import { ButtonGroup, Button, Modal } from 'react-bootstrap';
import { StateHandler } from '../../../../model/Utilities/Types';
import { useTranslation } from 'react-i18next';

declare interface AddEditProps {
  showChangeModal: any;
  disabledFields: boolean;
  showChange: boolean;
  setShowChange: StateHandler<boolean>;
  changes: string[];
  makeChanges: any;
  header?: string;
  body?: string;
}

export const AddEditModal = ({
  showChangeModal,
  disabledFields,
  showChange,
  setShowChange,
  changes,
  makeChanges,
  header,
  body,
}: AddEditProps) => {
    const { t } = useTranslation();
  return (
    <>
      <ButtonGroup>
        <Button
          variant="primary"
          onClick={showChangeModal}
          data-cy="save-settings"
          disabled={disabledFields}
        >
          {t('global.buttons.save')}
        </Button>
        {/*The Modal component is used to display the popup*/}
        <Modal
          show={showChange}
          onHide={() => setShowChange(false)}
          backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
          keyboard={false} //doesn't allow the user to close the modal by using the keyboard
        >
          <Modal.Header>
            <Modal.Title>
              {header ? header : t('global.generalWords.confirm')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/*Show all the changes that will be made*/}
            {body ? body : t('components.area.edit.changes.changes')}:
            <ul>
              {changes.map((change, idx) => {
                return (
                  <li key={idx} data-cy={idx} style={{ padding: 3 }}>
                    {change}
                  </li>
                );
              })}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              onClick={makeChanges}
              data-cy="submit-final"
            >
              {t('global.buttons.submit')}
            </Button>
            <Button variant="secondary" onClick={() => setShowChange(false)}>
              {t('global.buttons.cancel')}
            </Button>
          </Modal.Footer>
        </Modal>
      </ButtonGroup>
    </>
  );
};
