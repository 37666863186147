import { useTranslation } from "react-i18next";
import { Row, InputGroup, DropdownButton, Dropdown, FormControl, Alert } from "react-bootstrap";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import { AreaBase, AreaReporting } from "../../../model/Classes/Area";
import { useEffect, useState } from "react";
import { getSpotPricePendingCount } from "../../../services/myTransactionsService";
import { logger } from "../../../utils/logger";

declare interface InvoiceMethod {
  name: string;
  key: Nullable<number>;
}

declare interface InvoiceFilter {
  name: string;
  key: number;
}

declare interface TransactionsFiltersProps {
  user: User;
  invoiceMethodButtons: InvoiceMethod[];
  setSelectedInvoiceMethod: StateHandler<Nullable<number>>;
  selectedInvoiceMethod: Nullable<number>;
  areas: AreaReporting[];
  areaChanged: (area: AreaBase) => Promise<void>;
  areaField: string;
  invalidDates: boolean;
  startDate: string;
  setStartDate: StateHandler<string>;
  stopDate: string;
  setStopDate: StateHandler<string>;
  invoiceFilter: InvoiceFilter[];
  invoicingFilter: number;
  setInvoicingFilter: StateHandler<number>;
}

export const TransactionsFilters = ({
  user,
  invoiceMethodButtons,
  setSelectedInvoiceMethod,
  selectedInvoiceMethod,
  areas,
  areaChanged,
  areaField,
  invalidDates,
  startDate,
  setStartDate,
  stopDate,
  setStopDate,
  invoiceFilter,
  invoicingFilter,
  setInvoicingFilter,
}: TransactionsFiltersProps) => {
  const { t } = useTranslation();

  const [pendingAlert, setPendingAlert] = useState(false);
  useEffect(() => {
    const getSpotPendingCount = async () => {
      const pendingSpotCount = await getSpotPricePendingCount();
      if (pendingSpotCount.success) {
        if (pendingSpotCount.data[0].pendingCount > 0) {
          setPendingAlert(true);
        } else {
          setPendingAlert(false);
        }
      } else {
        if (!pendingSpotCount.success) {
          logger(pendingSpotCount.data);
        }
      }
    };
    getSpotPendingCount();
  }, []);
  
  return (
    <>
      {user.user_level === 2 && (
        <>
          {pendingAlert && (
            <Alert variant="danger" className="p-2">
              There are pending spotprice transactions
            </Alert>
          )}
          <Row className="mb-3">
            <InputGroup className="mb-3">
              <DropdownButton
                id="invoice_method_buttons"
                title={t("components.reporting.tabs.completed.areainvoice")}
                drop="down"
              >
                {invoiceMethodButtons.map((im, idx) => {
                  return (
                    <Dropdown.Item key={idx} as="button" onClick={() => setSelectedInvoiceMethod(im.key)}>
                      {im.name}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
              <FormControl
                value={
                  selectedInvoiceMethod === null
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.all")
                    : selectedInvoiceMethod === 0
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.test")
                    : selectedInvoiceMethod === 1
                    ? t("components.reporting.tabs.completed.table.buttonsFilters.normal")
                    : "Wattery/Paytrail"
                }
                readOnly
              />
            </InputGroup>
          </Row>
        </>
      )}
      {/*Row containing the area input field as a dropdown*/}
      <Row>
        <>
          <p>
            <strong>{t("components.reporting.tabs.completed.select")}</strong>
          </p>
          <InputGroup className="mb-3">
            <DropdownButton id="dropdown-item-button" title={t("components.reporting.tabs.completed.area")} drop="down">
              {/*Map all the areas to a Dropdown Item where each item displays the area name*/}
              {areas.map((area, idx) => {
                return (
                  //Whenever an item is clicked, set the areaField state to conatin the chosen area
                  <Dropdown.Item key={idx} as="button" onClick={() => areaChanged(area)}>
                    {area.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            {/*The form field displays the name of the area that is chosen. It's readOnly since
                               we want the user to only choose an area from the dropdown*/}
            <FormControl aria-label="Area input" value={areaField} readOnly />
          </InputGroup>
        </>
      </Row>
      {/**
      <Row>
        <>
         
          <InputGroup className="mb-3">
            <DropdownButton
              id="dropdown-item-button"
              title={t('components.reporting.tabs.completed.timezone')}
              drop="down"
            >
              <Dropdown.Item>
                <FormControl
                  placeholder="Search timezone..."
                  aria-label="Search input"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  ref={searchInputRef}
                  onClick={(e) => e.stopPropagation()}
                />
              </Dropdown.Item>
              {/*Map all the timezones to a Dropdown Item where each item displays the timezone name
              {filteredTimezones.map((timezone, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => handleTimezoneChange(timezone)}
                  as="button"
                >
                  {timezone}
                </Dropdown.Item>
              ))}
              {filteredTimezones.map((timezone, idx) => (
                <Dropdown.Item
                  key={idx}
                  onClick={() => handleTimezoneChange(timezone)}
                  as="button"
                >
                  {timezone}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            {/*The form field displays the name of the timezone that is chosen. It's readOnly since
                               we want the user to only choose a tmezone from the dropdown
            <FormControl
              aria-label="Area input"
              value={selectedTimezone}
              readOnly
            />
          </InputGroup>
        </>
      </Row>
       */}
      {/*Row containing the date input field for the start date and time*/}
      <Row>
        <p>
          <strong>{t("components.reporting.tabs.completed.start")}</strong>
        </p>
        <InputGroup className="mb-3">
          {/*Form field for the start date. It's an html date type*/}
          <FormControl type="date" value={startDate} onChange={(event) => setStartDate(event.target.value)} />
        </InputGroup>
      </Row>

      {/*Row containing the date input field for the stop date and time*/}
      <Row>
        <p>
          <strong>{t("components.reporting.tabs.completed.stop")}</strong>
        </p>
        <InputGroup className="mb-3">
          {/*Form field for the stop date. It's an html date type*/}
          <FormControl type="date" value={stopDate} onChange={(event) => setStopDate(event.target.value)} />
        </InputGroup>
      </Row>
      {/*Row containing a possible alert notifying the user that the chosen start and stop dates are invalid*/}
      <Row>
        {invalidDates && (
          <p style={{ color: "red", marginBottom: "1rem" }}>
            <strong>{t("global.alert.failure.invalidDates")}</strong>
          </p>
        )}
      </Row>
      {user.user_level === 2 && (
        <Row className="mb-3">
          <InputGroup className="mb-3">
            <DropdownButton id="invoice_filter" title={t("components.reporting.tabs.completed.invFilter")} drop="down">
              {invoiceFilter.map((im, idx) => {
                return (
                  <Dropdown.Item key={idx} as="button" onClick={() => setInvoicingFilter(im.key)}>
                    {im.name}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
            <FormControl
              value={
                invoicingFilter === 0
                  ? t("components.reporting.tabs.completed.table.buttonsFilters.showAll")
                  : invoicingFilter === 1
                  ? t("components.reporting.tabs.completed.table.buttonsFilters.invoiced")
                  : t("components.reporting.tabs.completed.table.buttonsFilters.notInvoiced")
              }
              readOnly
            />
          </InputGroup>
        </Row>
      )}
    </>
  );
};
