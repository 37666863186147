import { useState } from "react";
import { Alert, InputGroup, Form, Button, ButtonGroup, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import { toast } from "react-toastify";


type UserSettingsBasicProps = {
  user: User;
  setUser: StateHandler<Nullable<User>>;
  newFirstName: string;
  newLastName: string;
  setNewFirstName: StateHandler<string>;
  setNewLastName: StateHandler<string>;
  history: any;
  handleSubmitUserSettingsBasic: any;
};

/**
 * Component for the basic settings view where the user can change their first/last name
 * @param {*} user state for the user of the application
 * @param {*} setUser state handler for the user state
 * @param {*} newFirstName state for the first name field
 * @param {*} newLastName state for the last name field
 * @param {*} setNewFirstName state handler for the firstName state
 * @param {*} setNewLastName state handler for the lastName state
 * @param {*} history history object
 * @param {*} handleSubmitUserSettingsBasic helper function for handling the submission
 * @returns basic settings view
 */
const UserSettingsBasic = ({
  user,
  setUser,
  newFirstName,
  newLastName,
  setNewFirstName,
  setNewLastName,
  history,
  handleSubmitUserSettingsBasic,
}: UserSettingsBasicProps) => {
  const [showFirstNameAlert, setShowFirstNameAlert] = useState(false); //state for showing an alert if the first name field is invalid
  const [showLastNameAlert, setShowLastNameAlert] = useState(false); //state for showing an alert if the last name field is invalid
  const { t } = useTranslation();
  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   * @returns true if the validation passes, false otherwise
   */
  const validate = () => {
    //Return value
    let success = true;

    if (newFirstName === "" || newFirstName.length > 63) {
      success = false;
      setShowFirstNameAlert(true);
    } //Check if the first name is empty or too long
    if (newLastName === "" || newLastName.length > 63) {
      success = false;
      setShowLastNameAlert(true);
    } //Check if the last name is empty or too long

    return success;
  };


  /**
   * Asynchronous helper function that is called when the user presses the submit button in the popup.
   * The function closes the popup, and calls the handleSubmitUserSettingsBasic() function. If the
   * submission was successful, call the showSuccessAlert() function. Otherwise, display an alert
   * telling the user that the submission failed.
   */
  const makeChanges = async () => {
    //Call the helper function handleSubmitUserSettingsBasic()
    if (await handleSubmitUserSettingsBasic()) {
      toast.success(t("global.alert.success.nameChange"))
    } else {
      toast.error(t("global.view.errorTry"));
    }
  };


  /**
   * Return a form containing the fields for the various inputs. Additionally, render a popup whenever the save/cancel
   * button is pressed, and alerts whenever they need to be shown
   */
  return (
    <>
      {/*Row for alerts and the form fields*/}
      <Row>
        {/*Simple text input field for the first name field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.settings.first")}</InputGroup.Text>
          <Form.Control
            type="text"
            value={newFirstName}
            aria-label={user.first_name}
            aria-describedby="first name"
            onChange={(event) => setNewFirstName(event.target.value)}
          />
        </InputGroup>
        {showFirstNameAlert && ( //Show alert notifying the user if the first name is too long or empty
          <Alert key="first-name" variant="danger">
            {t("global.alert.failure.firstName")}
          </Alert>
        )}

        {/*Simple text input field for the last name field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.settings.last")}</InputGroup.Text>
          <Form.Control
            type="text"
            value={newLastName}
            aria-label={user.last_name}
            aria-describedby="last name"
            onChange={(event) => setNewLastName(event.target.value)}
          />
        </InputGroup>
        {showLastNameAlert && ( //Show alert notifying the user if the last name is too long or empty
          <Alert key="last-name" variant="danger">
            {t("global.alert.failure.lastName")}
          </Alert>
        )}
      </Row>
      <Row>
        {/*ButtonGroup for the save button*/}
        <ButtonGroup className="mb-3">
          <>
            {/*The save button needs to be wrapped around a Col component, since otherwise it will
                           stretch across the whole screen*/}
            <Col xs={0} sm={1}>
              <Button variant="primary" onClick={() => validate() && makeChanges()}>
                {t("global.buttons.save")}
              </Button>
            </Col>
          </>
        </ButtonGroup>
      </Row>
    </>
  );
};

export default UserSettingsBasic;
