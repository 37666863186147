import styled from "styled-components";

/**
 * Styled component for the admin search
 */

export const StyledAdminSearch = styled.div`

    .summary {
        cursor: pointer;
        color: blue;
    }

    .contract-header {
        display: flex;
        flex-direction: column;
    }

    .contract-header:hover {
        background-color: #f0f0f0;
    }

    .area-header:hover {
        background-color: #f0f0f0;
    }

    .chargepoint {
        display: flex;
        flex-direction: column;
    }

    //UserSearch

    .user-list {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .user-list:hover {
        background-color: #f0f0f0;
    }
`;