import { Accordion, Card, Col, Container, Row } from "react-bootstrap";
import AdminUserTransactionBody from "./AdminUserTransactionBody";
import getCurrencySymbol from "src/utils/getCurrencySymbol";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { StyledTransactionAccordion } from "./TransactionAccordion.styled";

const TransactionAccordion = ({ transactions, isActiveTransaction }) => {
  const formatDateTime = (dateTime, timezone) => {
    return DateTime.fromISO(dateTime, { zone: timezone }).setLocale("fi").toFormat("dd.MM.yyyy HH:mm");
  };

  if (isActiveTransaction) {
    return (
    <StyledTransactionAccordion>
      <Accordion>
        {transactions.map((transaction, index) => (
          <Card key={index}>
            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}
            className="transaction-header"
            >
              <Container>
                <Row>
                  <Col sm={4}>{formatDateTime(transaction.timestamp, transaction.timezone)} -- </Col>
                  <Col sm={3}>
                    <Link to={`/area/${transaction.area_id}/chargepoint/${transaction.charge_point_id}`}>
                      {transaction.number_prefix + "-" +
                      transaction.number + "/" +
                      // show chargepoint id if nickname is null
                      (transaction.nickname === null ? transaction.charge_point_id : transaction.nickname)}
                    </Link>
                  </Col>
                  <Col sm={3}>{transaction.name}</Col>
                  <Col sm="auto">{(transaction.Wh / 1000).toFixed(2) + " kWh"}</Col>
                </Row>
              </Container>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>
                <AdminUserTransactionBody transaction={transaction} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </StyledTransactionAccordion>
    );
  } else {
    return (
    <StyledTransactionAccordion>
      <Accordion>
        {transactions.map((transaction, index) => (
          <Card key={index}>
            <Accordion.Toggle as={Card.Header} eventKey={index.toString()}
            className="transaction-header"
            >
              <Container>
                <Row>
                  <Col sm={3}>
                    {formatDateTime(transaction.start_time, transaction.timezone)} --{" "}
                    {formatDateTime(transaction.end_time, transaction.timezone)}
                  </Col>
                  <Col sm={3}>
                    <Link to={`/area/${transaction.area_id}/chargepoint/${transaction.charge_point_id}`}>
                      {transaction.number_prefix + "-" +
                      transaction.number + "/" +
                      // show chargepoint id if nickname is null
                      (transaction.nickname === null ? transaction.charge_point_id : transaction.nickname)}
                    </Link>
                  </Col>
                  <Col sm={3}>{transaction.name}</Col>
                  <Col sm="auto">{(transaction.meter_value / 1000).toFixed(2) + " kWh"}</Col>
                  <Col sm={1} className="ms-auto text-end">
                    {transaction.total_price + getCurrencySymbol(transaction.currency)}
                  </Col>
                </Row>
              </Container>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={index.toString()}>
              <Card.Body>
                <AdminUserTransactionBody transaction={transaction} />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </StyledTransactionAccordion>
    );
  }
};

export default TransactionAccordion;
