import { InputGroup, DropdownButton, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

declare interface ConnectorIdDropdownProps {
  numberOfConnectors?: number;
  onSelectConnector: Function;
  connectorId: number | null;
}
export const ConnectorIdDropdown = ({
  numberOfConnectors,
  onSelectConnector,
  connectorId,
}: ConnectorIdDropdownProps) => {
  const { t } = useTranslation();

  return (
    <div>
      <InputGroup hidden={!numberOfConnectors || numberOfConnectors === 1}>
        <DropdownButton id="connectorIdDropdown" title={t("components.startTransaction.view.title.conn")} drop="down">
          {[...Array(numberOfConnectors)].map((e, idx) => (
            <Dropdown.Item
              key={idx}
              as="button"
              onClick={(event) => {
                event.preventDefault();
                onSelectConnector(idx + 1);
              }}
            >
              {idx + 1}
            </Dropdown.Item>
          ))}
        </DropdownButton>

        {/*The form field contains the tag id the user has chosen, or whater is written to it*/}
        <Form.Control
          type="text"
          disabled
          value={connectorId || ""}
          placeholder={t("components.startTransaction.view.selectConn")}
        />
      </InputGroup>
    </div>
  );
};
