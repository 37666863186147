import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AreaBase } from "../../../model/Classes/Area";
import { getAreaBranches } from "../../../services/areaService";

import { usePrevious } from "../../../utils/usePrevious";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import Toggle from "react-toggle";
import "react-toggle/style.css";
import { getUserRootAreas } from "../../../services/areaService";
import { toggleAlerts } from "../../../services/areaService";

const UserSettingsAlerts = () => {
  const { t } = useTranslation();

  const [areas, setAreas] = useState<AreaBase[]>([]);
  const [managedAreas, setManagedAreas] = useState<{ area_id: number; primary: number; alerts: number }[]>([]);
  const prevManagedAreas = usePrevious<typeof managedAreas>(managedAreas);

  const getAsyncData = async () => {
    const branchesRes = await getAreaBranches<AreaBase>("/api/area");
    if (branchesRes.success) {
      setAreas(branchesRes.data.flatMap((a) => a));
    }
    const managedRes = await getUserRootAreas();
    if (managedRes.success) {
      setManagedAreas(managedRes.data.flatMap((a) => a));
    }
  };
  
  const updateSetting = async (area_id: number) => {
    // e: Event & { target: HTMLInputElement }
    // If we ever switch to actually checking and not just toggling states
    // const checked = e.target.checked;

    // Just toggle the setting
    const res = await toggleAlerts(area_id);

    if (!res.success) {
      toast.error(t("components.userSettings.tabs.alerts.failedChange"));
    }

    // Refresh all data
    await getAsyncData();
  };

  const hasAlertsEnabled = (area_id: number | undefined) => {
    if (area_id === undefined) {
      return false;
    } else {
      return managedAreas?.some((a) => a.area_id === area_id && !!a.alerts);
    }
  };

  const getAreaName = useCallback((area_id: number | undefined) => {
    return areas.find((a) => a.id === area_id)?.name ?? "N/A";
  }, [areas]);

  useEffect(() => {
    getAsyncData();
  }, []);

  // Use an effect that keeps track of the previous state to compare for changes. Whenever
  // a change occurs, fire a toast indicating what happened. A bit convoluted, but works. Just
  // monitoring managedAreas fires on every entry, every time.
  useEffect(() => {
    managedAreas.forEach((a) => {
      if (
        !!prevManagedAreas &&
        prevManagedAreas.some((prevA) => a.area_id === prevA.area_id && a.alerts !== prevA.alerts) // For same ID, see if alert status differs
      ) {
        if (!!a.alerts) {
          toast.success(
            t("components.userSettings.tabs.alerts.successfulChangeOn", { areaName: getAreaName(a.area_id) })
          );
        } else {
          toast.info(
            t("components.userSettings.tabs.alerts.successfulChangeOff", { areaName: getAreaName(a.area_id) })
          );
        }
      }
    });
  }, [managedAreas, prevManagedAreas, getAreaName, t]);

  return (
    <>
      <p>{t("components.userSettings.tabs.alerts.description")}</p>
      {managedAreas.map((area, idx) => (
        <div key={idx}>
          <StyledToggle defaultChecked={hasAlertsEnabled(area.area_id)} onChange={() => updateSetting(area.area_id)} />
          {getAreaName(area.area_id)}
        </div>
      ))}
      {managedAreas.length === 0 && (
        <div className="alert alert-info">{t("components.userSettings.tabs.alerts.noAreas")}</div>
      )}
    </>
  );
};

const StyledToggle = styled(Toggle)`
  vertical-align: top;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
`;

export default UserSettingsAlerts;
