import { useEffect, useState } from "react";
import { Tab, Tabs, Row, Col, Button } from "react-bootstrap";
import EditSettings from "./EditSettings";
import EditMove from "./EditMove";
import EditDelete from "./EditDelete";
import { getSubareasAndCheckMasterLoadBalance, getParentsMasterLoadBalance } from "./SettingsUtils";
import { sendAreaChanges } from "../../../../services/areaService";
import { sendMeterChanges } from "../../../../services/areaService";
import { sendAreaMove } from "../../../../services/areaService";
import { sendAreaDelete } from "../../../../services/areaService";
import { deauthorizeUsers } from "../../../../services/areaService";
import { logger } from "../../../../utils/logger";
import AreaSingle, { AreaFull } from "../../../../model/Classes/Area";
import CpFull from "../../../../model/Classes/Chargepoint";
import User from "../../../../model/Classes/User";
import AreaMeter from "../../../../model/Classes/AreaMeter";
import { useTranslation } from "react-i18next";
import { Contract } from "../../../../model/Classes/Contract";
import { StateHandler } from "../../../../model/Utilities/Types";
//import { SingleAreaSubareas } from '../SingleArea/SingleAreaComponents';

/**
 * Component for area settings. The component return a tab menu from where the corresponding task can be comepleted.
 * The component also contains most states for the form fields (reasoning in the comments above the state) + helper functions,
 * as well as checks if the area can be toggled as a master load balancing area and retrieves all the subareas for this area,
 * the subareas that area master load balancing areas, and all the directly authorized users to this area.
 * @param {*} area which area to edit
 * @param {*} toggleEdit helper function for changing the view between settings and area view
 * @param {*} allAreas all the areas that the user has access to
 * @param {*} history history object
 * @param {*} authorizedUsers all the users that are authorized to this area (directly and inherited)
 * @param {*} refreshAuthorizedUsers helper function for refreshing authorized users
 * @param {*} chargepoints all the chargepoints for this area
 * @param {*} runUseEffect helper function for running the useEffect in SingleArea.js again
 * @returns  tab menu from where you can edit, move, or delete an area.
 */

declare interface EditAreaProps {
  area: AreaSingle;
  allMeters: AreaMeter[];
  toggleEdit: () => void;
  userRoot: number[];
  allAreas: AreaSingle[];
  history: any;
  authorizedUsers: User[];
  refreshAuthorizedUsers: () => Promise<void>;
  chargepoints: CpFull[];
  runUseEffect: (mode: number) => void;
  contract?: Contract;
  user: User;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  pricingType: number;
  setPricingType: StateHandler<number>;
  spotPriceMargin: string | number;
  setSpotPriceMargin: StateHandler<string | number>;
  userBiddingValue: string;
  setUserBiddingValue: StateHandler<string>;
}
const EditArea = ({
  area,
  allMeters,
  userRoot,
  toggleEdit,
  allAreas,
  history,
  authorizedUsers,
  refreshAuthorizedUsers,
  chargepoints,
  runUseEffect,
  contract,
  user,
  isReporting,
  setIsReporting,
  pricingType,
  setPricingType,
  spotPriceMargin,
  setSpotPriceMargin,
  userBiddingValue,
  setUserBiddingValue,
}: EditAreaProps) => {
  //The reason why most states are up here instead of at their respective components is due to the fact
  //that the states are needed the functions handling the respective tasks (editing, moving, deleting) need them.
  //It's easier to manage them if they are in this component
  const [meter, setMeter] = useState(`${area.has_meter}`); //state for the meter
  const [masterLoadBalance, setMasterLoadBalance] = useState(`${area.master_load_balancing}`); //state for the master load balancing
  const [newName, setNewName] = useState(area.name!); //state for the name field
  const [limit, setLimit] = useState(area.a_limit !== null ? area.a_limit! : ""); //state for the limit
  const [backupLimit, setBackupLimit] = useState(area.meter_fail_limit!); //state for the backup limit (applies if the area has a meter)
  const [parentsWithMasterLoadBalance, setParentsWithMasterLoadBalance] = useState<AreaSingle[]>([]); //state for all the parents that have master load balancing toggled on
  const [canBeMasterLoadBalance, setCanBeMasterLoadBalance] = useState(true); //state for knowing if the master load balancing can be toggle on or not
  const [childrenWithMasterLoadBalance, setChildrenWithMasterLoadBalance] = useState<AreaSingle[]>([]); //state for all the children that have master load balancing toggled on
  const [subAreas, setSubAreas] = useState<AreaSingle[]>([]); //all the subareas for the specific area
  const [directlyAuthorizedUsers, setDirectlyAuthorizedUsers] = useState<User[]>([]); //all the directly authorized users to this area
  const { t } = useTranslation();

  /**
   * Function that is called when the edit area component is viewed. The function checks if the
   * area that is visited can be toggled as a master load balance area or not. The area can be
   * toggled as a master load balancing area if it
   * 1)   has no direct link upwards (parent, granparent etc. up until root)
   *      to an area that is toggled as a master load balancing area or
   * 2)   has no (direct or indirect) subarea that is toggled as a master load balancing area.
   *      I.e. no area "up or down" can be toggled as a master load balancing area if the user wants to
   *      toggle this area to be one.
   * Additionally, the function checks all the users that are directly authorized to this area and sets them
   * to the directlyAuthorizedUsers state, as well as gets all the subareas and sets them to the subAreas state.
   */
  useEffect(() => {
    // If the area is the user's root area, all of the code can be disregarded since you cannot
    // toggle master load balancing for the root area, nor can you move or delete that area (hence why
    // we don't need to check for the subareas, check if the area can be toggled as a master load balancing area,
    // nor filter the authorized users (it cannot contain any inherited authorizations anyway)).
    // Additionally, the code needs to check if the area still exists i.e. the second statement.
    // This needs to be checked due to the fact that if the area is deleted by the user, the code still
    // runs this function (since area is part of the dependency array for the function),
    // and would thus produce errors since it cannot find the area when trying to access its properties.

    if (!area.user_root && allAreas.find((a) => a.id === area.id)) {
      // create an array of all the direcly authorized users
      const authorized: User[] = [];
      authorizedUsers.forEach((user) => {
        if (user.user_charging_authorization === area.id) authorized.push(user);
      });

      // call the getSubareasAndCheckMasterLoadBalance util function to get
      // 1) all the subareas for this area
      // 2) all the subareas that area toggles as master load balancing areas
      const [subAreasRes, childrenMasterLoadBalance] = getSubareasAndCheckMasterLoadBalance(
        area,
        allAreas,
        setCanBeMasterLoadBalance
      );

      //call the getParentsMasterLoadBalance util function to get all the parents that are master load balancing areas.
      const parentsMasterLoadBalance = getParentsMasterLoadBalance(
        allAreas,
        allAreas.filter((a) => a.id === area.parent)[0],
        setCanBeMasterLoadBalance
      );
      //If the function getParentsMasterLoadBalance returned an array, set that array to the parentsWithMasterLoadBalance state.
      //If there were no such parents, the function returns undefined. The reason for this is that I tried to return an empty array,
      //but for some reason the return value was undefined, so I opted for this solution.
      if (parentsMasterLoadBalance) setParentsWithMasterLoadBalance(parentsMasterLoadBalance);

      setChildrenWithMasterLoadBalance(childrenMasterLoadBalance);
      setSubAreas(subAreasRes);
      setDirectlyAuthorizedUsers(authorized);
    }
    //if the area is the root area for the user (or the area doesn't exist anymore), then set the
    //canBeMasterLoadBalance state to false
    else setCanBeMasterLoadBalance(false);
  }, [allAreas, area, authorizedUsers]);

  /**
   * Helper function for sending data to the backend when the user changes basic settings for the area.
   * If the operation was successful, run the useEffect in SingleArea.js again.
   * @returns true if successful, false otherwise
   */
  const handleSubmitSettings = async (area_changes: AreaFull, meter_changes: AreaMeter, previousMeter: AreaMeter) => {
    const has = area_changes.has_meter! === 1;
    const had = area.has_meter! === 1;
    const areaRes = await sendAreaChanges(area.id!, area_changes);
    if (
      !(
        has === had &&
        meter_changes.public_id === previousMeter.public_id &&
        meter_changes.slave_id === previousMeter.slave_id
      )
    ) {
      const meterRes = await sendMeterChanges(area.id!, meter_changes, previousMeter, had, has);
      if (areaRes.success && meterRes.success) runUseEffect(1);
      return areaRes.success && meterRes.success;
    } else {
      if (areaRes.success) runUseEffect(1);
      return areaRes.success;
    }
  };

  /**
   * Helper function for sending data to the backend when the user moves the area.
   * If the operation was successful, run the useEffect in SingleArea.tsx again.
   * @returns true if successful, false otherwise
   */
  const handleSubmitMove = async (data: any) => {
    const editRes = await sendAreaMove(area.id!, data);

    if (editRes.success) runUseEffect(1);

    return editRes.success;
  };

  /**
   * Helper function for sending data to the backend when the user deleted the area.
   * If the operation was successful, run the useEffect in SingleArea.js again.
   * @returns true if successful, false otherwise
   */
  const handleDelete = async () => {
    const data: number[] = [];
    subAreas.forEach((area) => {
      data.push(area.id!);
    });
    const res = await sendAreaDelete(area.id!, data);
    if (res.success) runUseEffect(-1);
    return res.success;
  };

  /**
   * Helper function for deauthorizing all users from this area.
   * If it's successful, refresh all the authorized users to get the updated data.
   * @returns true if successful, false otherwise
   */
  const handleDeauthorizeAllUsers = async () => {
    const res = await deauthorizeUsers(area.id!);
    if (res[0]) {
      await refreshAuthorizedUsers();
      return true;
    } else {
      logger(res[1]);
      return false;
    }
  };

  //helper function for changing the newName state
  const handleNameChange = (event: any) => {
    setNewName(event.target.value);
  };

  //helper function for changing  limit state
  const handleLimit = (event: any) => {
    setLimit(event.target.value !== "" ? event.target.value : null);
  };

  /**
   * The component returns a header + button + tab menu for the different settings. The Tabs component is responsible
   * for making sure the correct component is displayed when the user switches tabs.
   */
  return (
    <>
      <Row style={{ textAlign: "center" }}>
        <h2>{t("components.area.edit.static.header", { areaName: area.name })}</h2>
      </Row>
      <Row className="mb-3">
        <Col>
          <Button variant="secondary" onClick={toggleEdit} data-cy="backToAreaFromSettings">
            {t("global.buttons.back")}
          </Button>
        </Col>
      </Row>
      {/*Row containing the different tabs*/}
      <Row>
        <Tabs defaultActiveKey="first" id="" className="mb-3">
          <Tab eventKey="first" title={t("components.area.edit.static.tabs.editArea")}>
            <EditSettings
              area={area}
              allMeters={allMeters}
              meter={meter}
              setMeter={setMeter}
              backupLimit={backupLimit}
              setBackupLimit={setBackupLimit}
              masterLoadBalance={masterLoadBalance}
              setMasterLoadBalance={setMasterLoadBalance}
              toggleEdit={toggleEdit}
              handleNameChange={handleNameChange}
              handleLimit={handleLimit}
              handleSubmitSettings={handleSubmitSettings}
              newName={newName}
              limit={limit}
              parent={allAreas.filter((a) => a.id === area.parent && area.id !== 0)[0]}
              canBeMasterLoadBalance={canBeMasterLoadBalance}
              history={history}
              childrenWithMasterLoadBalance={childrenWithMasterLoadBalance}
              parentsWithMasterLoadBalance={parentsWithMasterLoadBalance}
              contract={contract}
              isReporting={isReporting}
              setIsReporting={setIsReporting}
              user={user}
              allAreas={allAreas}
              pricingType={pricingType}
              setPricingType={setPricingType}
              spotPriceMargin={spotPriceMargin}
              setSpotPriceMargin={setSpotPriceMargin}
              userBiddingValue={userBiddingValue}
              setUserBiddingValue={setUserBiddingValue}
            />
          </Tab>
          <Tab eventKey="second" title={t("components.area.edit.static.tabs.editMove")}>
            <EditMove
              area={area}
              allAreas={allAreas}
              userRoot={userRoot}
              toggleEdit={toggleEdit}
              handleSubmitMove={handleSubmitMove}
              subAreas={subAreas}
              childrenWithMasterLoadBalance={childrenWithMasterLoadBalance}
              contract={contract}
              user={user}
            />
          </Tab>
          <Tab eventKey="third" title={t("components.area.edit.static.tabs.editDelete")}>
            <EditDelete
              area={area}
              handleDelete={handleDelete}
              history={history}
              directlyAuthorizedUsers={directlyAuthorizedUsers}
              chargepoints={chargepoints}
              subAreas={subAreas}
              handleDeauthorizeAllUsers={handleDeauthorizeAllUsers}
            />
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default EditArea;
