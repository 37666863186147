import { InputGroup, Form, FormControl, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import { searchUser } from "../../services/adminService";
import { useTranslation } from "react-i18next";
import AdminUserSearchResult from "./AdminUserSearchResult";

const AdminUserManager = ({ activeEventKey }) => {
    const { t } = useTranslation();

    const [searchResult, setSearchResult] = useState<any | null>(null);
    const [viewUser, setViewUser] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    // Auto focus on searchbar
    useEffect(() => {
        if (inputRef.current && activeEventKey === "userManager") {
            inputRef.current.focus();
        }
    }, [activeEventKey]);

    async function searchHandler(e) {
        e.preventDefault();
        
        const formData = new FormData(e.target);
        const searchInput = (formData.get("searchInput") as string) || "";

        try {
            const res = await searchUser(searchInput);
            setSearchResult(res.data);
            setViewUser(false);
        }
        catch (error) {
            console.error("search error: ", error);
        }
    }

  return (
    <>
    <Form onSubmit={searchHandler}>
        <InputGroup className="mb-3">
            <FormControl
            ref={inputRef}
            placeholder={t('components.admin.userManager.searchPlaceholder')}
            type="text"
            name="searchInput"
            minLength={1}
            maxLength={100}
            />
            <Button variant="primary" type="submit">{t('components.admin.search')}</Button>
        </InputGroup>
    </Form>
    <AdminUserSearchResult 
        searchResult={searchResult}
        viewUser={viewUser}
        setViewUser={setViewUser}
    />
    </>
  );
}

export default AdminUserManager;