import { Container, Row, Col, Tabs, Tab } from 'react-bootstrap';
import { StyledAdminPage } from './AdminPage.styled';
import AdminSetAreaManager from './AdminSetAreaManager';
import AdminSearch from './AdminSearch';
import AdminUserManager from './AdminUserManager';
import { useState } from 'react';
import { ContractView } from '../Level1/Contract/Contract';
import { Contract } from '../../model/Classes/Contract';
import { useTranslation } from 'react-i18next';


/**
 * Component responsible for displaying the full admin page. The page contains the tabs for
 * the different admin functionalities, and displays the correct component for the active tab.
 * The component is wrapped around a styled component StyledAdminPage containing the specific
 * styles for this page.
 * @returns full admin page with the tab view of the different admin functionalities
 */
const AdminPage = () => {
const { t } = useTranslation();

const [activeEventKey, setActiveEventKey] = useState('areaManager');
const [contracts, setContracts] = useState<Contract[]>([]);
const [selectedContractName, setSelectedContractName] = useState<string | null>(null);

const handleKeyChange = (eventValue: any) => {
  setActiveEventKey(eventValue);
};

const handleContractSelection = async (contractName: string) => {
  setActiveEventKey("contracts"); // Switch to Contracts tab
  setSelectedContractName(contractName); // Set selected contract
};

  return (
    <StyledAdminPage className="top-level-component">
      <Container id="component-margin">
        <Row className="mb-3" style={{ textAlign: 'center' }}>
          <Col>
            <h2>{t('components.admin.functionality')}</h2>
          </Col>
        </Row>
        <Row>
          <Tabs
            activeKey={activeEventKey}
            defaultActiveKey="areaManager"
            id=""
            className="mb-3"
            onSelect={handleKeyChange}
            >
            <Tab eventKey="areaManager" title={t('components.admin.set')}>
              <AdminSetAreaManager />
            </Tab>
            <Tab
              eventKey="contracts"
              title={t('components.admin.contracts.title')}
            >
              <ContractView
                activeEventKey={activeEventKey}
                contracts={contracts}
                setContracts={setContracts}
                selectedContractName={selectedContractName}
              />
            </Tab>
            <Tab eventKey="search" title={t('components.admin.search')}>
             <AdminSearch onContractSelect={handleContractSelection} activeEventKey={activeEventKey}/>
            </Tab>
            <Tab eventKey="userManager" title={t('components.admin.userManager.title')}>
              <AdminUserManager activeEventKey={activeEventKey}/>
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </StyledAdminPage>
  );
};

export default AdminPage;
