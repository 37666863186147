import { Nullable } from "../Utilities/Types";

export enum AccessType {
  free = 0,
  private = 1,
  privateAndPublic = 2,
  public = 3,
}

export enum PricingType {
  fixed = 0,
  spot = 1,
}

export default interface AreaSingle extends AreaBase, SpotPrice {
  a_limit?: Nullable<number>;
  has_meter?: number;
  master_load_balancing?: number;
  meter_fail_limit?: Nullable<number>;
  user_root?: boolean;
  contract_id?: number;
  access_type?: AccessType;
  reporting_site?: number;
  currency?: string;
  bidding_area?: Nullable<string>;
}

export interface AreaBase {
  id?: number;
  name: string;
  contract_name?: string;
  parent?: number;
  parentObj?: AreaBase;
  children?: AreaSingle[] | AreaBase[];
}

export interface AreaInTree extends AreaBase {
  children?: AreaInTree[];
  expanded: boolean;
  parentObj?: AreaInTree;
}

export interface AreaReporting extends AreaBase {
  reporting_site?: number;
  invoicing_method?: number;
  contract_id?: number;
  access_type?: number;
  user_root?: boolean;
  default_price?: number;
  default_public_price?: number;
  default_public_start_price?: number;
}

export interface AreaFull extends AreaBase, SpotPrice {
  invoicing_method?: number;
  contract_id?: number;
  access_type?: number;
  user_root?: boolean;
  default_price?: number;
  a_limit?: Nullable<number>;
  has_meter?: number;
  master_load_balancing?: number;
  meter_fail_limit?: Nullable<number>;
  default_public_price?: number;
  default_public_start_price?: number;
  remote_mode?: number;
  nominal_current?: Nullable<number>;
  reporting_site?: number;
  timezone?: string;
  currency?: string;
}

export interface SpotPrice {
  pricing_type?: number;
  bidding_area?: Nullable<string>;
  margin?: Nullable<number | string>;
}
