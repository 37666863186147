import {
    InputGroup,
    Form,
    Alert,
    DropdownButton,
    Dropdown,
    ButtonGroup,
    ToggleButton,
    Row,
    Col,
    FormControl,
} from "react-bootstrap";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AreaSingle, { AreaBase, AreaFull } from "../../../../model/Classes/Area";
import { StateHandler } from "../../../../model/Utilities/Types";
import { Contract } from "../../../../model/Classes/Contract";
import User from "../../../../model/Classes/User";
import BiddingArea from "../../../../model/Classes/BiddingArea";
import { getBiddingAreaValue } from "../../../../services/areaService";

declare interface AreaSettingsGeneralProps {
  newName: string;
  disabledFields: boolean;
  handleNameChange: (event: any) => void;
  //limit: string | number;
  handleLimit: (event: any) => void;
  showLimitAlert: boolean;
  addArea?: boolean;
  // editArea?: boolean;
  setAreaChargingType: StateHandler<{ name: string; value: number }[]>;
  areas?: AreaFull[];
  parent: AreaFull;
  setParent?: StateHandler<AreaFull>;
  userRoot?: number[];
  areaChargingType: { name: string; value: number }[];
  accessType: number;
  setAccessType: StateHandler<number>;
  privatePrice: string;
  setPrivatePrice: StateHandler<string>;
  publicPrice?: string;
  setPublicPrice: StateHandler<string>;
  publicStartPrice: string;
  setPublicStartPrice: StateHandler<string>;
  publicPriceAlert: boolean;
  invoicing: 0 | 1 | 2;
  setInvoicing: StateHandler<0 | 1 | 2>;
  changeAllSubareas?: boolean;
  setChangeAllSubareas?: StateHandler<boolean>;
  contract?: Contract;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  user: User;
  //masterLoadBalance: string;
  area?: AreaFull;
  allAreas?: AreaSingle[];
  pricingType: number;
  setPricingType: StateHandler<number>;
  spotPriceMargin: string | number;
  setSpotPriceMargin: StateHandler<string | number>;
  userBiddingValue: string;
  setUserBiddingValue: StateHandler<string>;
}

export const AreaSettingsGeneral = ({
  newName,
  disabledFields,
  handleNameChange,
  // limit,
  handleLimit,
  showLimitAlert,
  parent,
  addArea,
  //editArea,
  setAreaChargingType,
  areas,
  setParent,
  userRoot,
  areaChargingType,
  accessType,
  setAccessType,
  publicPrice,
  setPublicPrice,
  publicStartPrice,
  setPublicStartPrice,
  publicPriceAlert,
  privatePrice,
  setPrivatePrice,
  invoicing,
  setInvoicing,
  changeAllSubareas,
  setChangeAllSubareas,
  contract,
  isReporting,
  setIsReporting,
  user,
  //masterLoadBalance,
  area,
  allAreas,
  spotPriceMargin,
  setSpotPriceMargin,
  userBiddingValue,
  setUserBiddingValue,
  pricingType,
  setPricingType,
}: AreaSettingsGeneralProps) => {
  const { t } = useTranslation();

  //When changing Parent
  useEffect(() => {
    if (contract?.invoicing_method !== undefined) setInvoicing(contract?.invoicing_method);
    //eslint-disable-next-line
  }, [contract?.invoicing_method]);

  const onPriceBlur = (value: string, setState: StateHandler<string>) => {
    let num = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    const cleanNum = num.toFixed(2);
    setState(cleanNum);
  };
  //-------------------
  const reportingType = [
    {
      value: 0,
      name: t("components.area.addArea.static.form.reporting.isNotReporting"),
    },

    {
      value: 1,
      name: t("components.area.addArea.static.form.reporting.isReporting"),
    },
  ];

  const pricingTypeValue = [
    {
      value: 0,
      name: t("components.area.addArea.static.form.pricing.fixedPrice"),
    },

    {
      value: 1,
      name: t("components.area.addArea.static.form.pricing.spotPrice"),
    },
  ];

  useEffect(() => {
    const createAccessTypeArray = () => {
      if (invoicing === 1) {
        return [
          {
            name: "components.area.static.singleArea.header.areaType.private",
            value: 1,
          },
        ];
      } else {
        return [
          {
            name: "components.area.static.singleArea.header.areaType.private",
            value: 1,
          },
          {
            name: "components.area.static.singleArea.header.areaType.privPub",
            value: 2,
          },
          {
            name: "components.area.static.singleArea.header.areaType.public",
            value: 3,
          },
        ];
      }
    };
    //yucky
    setAreaChargingType(createAccessTypeArray());
    //eslint-disable-next-line
  }, [invoicing]);

  const [biddingValues, setBiddingValues] = useState<BiddingArea[]>([]);
  //const [userBiddingValue, setUserBiddingValue] = useState('');

  useEffect(() => {
    const getBiddingValues = async () => {
      const getValues = await getBiddingAreaValue();
      if (getValues.success) {
        setBiddingValues(getValues.data);
      }
    };
    getBiddingValues();
  }, []);

  // Extract unique bidding areas including null
  const uniqueBiddingAreas: (string | null)[] = [];

  allAreas !== undefined &&
    allAreas!.forEach((area) => {
      const biddingAreaAsString: string | null = area.bidding_area ?? "null";
      //area.bidding_area !== undefined ? area.bidding_area : null;
      if (!uniqueBiddingAreas.includes(biddingAreaAsString)) {
        uniqueBiddingAreas.push(biddingAreaAsString);
      }
    });

  const findParentArea = (parentId: number | undefined) => {
    if (!parentId) parentId = 0;
    return allAreas?.find((a: AreaSingle) => a.id === parentId);
  };
  useEffect(() => {
    let parentId = area?.parent;
    let reachedRootArea = false;
    do {
      const parentArea = findParentArea(parentId);
      reachedRootArea = parentArea === undefined;
      if (parentArea?.master_load_balancing === 1) {
        //setMasterLoadBalancer(true);
        // setLbArea(true);

        reachedRootArea = true;
      } else {
        parentId = parentArea?.parent;
        // setMasterLoadBalancer(true);
        // setLbArea(true);
      }
    } while (!reachedRootArea);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  const handleMarginChange = (e) => {
    const value = e.target.value;
    // Limit input to 3 decimal points
    if (/^\d*[.,]?\d{0,3}$/.test(value) || value === "") {
      setSpotPriceMargin(value);
    }
  };
  return (
    <div>
      <h4>{t("components.area.static.singleArea.header.tabHeader")}</h4>

      <InputGroup className="mb-3">
        <InputGroup.Text id="name">{t("components.area.addArea.static.form.area")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={newName}
          aria-label={"area.name"}
          aria-describedby="name"
          disabled={disabledFields}
          onChange={handleNameChange}
          data-cy="editAreaNameField"
        />
      </InputGroup>
      {/**
      <InputGroup className="mb-3">
        <InputGroup.Text id="limit">
          {t('components.area.addArea.static.form.limit')}
        </InputGroup.Text>
        <Form.Control
          type="number"
          value={limit ? limit : ''}
          min="0"
          aria-label={'area.a_limit'}
          aria-describedby="limit"
          disabled={
            editArea &&
            ((!lbArea || !masterLoadBalancer) && masterLoadBalance === '0'
              ? true
              : false)
          }
          onChange={handleLimit}
          data-cy="editAreaLimitField"
        />
      </InputGroup>
 */}
      {/*Dropdown input field for the parent*/}

      {addArea && (
        <InputGroup className="mb-3">
          <DropdownButton
            id="dropdown-parent-button"
            title={t("components.area.addArea.static.form.parentId")}
            drop="down"
          >
            {areas!.map((area: AreaSingle, idx: number) => {
              return (
                <Dropdown.Item key={idx} as="button" onClick={() => setParent!(area)}>
                  {`${area.name} (${area.id})`}
                </Dropdown.Item>
              );
            })}
          </DropdownButton>
          <Form.Control
            id="parentInput"
            aria-label="Parent input"
            value={
              parent.id === userRoot
                ? `${parent.name} (${t("components.area.addArea.static.form.default")})`
                : `${parent.name}`
            }
            disabled={disabledFields}
            readOnly /*readOnly is set so that the user cannot change the field directly*/
          />
        </InputGroup>
      )}
      <Row>
        <ButtonGroup className="mb-3">
          {areaChargingType.map((type, idx) => {
            return (
              <ToggleButton
                key={type.name}
                id={`load-type-${idx}`}
                type="checkbox"
                variant="outline-success"
                value={type.value}
                checked={accessType === type.value}
                onChange={(e) => setAccessType(Number(e.currentTarget.value))}
                style={{ maxWidth: "200px" }}
              >
                {` ${t(type.name)}`}
              </ToggleButton>
            );
          })}
        </ButtonGroup>
      </Row>
      <Row>
        <ButtonGroup className="mb-3">
          {reportingType.map((value, id) => {
            return (
              <ToggleButton
                key={value.value}
                value={value.value}
                id={`reporting-radio-${id}`}
                type="checkbox"
                onChange={(e) => {
                  setIsReporting(Number(e.currentTarget.value));
                }}
                className="mt-2"
                variant={id % 2 ? "outline-success" : "outline-danger"}
                disabled={!addArea && user.user_level === 1 && area?.contract_id !== parent?.contract_id}
                //area.contract_id != parentarea.contract_id && user_level == 1
                style={{ maxWidth: "200px" }}
                checked={value.value === isReporting}
              >
                {` ${t(value.name)}`}
              </ToggleButton>
            );
          })}
        </ButtonGroup>
      </Row>
      <Row>
        <Col className="mb-3">
          <i>{t("components.area.static.singleArea.header.reportTooltip")}</i>
        </Col>
      </Row>
      {showLimitAlert && (
        <Alert key="invalidLimit" variant="danger">
          {t("global.alert.failure.invalidLimit")}
        </Alert>
      )}

      {accessType ? (
        <>
          <h4 className="mt-3 ">{t("components.area.addArea.static.form.headers.pricing")}</h4>
          {!addArea && (
            <>
              <p className="mb-0 ">
                <i>{` ${t("components.area.edit.changes.allPriceChange")}`}</i>
              </p>
              <br />
              {/**    <ToggleButton
                className="mb-3"
                type="checkbox"
                value={area!.pricing_type!}
                variant="outline-primary"
                checked={changeAllSubareas!}
                onChange={(event) => setChangeAllSubareas!(true)}
                style={{ marginRight: '5px' }}
                //hidden={true}
              >
                {` ${t('components.area.edit.changes.allPrices')}`}
              </ToggleButton>
              */}

              {/** {setChangeAllSubareas!(true)} */}
              <Row>
                <ButtonGroup className="mb-3">
                  {pricingTypeValue.map((pricingValue, id) => {
                    return (
                      <ToggleButton
                        key={pricingValue.value}
                        value={pricingValue.value}
                        id={`pricing-radio-${id}`}
                        type="checkbox"
                        variant="outline-success"
                        onChange={(e) => {
                          if (parent!) {
                            parent.pricing_type === 1
                              ? setPricingType(area!.pricing_type!)
                              : setPricingType(Number(e.currentTarget.value));
                          } else if (parent === undefined) {
                            setPricingType(Number(e.currentTarget.value));
                          }
                        }}
                        checked={pricingValue.value === Number(pricingType)}
                        disabled={parent ? parent!.pricing_type === 1 : false}
                      >
                        {` ${t(pricingValue.name)}`}
                      </ToggleButton>
                    );
                  })}
                </ButtonGroup>
              </Row>
            </>
          )}
          {addArea && (
            <Row>
              <ButtonGroup className="mb-3">
                {pricingTypeValue.map((pricingValue, id) => {
                  return (
                    <ToggleButton
                      key={pricingValue.value}
                      value={
                        parent.pricing_type !== undefined && parent.pricing_type === 1
                          ? parent.pricing_type
                          : pricingValue.value
                      }
                      id={`pricing-radio-${id}`}
                      type="checkbox"
                      variant="outline-success"
                      onChange={(e) => {
                        setPricingType(Number(e.target.value));
                      }}
                      checked={pricingValue.value === pricingType}
                      disabled={parent!.pricing_type === 1}
                    >
                      {` ${t(pricingValue.name)}`}
                    </ToggleButton>
                  );
                })}
              </ButtonGroup>
            </Row>
          )}

          {/**Spot price bidding area dropdown and margin input fields */}
          <Row hidden={pricingType === 0}>
            <InputGroup className="mb-3">
              <DropdownButton
                id="bidding_area_Values"
                title={t("components.area.addArea.static.form.biddingArea")}
                drop="down"
                disabled={parent ? parent!.pricing_type === 1 : false}
              >
                {biddingValues
                  ? biddingValues.map((bidding, idx) => {
                      return (
                        <Dropdown.Item key={idx} as="button" onClick={() => setUserBiddingValue(bidding.bidding_area)}>
                          {bidding.name}
                        </Dropdown.Item>
                      );
                    })
                  : ""}
              </DropdownButton>
              <FormControl
                value={userBiddingValue}
                onChange={() => setUserBiddingValue(userBiddingValue)}
                disabled={parent ? parent!.pricing_type === 1 : false}
              />
            </InputGroup>

            {/** 
              <select
                onChange={(e) => {
               
                }}
                style={{
                  borderRadius: '3px',
                }}
              >
                {biddingValues
                  ? biddingValues.map((bidding) => {
                      return (
                        <option key={bidding.bidding_area}>
                          {bidding.name}
                        </option>
                      );
                    })
                  : null}
              </select>
              */}
          </Row>

          <Row hidden={pricingType === 0}>
            <InputGroup className="mb-3">
              <InputGroup.Text>
                {t("components.area.addArea.static.form.margin") +
                  ` (${area?.currency ? area?.currency : parent.currency}/kWh)`}
              </InputGroup.Text>
              <Form.Control
                type="text"
                value={parent && parent.pricing_type === 1 && parent.margin ? parent.margin : spotPriceMargin}
                aria-label={"area margin"}
                aria-describedby="area margin"
                // disabled={disabledFields}
                onChange={handleMarginChange}
                disabled={parent ? parent.pricing_type === 1 : false}
                data-cy="editMarginField"
              />
            </InputGroup>
            <Row className="mb-4">
              <i>{t("components.area.addArea.static.form.marginFee")}</i>
            </Row>
          </Row>

          <Row hidden={pricingType === 1}>
            {
              <InputGroup className="mb-3">
                <InputGroup.Text>
                  {t("components.area.static.singleArea.header.defaultPrice") +
                    ` (${area?.currency ? area?.currency : parent.currency}/kWh)`}
                </InputGroup.Text>
                <Form.Control
                  type="number"
                  step="0.01"
                  value={privatePrice}
                  onChange={(event) => {
                    setPrivatePrice(event.target.value);
                  }}
                  onBlur={(event) => {
                    onPriceBlur(event.target.value, setPrivatePrice);
                  }}
                  aria-label="area.privatePrice"
                  aria-describedby="privatePrice"
                />
              </InputGroup>
            }
            {
              <>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    {t("components.area.static.singleArea.header.publicPrice") +
                      ` (${area?.currency ? area?.currency : parent.currency}/kWh)`}
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={publicPrice}
                    onChange={(event) => {
                      setPublicPrice(event.target.value);
                    }}
                    onBlur={(event) => {
                      onPriceBlur(event.target.value, setPublicPrice);
                    }}
                    aria-label="area.publicPrice"
                    aria-describedby="publicPrice"
                  />
                </InputGroup>
                <InputGroup className="mb-3">
                  <InputGroup.Text>
                    {t("components.area.static.singleArea.header.publicStart") +
                      ` (${area?.currency ? area?.currency : parent.currency}/kWh)`}
                  </InputGroup.Text>
                  <Form.Control
                    type="number"
                    step="0.01"
                    value={publicStartPrice}
                    onChange={(event) => {
                      setPublicStartPrice(event.target.value);
                    }}
                    onBlur={(event) => {
                      onPriceBlur(event.target.value, setPublicStartPrice);
                    }}
                    aria-label="area.publicStartPrice"
                    aria-describedby="publicStartPrice"
                  />
                </InputGroup>
              </>
            }
          </Row>
          <Row hidden={pricingType === 1}>
            <Col className="mb-4">
              <i>{t("components.area.static.singleArea.header.tooltip")}</i>
            </Col>
          </Row>
          {publicPriceAlert && (
            <Col>
              <Alert variant="danger">{t("global.alert.chargepointForm.invalidPrice")}</Alert>
            </Col>
          )}
        </>
      ) : (
        <br />
      )}
    </div>
  );
};

interface AreaNameAndParentProps {
  name: string;
  setName: StateHandler<string>;
  parent: AreaBase;
  areas: AreaBase[];
  setParent: StateHandler<AreaBase>;
  disabledFields: boolean;
}

export const AreaNameAndParent = ({
  name,
  setName,
  parent,
  areas,
  setParent,
  disabledFields,
}: AreaNameAndParentProps) => {
  const { t } = useTranslation();
  const rootDefault = 0;
  return (
    <>
      <InputGroup className="mb-3">
        <InputGroup.Text id="name">{t("components.area.addArea.static.form.area")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={name}
          aria-label={"area-name"}
          aria-describedby="name"
          onChange={(e) => setName(e.target.value)}
          data-cy="editAreaNameField"
        />
      </InputGroup>
      <InputGroup className="mb-3">
        <DropdownButton
          id="dropdown-parent-button"
          title={t("components.area.addArea.static.form.parentId")}
          drop="down"
        >
          {areas.map((area: AreaBase, idx: number) => {
            return (
              <Dropdown.Item key={idx} as="button" onClick={() => setParent(area)}>
                {`${area.name} (${area.id})`}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <Form.Control
          id="parentInput"
          aria-label="Parent input"
          value={
            parent.id === rootDefault
              ? `${parent.name} (${t("components.area.addArea.static.form.default")})`
              : `${parent.name}`
          }
          disabled={disabledFields}
          readOnly /*readOnly is set so that the user cannot change the field directly*/
        />
      </InputGroup>
    </>
  );
};
