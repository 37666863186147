import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TransactionCp, ChargepointClass, ChargepointMap } from "../../../model/Classes/Chargepoint";

import AsyncSelect from "react-select/async";
import { components, GroupProps } from "react-select";

const CustomGroup = (props: GroupProps<any>) => (
  <>
    {
      // @ts-ignore
      !!!props.data.isHidden && <components.Group {...props} />
    }
  </>
);

const ChargepointDropdown = ({
  onSelectChargepoint,
  selectedChargepoint,
  publicChargepoints,
  myChargepoints,
  recentChargepoints,
}: {
  onSelectChargepoint: Function;
  selectedChargepoint?: TransactionCp;
  publicChargepoints: ChargepointMap;
  myChargepoints: ChargepointMap;
  recentChargepoints: ChargepointMap;
}) => {
  const { t } = useTranslation();

  const [allChargepoints] = useState(new Map([...publicChargepoints, ...myChargepoints, ...recentChargepoints]));

  const handleSelect = (option: any) => {
    if (!!option && option.value) {
      onSelectChargepoint(allChargepoints.get(option.value));
    } else {
      onSelectChargepoint(undefined);
    }
  };

  const options = [
    {
      label: t("components.startTransaction.view.recentUsed"),
      options: [...recentChargepoints].map(([id, cp]) => ({
        value: id,
        label: ChargepointClass.toStringWithId(cp),
      })),
    },
    {
      label: t("components.startTransaction.view.privateCp"),
      options: [...myChargepoints].map(([id, cp]) => ({
        value: id,
        label: ChargepointClass.toStringWithId(cp),
      })),
    },
    {
      label: t("components.startTransaction.view.publicCp"),
      options: [...publicChargepoints].map(([id, cp]) => ({
        value: id,
        label: ChargepointClass.toStringWithId(cp),
      })),
      isHidden: true,
    },
  ];

  let selectionState: {} | undefined;
  if (!!selectedChargepoint) {
    selectionState = {
      value: selectedChargepoint.charge_point_id,
      label: ChargepointClass.toStringWithId(selectedChargepoint),
    };
  }

  // We use AsyncSelect to be able to filter like this
  const loadOptions = (inputValue: string, callback: Function) => {
    const filterOptions = (inputValue: string) => {
      // Gets rid of all groups, hence showing also public options
      return options.flatMap((group) =>
        group.options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
      );
    };
    const filteredOptions = filterOptions(inputValue);

    // Populates the dropdown
    callback(filteredOptions);
  };

  return (
    <>
      <AsyncSelect
        className="w-250"
        //defaultInputValue={cpId}
        value={selectionState}
        defaultOptions={options}
        loadOptions={loadOptions}
        isSearchable={true}
        isClearable
        placeholder={t("components.startTransaction.view.cpDropdown")}
        components={{ Group: CustomGroup }}
        styles={{
          menu: (provided) => ({
            ...provided,
            zIndex: 1000, // Adjust the z-index value as needed
          }),
        }}
        onChange={handleSelect}
      />
    </>
  );
};

export default ChargepointDropdown;
