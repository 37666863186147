import cpHealth0 from '../../resources/cpHealth0.svg';
import cpHealth1 from '../../resources/cpHealth1.svg';
import cpHealth2 from '../../resources/cpHealth2.svg';
import cpHealth3 from '../../resources/cpHealth3.svg';
import cpConnected from '../../resources/cpConnected.svg';
import cpDisconnected from '../../resources/cpDisconnected.svg';

export const ChargePointStatusImg = ({ chargepoint }) => {
  return (
    <>
      <img className='mx-2'
        src={
          chargepoint.health === 0 ? cpHealth0 :
          chargepoint.health === 1 ? cpHealth1 :
          chargepoint.health === 2 ? cpHealth2 :
          cpHealth3
        }
        alt={
          chargepoint.health === 0 ? "Unknown or disable" :
          chargepoint.health === 1 ? "Ok" :
          chargepoint.health === 2 ? "Warning" :
          "Error"
        }
        data-cy={
          chargepoint.health === 0 ? "health-0" :
          chargepoint.health === 1 ? "health-1" :
          chargepoint.health === 2 ? "health-2" :
          "health-3"
        }
      />
      <img
        src={chargepoint.connected ? cpConnected : cpDisconnected} 
        alt={chargepoint.connected ? "Connected" : "Disconnected"} 
        data-cy={chargepoint.connected ? "connected" : "disconnected"}
      />
    </>
  )
};

export default ChargePointStatusImg;