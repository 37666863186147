import { InputGroup, Form, FormControl, Button } from 'react-bootstrap';
import { search } from "../../services/adminService";
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import AdminSearchResult from './AdminSearchResult';

const AdminSearch = ({ onContractSelect, activeEventKey }) => {
    const { t } = useTranslation();
    
    const [searchResult, setSearchResult] = useState<any | null>(null);
    const [gotResults, setGotResults] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current && activeEventKey === "search") {
            inputRef.current?.focus();
        }
    }
    , [activeEventKey]);

    async function searchHandler(e) {
        e.preventDefault();
    
        const formData = new FormData(e.target);
    
        // if cannot get searchInput ten set it to ""
        const searchInput = (formData.get("searchInput") as string) || "";

        function sortResults(resData: Object) {
            let sortedRes = {};
            
            const SortBy = {
                contracts: "name",
                chargepoints: "nickname",
                areas: "name",
            }

            // example:
            // tablename: contracts 
            // tablesResArray: [{id: 1, name: "WTRY Oy"...}, {id: 2, name: "Wattery Oy"...}]

            Object.entries(resData).forEach(([tableName, tableResArray]) => {
                // check if the value is an array
                if (Array.isArray(tableResArray) && SortBy[tableName]) {
                    // sort the array alphabetically by desired key
                    sortedRes[tableName] = tableResArray.sort((a, b) => {
                        const sortKey = SortBy[tableName];
                        if (!a[sortKey]) return 1;
                        if (!b[sortKey]) return -1;
                        return a[sortKey].localeCompare(b[sortKey]);
                    });
                } else {
                    sortedRes[tableName] = tableResArray; // Preserve non-array values to avoid errors
                }
            });

            return sortedRes;
        }

        try {
            const res = await search(searchInput);
            const sortedResults = sortResults(res.data);
            setSearchResult(sortedResults);
            setGotResults(// if data is found, then check if there are any results
                res.data.chargepoints?.length > 0 ||
                res.data.contracts?.length > 0 ||
                res.data.areas?.length > 0
            );
        }
        catch (error: any) {
            console.log("No results found");
            console.log("Error", error);
        }
    }

    return (
        <>
        <Form onSubmit={searchHandler}>
            <InputGroup className="mb-3">
                <FormControl
                ref={inputRef}
                placeholder={t('components.admin.searchbar.placeholder')}
                type="text"
                name="searchInput"
                minLength={3}
                maxLength={100}
                />
                <Button variant="primary" type="submit">{t('components.admin.search')}</Button>
            </InputGroup>
        </Form>
        <AdminSearchResult
            searchResult={searchResult}
            gotResults={gotResults}
            onContractSelect={onContractSelect}/>
        </>
    );
};

export default AdminSearch;