import { Col } from 'react-bootstrap';
import BootstrapTable, { ColumnDescription } from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CpFull, {
    ChargepointClass,
} from '../../../../model/Classes/Chargepoint';
import cpHealth0 from '../../../../resources/cpHealth0.svg';
import cpHealth1 from '../../../../resources/cpHealth1.svg';
import cpHealth2 from '../../../../resources/cpHealth2.svg';
import cpHealth3 from '../../../../resources/cpHealth3.svg';
import cpConnected from '../../../../resources/cpConnected.svg';
import cpDisconnected from '../../../../resources/cpDisconnected.svg';
import { AreaFull } from '../../../../model/Classes/Area';

declare interface SingleAreaChargepointsProps {
  chargePoints: CpFull[];
  area: AreaFull;
}
/**
 * Component responsible for displaying a list with all the chargepoints for an area (if any).
 * @param {*} chargePoints all the chargepoints for the area being handled
 * @param {*} area the area that is being handled
 * @returns a list with all the chargepoints for an area
 */
export const SingleAreaChargepoints = ({
  chargePoints,
  area,
}: SingleAreaChargepointsProps) => {
  const { t } = useTranslation();
  /* const options = [
    { value: '', label: 'Show this area + subareas' },
    { value: area.name, label: 'Show only this area' },
  ]; */

  const columns: ColumnDescription<CpFull>[] = [
    {
      dataField: 'health',
      text: '',
      formatter: (cell, row: CpFull, rowIndex, extraData) => (
        <>
          {row.health === 0 ? (
            <img src={cpHealth0} alt="Unknown or disable" data-cy="health-0" />
          ) : row.health === 1 ? (
            <img src={cpHealth1} alt="Ok" data-cy="health-1" />
          ) : row.health === 2 ? (
            <img src={cpHealth2} alt="Warning" data-cy="health-2" />
          ) : (
            <img src={cpHealth3} alt="Error" data-cy="health-3" />
          )}{' '}
          {row.connected ? (
            <img src={cpConnected} alt="Connected" data-cy="connected" />
          ) : (
            <img
              src={cpDisconnected}
              alt="Disconnected"
              data-cy="disconnected"
            />
          )}
        </>
      ),
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'desc') {
          return b - a; // Sort in descending order when 'asc' is selected
        }
        return a - b; // Sort in ascending order when 'desc' is selected or by default
      },
    },
    {
      dataField: 'charge_point_id',
      text: `${t('components.area.table.cp')}`,
      formatter: (cell, row: CpFull, rowIndex, extraData) => (
        <>
          <Link to={`/area/${row.area_id}/chargepoint/${row.charge_point_id}`}>
            {ChargepointClass.toString(row)}
          </Link>
        </>
      ),
      sort: true,
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        let first = rowA.nickname ? rowA.nickname : rowA.charge_point_id;
        let second = rowB.nickname ? rowB.nickname : rowB.charge_point_id;
        const sorting = first!
          .toLocaleLowerCase()
          .localeCompare(second!.toLocaleLowerCase());
        return order === 'asc' ? sorting : -sorting;
      },
    },
    {
      dataField: 'area_name',
      text: `${t('components.area.table.area')}`,
      formatter: (cell, row: CpFull, rowIndex, extraData) => (
        <Link to={`/area/${row.area_id}`}>{row.area_name}</Link>
      ),
      sort: true,
      /* Can't get this filter to work with TypeScript right now, since the options only accepts { value: number, label: string}
      and not what was supplied before the refactor ( { value: string, label: string} ). No clue why or how JavaScript accepts it
      as a valid input */
      /* filter: selectFilter({
        options: options,
        comparator: Comparator.LIKE,
        defaultValue: '',
        withoutEmptyOption: true,
      }), */
    },
    {
      dataField: 'disabled',
      text: `${t('components.area.table.disabled')}`,

      hidden: true,
      sort: true,
      sortFunc: (a, b, order) => {
        if (order === 'asc') {
          return b - a; // Sort in descending order when 'asc' is selected
        }
        return a - b; // Sort in ascending order when 'desc' is selected or by default
      },
    },
  ];
  // Function to determine the row style based on the health status of the chargepoint
  const getRowStyle = (row: any, rowIndex: number): React.CSSProperties => {
    const isConnected = row.disabled;
    let rowStyle: React.CSSProperties = {};
    //if health os 0 opacity is decreaed to whole row to minimise its visibility
    if (isConnected === 1) {
      rowStyle.opacity = '0.4';
    }
    return rowStyle;
  };
  return (
    //Check if the area has any chargepoints
    chargePoints.length > 0 ? (
      <>
        <Col>
          <h4>{t('components.area.static.singleArea.header.hasCp')}</h4>
        </Col>
        <ToolkitProvider
          bootstrap4
          keyField="charge_point_id"
          data={chargePoints}
          columns={columns}
          data-cy="chargepoint-table"
        >
          {(props) => (
            <BootstrapTable
              id="cp-table"
              striped
              bordered
              hover
              noDataIndication="Table is Empty"
              defaultSorted={[{ dataField: 'health', order: 'desc' }]}
              pagination={paginationFactory({
                hidePageListOnlyOnePage: true,
              })}
              rowStyle={getRowStyle}
              {...props.baseProps}
            />
          )}
        </ToolkitProvider>
      </>
    ) : (
      <h5>{t('components.area.static.singleArea.header.noCp')}</h5>
    )
  );
};
