import { useState, useEffect } from 'react';
import {
    InputGroup,
    Dropdown,
    DropdownButton,
    FormControl,
    Alert,
    Button,
    ButtonGroup,
    Modal,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AreaSingle from '../../../../model/Classes/Area';
import { timer } from '../../../../utils/timer';

/**
 * Component responsible for displaying the chargepoint move view. Also responsible for filtering the possible
 * parents the user can move the chargepoint under (all the areas the user has access to that does not belong
 * to this chargepoints subtree (nor this chargepoint itself)).
 * @param {*} chargepoint the chargepoint to be moved
 * @param {*} allAreas all the areas the user has access to
 * @param {*} area the area the chargepoint belongs to
 * @param {*} handleSubmitMoveCp helper function for submitting the move
 * @param {*} history history object
 * @returns the move chargepoint view
 */
const EditMoveCp = ({
  chargepoint,
  allAreas,
  area,
  handleSubmitMoveCp,
  history,
  user,
}) => {
  const [possibleParents, setPossibleParents] = useState<AreaSingle[]>([]); //state for all the possible parents
  const [fieldValue, setFieldValue] = useState({ name: '' }); //state for the input field value
  const [showChange, setShowChange] = useState(false); //state for showing the popup
  const [showAlert, setShowAlert] = useState(false); //state for showing an alert notifying the user that there is not point in moving the chargepoint under its origin parent
  const [showError, setShowError] = useState(false); //state for showing the failed alert
  const [showContractAlert, setshowContractAlert] = useState(false); //state for showing the failed alert when new area contract is 0

    const { t } = useTranslation();
  /**
   * Helper function for showing the move popup. If the input field is the original parent,
   * don't show the modal, just show an alert notifying the user that the move has no effect.
   */

  const showMoveModal = () => {
    const newParent: AreaSingle = fieldValue;

    //If the set parent is the same as the original parent,
    //simply show an alert and do not allow the user to change it this way
    if (newParent.id === area.id) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      setShowChange(true);
    }
    if (user.user_level < 2 && newParent.contract_id === 0) {
      setshowContractAlert(true);
    } else {
      setshowContractAlert(false);
      setShowChange(true);
    }
  };

  /**
   * Helper function for calling the handleSubmitMoveCp() function. If the move was successful,
   * set the successfullMove state to true and redirect to the new areas area page. If the move
   * was unsuccessfull, show an error alert
   */
  const makeMove = async () => {
    //Data to be sent to the backend
    const newParent: AreaSingle = fieldValue;
    const data = {
      old_area_id: area.id,
      charge_point_id: chargepoint.charge_point_id,
      new_area_id: newParent.id,
    };

    setShowChange(false);
    (await handleSubmitMoveCp(data))
      ? history.push({
          pathname: `/area/${newParent.id}`,
          state: { cpMoved: true },
        })
      : timer(setShowError);
  };

  //Sort all the parents in alphabetical order and update the corresponding state. Additionally,
  //set the input field initially to the area the chargepoint currently belongs to.
  useEffect(() => {
    const sortedParents = allAreas.sort((a, b) => a.id - b.id);
    setPossibleParents(sortedParents);
    setFieldValue(area);
  }, [allAreas, chargepoint.area_id, area]);

  /**
   * The component intially returns
   * 1) possibly an alert notifying the user that the move failed
   * 2) text informing the user about moving the chargepoint + which area is the original parent
   * 3) input field with a dropdown for choosing the area to move this chargepoint under
   * 4) the move button
   * 5) possibly an alert notifying the user that moving the chargepoint under its original parent has no effect
   *
   * If the user clicks the "Move" button, a popup (modal) appears. The popup informs the user about the move and
   * asks the user to confirm the move. This is done to add another layer to moving the chargepoint (less
   * error prone). When the user confirms the action, function makeMove() is called (description found in the
   * comments).
   */
  return (
    <>
      {showError ? (
        <Alert key="error" variant="danger">
          {t('global.view.errorTry')}
        </Alert>
      ) : null}
      {showContractAlert ? (
        <Alert key="error" variant="danger">
          {t('global.alert.failure.cpMove')}
        </Alert>
      ) : null}

      <div //icky wicky
        dangerouslySetInnerHTML={{
          __html: t('components.chargepoint.edit.tabs.move.moveTo', {
            interpolation: { escapeValue: false },
            areaName: area.name,
          }),
        }}
      />
      {/*Dropdown and input field for the move*/}
      <InputGroup className="mb-3">
        <DropdownButton
          id="dropdown-item-button"
          title={t('components.area.addArea.static.form.parentId')}
          drop="down"
        >
          {possibleParents.map((parent, idx) => {
            return (
              <Dropdown.Item
                key={idx}
                as="button"
                onClick={() => setFieldValue(parent)}
              >
                {`${parent.name} (${parent.id})`}
              </Dropdown.Item>
            );
          })}
        </DropdownButton>
        <FormControl aria-label="Text input" value={fieldValue.name} readOnly />
      </InputGroup>
      <>
        <ButtonGroup className="mb-3">
          <>
            <Button variant="primary" onClick={showMoveModal}>
              {t('components.chargepoint.edit.tabs.move.buttons.move')}
            </Button>
            {/*The Modal component is used to display the popup*/}

            {!showContractAlert && (
              <Modal
                show={showChange}
                onHide={() => setShowChange(false)}
                backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
                keyboard={false} //doesn't allow the user to close the modal by using the keyboard
              >
                <Modal.Header>
                  <Modal.Title>
                    {t('components.chargepoint.edit.tabs.move.modal.header')}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div //icky wicky
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'components.chargepoint.edit.tabs.move.modal.sure',
                        {
                          interpolation: { escapeValue: false },
                          chargepoint_id: chargepoint.charge_point_id,
                          oldAreaName: area.name,
                          newAreaName: fieldValue.name,
                        }
                      ),
                    }}
                  />
                  {}
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="success" onClick={makeMove}>
                    {t('global.buttons.submit')}
                  </Button>
                  <Button
                    variant="secondary"
                    onClick={() => setShowChange(false)}
                  >
                    {t('global.buttons.cancel')}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </>
        </ButtonGroup>
      </>
      {showAlert ? (
        <Alert key="noChangeMove" variant="warning">
          {t('components.chargepoint.edit.tabs.move.requireChange')}
        </Alert>
      ) : null}
    </>
  );
};

export default EditMoveCp;
