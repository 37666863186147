import { useTranslation } from "react-i18next";
import { InputGroup, Form, Alert } from "react-bootstrap";
import { StateHandler } from "../../../../model/Utilities/Types";
import { AreaFull } from "../../../../model/Classes/Area";

declare interface ChargepointPricingProps {
  price: string;
  setPrice: StateHandler<string>;
  publicPrice: string;
  publicStartPrice: string;
  setPublicPrice: StateHandler<string>;
  setPublicStartPrice: StateHandler<string>;
  disabledFields: boolean;
  accessType?: number;
  showPriceAlert: boolean;
  area: AreaFull;
  pricingType?: number;
}

export const ChargepointPricing = ({
  price,
  setPrice,
  publicPrice,
  publicStartPrice,
  setPublicPrice,
  setPublicStartPrice,
  disabledFields,
  showPriceAlert,
  accessType,
  area,
  pricingType,
}: ChargepointPricingProps) => {
  const { t } = useTranslation();

  const onPriceBlur = (value: string, setState: StateHandler<string>) => {
    let num = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    const cleanNum = num.toFixed(2);
    setState(cleanNum);
  };

  return (
    <>
      {/*Simple number input field for the price field. Min = 0, Max = 10*/}

      {(accessType === 1 || accessType === 2) && (
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">
            {t("components.area.addCp.details.price")} ({area.currency}/kWh)
          </InputGroup.Text>
          <Form.Control
            type="number"
            step="0.01"
            min="0"
            max="10"
            value={price}
            placeholder={t("components.area.addCp.details.pricePlaceholder")}
            aria-label={price}
            aria-describedby="Price"
            data-cy="price-field"
            disabled={disabledFields}
            onChange={(event) => setPrice(event.target.value)}
            onBlur={(event) => onPriceBlur(event.target.value, setPrice)}
          />
        </InputGroup>
      )}

      {accessType && accessType >= 2 ? (
        <>
          <InputGroup className="mb-3">
            <InputGroup.Text id="name">
              {t("components.area.addCp.details.publicPrice")} ({area.currency}/kWh)
            </InputGroup.Text>

            <Form.Control
              type="number"
              step="0.01"
              min="0"
              max="10"
              value={publicPrice}
              aria-label={"publicPrice"}
              aria-describedby="Price"
              data-cy="public-price-field"
              disabled={disabledFields}
              onChange={(event) => setPublicPrice(event.target.value)}
              onBlur={(event) => onPriceBlur(event.target.value, setPublicPrice)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="name">
              {t("components.area.addCp.details.publicStart")} ({area.currency})
            </InputGroup.Text>

            <Form.Control
              type="number"
              step="0.01"
              min="0"
              max="10"
              value={publicStartPrice}
              aria-label={"publicStartPrice"}
              aria-describedby="start-price"
              data-cy="start-price-field"
              disabled={disabledFields}
              onChange={(event) => setPublicStartPrice(event.target.value)}
              onBlur={(event) => onPriceBlur(event.target.value, setPublicStartPrice)}
            />
          </InputGroup>
        </>
      ) : null}
      {showPriceAlert && ( //Show alert notifying the user if the price is invalid
        <Alert key="priceAlert" variant="danger">
          {t("global.alert.chargepointForm.invalidPrice")}
        </Alert>
      )}
    </>
  );
};
