import { InputGroup, Form, Button, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import StructuredResponse from "../../../model/Classes/StructuredResponse";
import { StateHandler } from "../../../model/Utilities/Types";
import { toast } from "react-toastify";

type UserSettingsPasswordProps = {
  oldPassword: string;
  newPassword: string;
  newConfirmPassword: string;
  setOldPassword: StateHandler<string>;
  setNewPassword: StateHandler<string>;
  setNewConfirmPassword: StateHandler<string>;
  handleSubmitUserSettingsPassword: () => Promise<StructuredResponse<any>>;
};

/**
 *
 * @param param0
 * @returns
 */
const UserSettingsPassword = ({
  oldPassword,
  newPassword,
  newConfirmPassword,
  setOldPassword,
  setNewPassword,
  setNewConfirmPassword,
  handleSubmitUserSettingsPassword
}: UserSettingsPasswordProps) => {

  const { t } = useTranslation();

  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   *
   * NOTE! The old password cannot be validated on the front end, and must thus be validated on the backend. Hence,
   * no need to validate the old password here.
   * @returns true if the validation passes, false otherwise
   */
  const validate = () => {
    if (newPassword.length < 8) {
      toast.error(t("global.alert.failure.pwShort"));
      return false
    } else if (newPassword !== newConfirmPassword) {
      toast.error(t("global.alert.failure.noPwMatch"));
      return false;
    }
    return true;
  };

  /**
   * Asynchronous helper function for calling the validate() function, and based on the result, call the helper function
   * handleSubmitUserSettingsPassword() in the UserSettings component to send the data changes to the backend or show
   * an error alert.
   */
  const checkUpdate = async () => {
    if (validate()) {
      const res = await handleSubmitUserSettingsPassword();
      if (res.success) {
        toast.success(t("global.alert.success.pwdChange"));
        setOldPassword("");
        setNewPassword("");
        setNewConfirmPassword("");
      } else if (res.status === 400) { // Old password wrong
        toast.error(t("global.alert.failure.oldPw"));
      } else {
        toast.error(t("global.view.errorTry"));
      }
    }
  };

  return (
    <>
      <Row>
        {/*Simple text input field for the old password field*/}
        <InputGroup className="mb-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.old")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={oldPassword}
            aria-label="Old password"
            aria-describedby="Old password"
            onChange={(event) => setOldPassword(event.target.value)}
          />
        </InputGroup>

        {/*Simple text input field for the new password field*/}
        <InputGroup>
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.new")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={newPassword}
            aria-label="New password"
            aria-describedby="New password"
            onChange={(event) => setNewPassword(event.target.value)}
          />
        </InputGroup>
        <Form.Text className="text-muted">{t("components.userSettings.tabs.password.note")}</Form.Text>

        {/*Simple text input field for the confirm password field */}
        <InputGroup className="mb-3 mt-3">
          <InputGroup.Text id="name">{t("components.userSettings.tabs.password.confirm")}</InputGroup.Text>
          <Form.Control
            type="password"
            value={newConfirmPassword}
            aria-label="Confirm password"
            aria-describedby="Confirm password"
            onChange={(event) => setNewConfirmPassword(event.target.value)}
          />
        </InputGroup>
      </Row>
      {/*Row for the update password button*/}
      <Row>
        {/*The save button needs to be wrapped around a Col component, since otherwise it will
                   stretch across the whole screen*/}
        <Col xs={3} sm={3}>
          <Button variant="primary" onClick={checkUpdate} disabled={!oldPassword || !newPassword || !newConfirmPassword}>
            {t("components.userSettings.tabs.password.update")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default UserSettingsPassword;
