import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import { StyledHelp } from "./Help.styled";
import pdfIcon from "../../../resources/pdfIcon.svg";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

const manuals = {
  fi: "https://files.wattery.io/docs/manuals/kayttoohjeet_loppukayttaja.pdf",
  sv: "https://files.wattery.io/docs/manuals/anvandarmanual_slutanvandare.pdf",
  en: "https://files.wattery.io/docs/manuals/usermanual_enduser.pdf",
};

const Help = ({ user }) => {
  const { t } = useTranslation();
  return (
    <StyledHelp className="top-level-component">
      <Container id="component-margin">
        <Row className="mb-3" style={{ textAlign: "center" }}>
          <Col>
            <h2>{t("components.help.static.header")}</h2>
          </Col>
        </Row>
        <Row>
          <Tabs defaultActiveKey="documentation" id="" className="mb-3">
            <Tab eventKey="documentation" title={t("components.help.static.docs")}>
              <>
                <img src={pdfIcon} alt="pdf" />{" "}
                <a target="_blank" rel="norefferer noreferrer" href={manuals[i18n.language]}>
                  {t("components.help.static.userGuide")}
                </a>
              </>
              <br />
              <br />
              {user.user_level > 0 && (
                <>
                  <img src={pdfIcon} alt="pdf" />{" "}
                  <a
                    target="_blank"
                    rel="norefferer noreferrer"
                    href={`https://files.wattery.io/docs/manuals/kayttoohjeet_paakayttaja.pdf`}
                  >
                    Käyttöohjeet pääkäyttäjälle (Finnish only at the moment / Endast på finska för tillfället)
                    {/*t("components.help.static.adminGuide")*/}
                  </a>
                </>
              )}
            </Tab>
          </Tabs>
        </Row>
      </Container>
    </StyledHelp>
  );
};

export default Help;
