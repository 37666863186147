import { StyledAdminSearch } from "./AdminSearch.styled";
import { ListGroup } from "react-bootstrap";
import { useState } from "react";
import AdminUserInfo from "./AdminUserInfo";

const AdminUserSearchResult = ({ searchResult, viewUser, setViewUser }) => {

  const [userId, setUserId] = useState(-1);

  return (
    <StyledAdminSearch>
    <div className="userSearchResult">
        {!searchResult ? null : (
            searchResult.response ? (
              <>
                <h2>{searchResult.response.statusText}</h2>
                <details>
                  <summary className="summary">
                    Show error message
                  </summary>
                  <pre>{JSON.stringify(searchResult.response, null, 2)}</pre>
                </details>
              </>
            ) :
            searchResult.length === 0 ? "No users found" : (
            !viewUser ? (
              <>
                <h3>Users</h3>
                <ListGroup>
                  {searchResult.map((user, index) => (
                    <ListGroup.Item key={index} 
                      className="user-list" 
                      onClick={() => {
                        setUserId(user.id);
                        setViewUser(true)
                      }}
                    >
                      <div>
                        {user.id} {user.first_name} {user.last_name}
                      </div>
                      <div>
                        {user.email}
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </>
          ) : (
            <AdminUserInfo userId={userId}/>
          ))
        )}
    </div>
    </StyledAdminSearch>
  );
}

export default AdminUserSearchResult;