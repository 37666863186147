import styled from "styled-components";

/**
 * Styled component for the transaction accordion
 */

export const StyledTransactionAccordion = styled.div`

    .transaction-header:hover {
        background-color: #f0f0f0;
    }
`;