import { StyledTransactionAccordionBody } from "./TransactionAccordionBody.styled";
import getCurrencySymbol from "src/utils/getCurrencySymbol";

const AdminUserTransactionBody = ({ transaction }) => {
  return (
    <StyledTransactionAccordionBody>
      id : {transaction.id}
      {transaction.pricing_type === 0 ? (
        <>
          <div>pricing_type : kWh-based</div>
          <div className="kWh-based">
            <span>start_price : {transaction.start_price + getCurrencySymbol(transaction.currency)}</span>
            <span>price : {(transaction.price * (transaction.meter_value / 1000)).toFixed(2) + getCurrencySymbol(transaction.currency)}</span>
          </div>
        </>
      ) : transaction.pricing_type === 1 ? (
        <>
          <div>pricing_type : spot price</div>
          <div className="spot-price">
            <span>margin : {transaction.margin + getCurrencySymbol(transaction.currency)}</span>
          </div>
        </>
      ) : transaction.pricing_type === 2 ? (
        <>
          <div>pricing_type : free user</div>
        </>
      ) : (
        <>
        <div>pricing_type : unknown</div>
        <p>{JSON.stringify(transaction)}</p>
        </>
      )}
      tag : {transaction.tag_nickname}
    </StyledTransactionAccordionBody>
  );
};

export default AdminUserTransactionBody;
