import styled from "styled-components";

/**
 * Styled component for the admin user info
 */

export const StyledAdminUserInfo = styled.div`

    .container {
        padding: 0;
    }
`;