import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { Button, Container, Row, Col, Tabs, Tab, Alert } from "react-bootstrap";
import { StyledChargepoint } from "./Chargepoint.styled";
import { ChargepointDiagnostics, ChargepointGeneral, ChargepointStatus } from "./ChargepointComponents";
import { getAreaBranches } from "../../../services/areaService";
import { forceStop } from "../../../utils/forceStop";
import { remoteStop } from "../../../utils/remoteStop";
import ActiveTransactions from "../../public/Transactions/ActiveTransactions";
import editIcon from "../../../resources/editIcon.svg";
import EditChargepoint from "./Settings/EditChargepoint";
import { refreshTable } from "../../../utils/refreshActiveTransactions";
import { logger } from "../../../utils/logger";
import { timer } from "../../../utils/timer";
import CpFull, { ChargepointClass } from "../../../model/Classes/Chargepoint";
import AreaSingle, { AccessType, AreaBase } from "../../../model/Classes/Area";
import { ActiveTransaction } from "../../../model/Classes/Transaction";
import User from "../../../model/Classes/User";
import { useTranslation } from "react-i18next";
import { PrintableChargepointQR } from "./ChargepointQR";
import ReactToPrint from "react-to-print";
import { getSingleChargepoint } from "../../../services/areaService";
import ResetChargepointModal from "./ResetChargepointModal";
import { useOnTabClicked } from "../../../hooks";
import { getCpDiagnosticMessages } from "../../../services/chargepointService";
import DiagnosticsMessage from "../../../model/Classes/DiagnosticsMessage";
import { getAllActiveTransactionsForChargepoint } from "../../../services/reportingService";
import UnlockConnectorModal from "./UnlockConnectorModal";
import { PrintableChargepointNoLoginSticker } from "./ChargepointNoLoginSticker";

declare interface ChargepointProps {
  chargepoint: CpFull;
  area: AreaSingle;
  user: User;
  toggleEdit: () => void;
  activeTransactions: ActiveTransaction[];
  refresh: () => Promise<void>;
  showCheckmark: boolean;
  showLoading: boolean;
  showCheckmarkStatusTable: boolean;
  showLoadingStatusTable: boolean;
  history: any;
  callForceStop: (id: number, area_id?: number) => Promise<void>;
  callRemoteStop: (id: number, cpId?: string) => Promise<void>;
  forceStopSuccess: boolean;
  forceStopError: boolean;
  forceStopSuccessButRefreshActive: boolean;
  remoteStopSuccess: boolean;
  remoteStopError: boolean;
  remoteStopSuccessButRefreshActive: boolean;
  executionTimeExceeded: boolean;
  refreshStatusTable: () => Promise<void>;
}
/**
 * Component responsible for displaying the chargepoint page. The component contains a tab view for
 * Tab 1: general info + active transactions
 * Tab 2: commands (functionality will be implemented in the future)
 */
const Chargepoint = ({
  chargepoint,
  area,
  user,
  toggleEdit,
  activeTransactions,
  refresh,
  showCheckmark,
  showLoading,
  showCheckmarkStatusTable,
  showLoadingStatusTable,
  history,
  callForceStop,
  callRemoteStop,
  forceStopSuccess,
  forceStopError,
  forceStopSuccessButRefreshActive,
  remoteStopSuccess,
  remoteStopError,
  remoteStopSuccessButRefreshActive,
  executionTimeExceeded,
  refreshStatusTable,
}: ChargepointProps) => {
  const { t } = useTranslation();
  const qrRef = useRef(null);
  const noLoginStickerRef = useRef(null);

  /**
   * The component returns:
   * 1) The title of the chargepoint + edit button
   * 2) Tab to navigate between the general page (contains general info and active transactions), and commands page
   * 3) Button to navigate back to the area this chargepoint belongs to
   *
   * The component rendering the general page and active transactions are defined in their own files (ChargepointComponents.js
   * and ActiveTransactions.js respectively). This was done to make the code much cleaner and easier to read.
   */

  /* extra function similar to the once in the singleArea to handle the on tab click functionality for the Diagnostic messages */
  const [activeEventKey, setActiveEventKey] = useState("general");
  const [diagnosticMessage, setDiagnosticMessage] = useState<DiagnosticsMessage[]>([]);

  const handleKeyChange = (eventValue: any) => {
    setActiveEventKey(eventValue);
  };

  useOnTabClicked(
    activeEventKey,
    ["diagnostics"],

    async () => {
      if (!chargepoint.charge_point_id) return;
      const diagMessage = await getCpDiagnosticMessages(chargepoint.charge_point_id);
      if (diagMessage.success) {
        setDiagnosticMessage(diagMessage.data);
      } else logger(diagMessage.data);
      return;
    },
    []
  );

  //fetching data from the backend and passig charge_point:id as property
  const refreshDiagnosticMessageFunc = async () => {
    if (!chargepoint.charge_point_id) return;
    const diagMessage = await getCpDiagnosticMessages(chargepoint.charge_point_id);
    if (diagMessage.success) {
      setDiagnosticMessage(diagMessage.data);
    }

    return;
  };

  return (
    <>
      <Row className="mb-2">
        <h1>
          {t("components.chargepoint.static.header", {
            chargepoint: ChargepointClass.formatNickname(chargepoint),
          })}
          <Button variant="outline-ligth">
            <img src={editIcon} alt="Edit icon" onClick={toggleEdit} />
          </Button>
        </h1>
      </Row>
      {/*CHECK THE BUTTON FOR REBOOT IN CHARGEPOINT*/}
      <Row className="text-center">
        <Col>
          <ResetChargepointModal
            activeTransactions={activeTransactions}
            chargePointId={chargepoint.charge_point_id}
            status={chargepoint.connected}
            executionTimeExceeded={executionTimeExceeded}
            refresh={refresh}
            refreshStatusTable={refreshStatusTable}
          />
        </Col>
        <Col>
          <UnlockConnectorModal
            chargepoint={chargepoint} // Pass the chargepoint object
            chargePointId={chargepoint.charge_point_id} // Pass the charge point ID
            executionTimeExceeded={executionTimeExceeded} // Pass the execution time exceeded flag
            refreshStatusTable={refreshStatusTable} // Pass the refreshStatusTable function
            refresh={refresh} // Pass the refresh function
            status={chargepoint.number_of_connectors}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "30px" }}>
        <Tabs defaultActiveKey="general" activeKey={activeEventKey} id="" className="mb-3" onSelect={handleKeyChange}>
          <Tab eventKey="general" title={t("components.chargepoint.static.tabs.general.header")}>
            <Row>
              <ChargepointStatus
                chargepoint={chargepoint}
                showCheckmarkStatusTable={showCheckmarkStatusTable}
                showLoadingStatusTable={showLoadingStatusTable}
                refreshStatusTable={refreshStatusTable}
              />
            </Row>
            <Row>
              <ChargepointGeneral chargepoint={chargepoint} area={area} />
            </Row>
            <Row>
              {executionTimeExceeded ? <Alert variant="warning">{t("global.alert.failure.exectionTime")}</Alert> : null}
              <ActiveTransactions
                user={user}
                forceStopSuccess={forceStopSuccess}
                forceStopError={forceStopError}
                forceStopSuccessButRefreshActive={forceStopSuccessButRefreshActive}
                remoteStopSuccess={remoteStopSuccess}
                remoteStopError={remoteStopError}
                remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
                activeTransactions={activeTransactions}
                showCheckmark={showCheckmark}
                showLoading={showLoading}
                refresh={refresh}
                callRemoteStop={callRemoteStop}
                callForceStop={callForceStop}
                forChargepoint={true}
              />
            </Row>
          </Tab>

          <Tab eventKey="diagnostics" title={t("components.chargepoint.static.tabs.diagnostics.header")}>
            <ChargepointDiagnostics
              charge_point_id={chargepoint.charge_point_id}
              diagnosticMessage={diagnosticMessage}
              refreshDiagnosticMessageFunc={refreshDiagnosticMessageFunc}
              showCheckmark={showCheckmark}
              showLoading={showLoading}
            />
          </Tab>
          <Tab eventKey="QR" title={t("components.chargepoint.static.tabs.QR.title")}>
            <PrintableChargepointQR chargepoint={chargepoint} width={256} ref={qrRef} />
            <ReactToPrint
              content={() => qrRef.current}
              trigger={() => <Button className="mb-3">{t("components.chargepoint.static.tabs.QR.print")}</Button>}
              documentTitle={ChargepointClass.toString(chargepoint)}
            ></ReactToPrint>
          </Tab>

          {chargepoint.access_type && chargepoint.access_type >= AccessType.privateAndPublic && (
            <Tab eventKey="noLoginSticker" title={t("components.chargepoint.static.tabs.noLoginSticker.title")}>
              <PrintableChargepointNoLoginSticker chargepoint={chargepoint} width={400} ref={noLoginStickerRef} />
              <ReactToPrint
                content={() => noLoginStickerRef.current}
                trigger={() => (
                  <Button className="mb-3">{t("components.chargepoint.static.tabs.noLoginSticker.print")}</Button>
                )}
                documentTitle={ChargepointClass.toString(chargepoint)}
              ></ReactToPrint>
            </Tab>
          )}
        </Tabs>
      </Row>
      <Row>
        <Col xs="4" sm="4">
          <Button className="mb-2" onClick={() => history.push(`/area/${area.id}`)} variant="secondary">
            {t("global.buttons.back")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

declare interface ChargepointViewProps {
  user: User;
  history: any;
}

/**
 * Component responsible for handling functionality related to a chargepoint, as well as displaying the correct
 * component (Chargepoint or EditChargepoint). The component does so with the help of states that are toggle on
 * if the corresponding component should be shown.
 *
 * The component fetches all the data needed for the page. The component does this with the useEffect hook. The information
 * it gathers is information about all the areas (needed for moving the chargepoint), all the chargepoints (from where the
 * single chargepoint is gotten from), as well as all the active transactions for the chargepoint.
 *
 * Authentication and authorization is checked at the backend. Since the first useEffect will always fetch data from the backend,
 * the backend authenticates and authorizes the user. If it passes, data is sent back to the frontend. If it doesn't (the
 * user doesn't have access to the area), an error message is displayed to the screen notifying that the user doesn't have
 * access to this area.
 *
 * NOTE! At the moment, when the frontend fetches data from the backend, and when the backend responds with an error (could be
 * the user is not authorized or an internal server error), an error message is displayed to the user informing the user
 * that they do not have access to this area. The error message should be checked and a correct error message should be displayed.
 * WILL BE IMPLEMENTED IN THE FUTURE!
 * @param {*} user the user accessing this chargepoint
 * @param {*} history history object
 */
const ChargepointView = ({ user, history }: ChargepointViewProps) => {
  const [chargepoint, setChargepoint] = useState<CpFull>(); //state for the chargepoint
  const [area, setArea] = useState<AreaSingle>(); //state for the area the chargepoint belongs to
  const [allAreas, setAllAreas] = useState<AreaSingle[]>([]); //state for all the areas the user has access to
  const [activeTransactions, setActiveTransactions] = useState<ActiveTransaction[]>([]); //state for all the active transactions for this chargepoint

  const [editMode, setEditMode] = useState(false); //state for displaying the edit mode
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or chargepoint screen
  const [runUseEffectAgain, setRunUseEffectAgain] = useState(false); //state for running the useEffect again
  const [runUseEffectMode, setRunUseEffectMode] = useState(0); //state for which mode to run the useEffect

  const [showCheckmark, setShowCheckmark] = useState(false); //state for showing the checkamrk (used for refresh button)
  const [showLoading, setShowLoading] = useState(false); //state for showing the loading animation (used for refresh button)
  const [showCheckmarkStatusTable, setShowCheckmarkStatusTable] = useState(false); //state for showing the checkamrk (used for refresh button)
  const [showLoadingStatusTable, setShowLoadingStatusTable] = useState(false); //state for showing the loading animation (used for refresh button)
  const [forceStopSuccess, setForceStopSuccess] = useState(false); //state for succesfully force stopping an active transaction alert
  const [forceStopError, setForceStopError] = useState(false); //state for failed force stopping of an active transaction alert
  const [forceStopSuccessButRefreshActive, setForceStopSuccessButRefreshActive] = useState(false); //state for succesfully force stopping an active transaction, but failed refreshing alert
  const [remoteStopSuccess, setRemoteStopSuccess] = useState(false); //state for succesfully remote stopping an active transaction alert
  const [remoteStopError, setRemoteStopError] = useState(false); //state for failed remote stopping of an active transaction alert
  const [remoteStopSuccessButRefreshActive, setRemoteStopSuccessButRefreshActive] = useState(false); //state for succesfully remote stopping an active transaction, but failed refreshing alert
  const [executionTimeExceeded, setExecutionTimeExceeded] = useState(false);
  const { t } = useTranslation();

  /**
   * Helper function for toggling the view to edit mode
   */
  const toggleEdit = () => {
    setEditMode(!editMode);
  };

  //The api address from where the code fetches the chargepoint datas. There area two different ones:
  //1) /api/admin/area for an admin wanting all the areas and chargepoints in the application
  //2) /api/area for an area manager wanting all the areas and chargepoint the area manager has access to
  const apiAddress = "/api/area";

  //Area and chargepoint ids from the url parameter
  const areaId = Number(useParams<{ id?: string }>().id);
  const cpId = useParams<{ charge_point_id?: string }>().charge_point_id;

  /* if (areaId === undefined || cpId === undefined) {
    return (
      <h2 className="align-self-center">Errors occurred when rendering page</h2>
    );
  } */

  /**
   * Helper function for running the useEffect again. The function initially sets the runUseEffectMode to the passed parameter,
   * since it determines if the showView state should be set to -1 in the beginning or if data should be fetched.
   * Reasoning can be found in the comments for the useEffect
   * @param {*} mode what mode to run the useEffect in
   */
  const runUseEffect = (mode: number) => {
    setRunUseEffectMode(mode);
    setRunUseEffectAgain((s) => !s);
  };

  /**
   * On each rerender, fetch all the areas, chargepoints, and active transactions for this chargepoints.
   *
   * All the areas are sorted in alphabetical order.
   *
   * If the user doesn't have access to the chargepoint, an error message will be displayed to the screen notifying the user
   * that they do not have access to the chargepoint.
   *
   * If the state runUseEffectMode is anything but 1, the hook will set the showView state to -1 (renders the 'Loading...' screen).
   * This is needed since at times when we fetch data from the backend, we don't want to change the view since it prevents
   * alerts to be shown. E.g. when the user updates the settings for the chargepoint, we want to fetch the updated data from the backend.
   * If we would render the 'Loading...' screen before the data has been fetched, the previously rendered alert would disappear.
   * Additionally, if the runUseEffectMode is -1 it wont fetch data (only set when deleting chargepoint).
   *
   * NOTE! At the moment, if an error occurs from the backend (e.g. the user doesn't have access, or an internal server
   * error), the function doesn't check what kind of error it is. I.e., if an internal server error occurs, the user
   * is told that they do not have access to the chargepoint. WILL BE CHANGED IN THE FUTURE!
   */
  useEffect(() => {
    if (runUseEffectMode !== 1) setShowView(-1);

    /**
     * Asynchronous helper function for the useEffect that fetches all the areas, chargepoints, and active transactions
     * from the backend, and updates the corresponding state with the data. The function also sorts all the areas
     * in alphabetical order.
     */
    const getData = async () => {
      if (!cpId) {
        setShowView(0);
        return;
      }

      //Get all the data using various service functions
      const areasRes = await getAreaBranches<AreaBase>(apiAddress);
      const chargepoint = await getSingleChargepoint(areaId, cpId);

      //Check if all the requests came back as successful
      if (areasRes.success && chargepoint.success) {
        //Store (and sort) the data
        const allAreas = areasRes.data
          .flatMap((a) => a)
          .sort((a: AreaSingle, b: AreaSingle) => (b.name!.toLowerCase() < a.name!.toLowerCase() ? 1 : -1));

        //Store and filter the area the chargepoint belongs to
        const areaData = allAreas.filter((area: AreaSingle) => area.id === areaId)[0];
        //Store and filter the chargepoint the user is currently trying to view
        const CPToSet = chargepoint.data;

        //Check if the chargepoints area id is the same as the area it belongs to. If not,
        //set the showView state to 0 notifying the user they do not have access to this chargepoint
        //and exit the function.
        if (areaData.id !== CPToSet.area_id) {
          setShowView(0);
          //return;
        }

        //Update all the states with the correct data
        setChargepoint(CPToSet);
        setArea(areaData);
        setAllAreas(allAreas);

        //Show the chargepoint view
        setShowView(1);
      } else {
        //Errors in areasRes?
        if (!areasRes.success) {
          logger(areasRes.data);
          //Display an error message notifying that the user does not have access to this area
          setShowView(0);
        }

        if (!chargepoint.success) {
          logger(chargepoint.data);
          setShowView(0);
        }
      }
    };
    getData();

    if (runUseEffectMode !== -1) getData();
    setRunUseEffectMode(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiAddress, areaId, cpId, runUseEffectAgain]);

  useEffect(() => {
    async function getData() {
      await refresh();
    }
    if (showView === 1) getData();
    //eslint-disable-next-line
  }, [showView]);

  /**
   * Asynchronous helper function for refreshing all the active transactions (refresh button).
   * The function calls the utility function refreshActiveTransactions which handles the
   * functionality for refreshing active transactions.
   */
  const refresh = async () => {
    //Call the utility function refresActiveTransactions.
    //We pass the string "chargepoint" as the first parameter to indicate that we're calling this
    //function for the Chargepoint component. Additionally, we pass the area id as the second parameter
    //and the chargepoint id as the third parameter
    await refreshTable(
      {
        areaId: areaId!,
        cpId: cpId!,
      },
      getAllActiveTransactionsForChargepoint,
      setActiveTransactions,
      setShowLoading,
      setShowCheckmark,
      (e) => e.response && e.response.data.errno === 3024,
      setExecutionTimeExceeded
    );
  };

  /**
   * Asynchronous helper function for refreshing the status table
   */
  const refreshStatusTable = async () => {
    if (!cpId) {
      setShowView(0);
      return;
    }

    setShowLoadingStatusTable(true);
    const areasRes = await getAreaBranches(apiAddress);
    const allChargepoints = await getSingleChargepoint(areaId, cpId);

    //Check if the requests came back as successful
    if (areasRes.success && allChargepoints.success) {
      //Store (and sort) the data
      const allAreas = areasRes.data
        .flatMap((a) => a)
        .sort((a: AreaBase, b: AreaBase) => (b.name!.toLowerCase() < a.name!.toLowerCase() ? 1 : -1));
      //Store and filter the area the chargepoint belongs to
      const areaData = allAreas.filter((area: AreaBase) => area.id === areaId)[0];
      //Store and filter the chargepoint the user is currently trying to view
      const CPToSet = allChargepoints.data;

      //Check if the chargepoints area id is the same as the area it belongs to. If not,
      //set the showView state to 0 notifying the user they do not have access to this chargepoint
      //and exit the function.
      if (areaData.id !== CPToSet.area_id) {
        setShowView(0);
        return;
      }

      //Update all the states with the correct data
      setChargepoint(CPToSet);
      setArea(areaData);
      setAllAreas(allAreas);

      setShowLoadingStatusTable(false);
      timer(setShowCheckmarkStatusTable, 2000);
    } else {
      setShowLoadingStatusTable(false);
      logger(areasRes[1]);
    }
  };

  /**
   * Asynchronous helper function used to call the forceStop utility function when the user wants
   * to force stop an active transaction.
   * @param {*} activeId the id of the active transaction
   */
  const callForceStop = async (activeId: number) => {
    forceStop(
      activeId,
      null,
      "chargepoint",
      setForceStopSuccess,
      setForceStopError,
      setForceStopSuccessButRefreshActive,
      setActiveTransactions,
      refresh,
      areaId,
      cpId
    );
  };

  /**
   * Asynchronous helper function used to call the remoteStop utility function when the user
   * wants to remote stop an active transaction.
   * @param {*} activeId the id of the active transaction
   */
  const callRemoteStop = async (activeId: number) => {
    await remoteStop(
      activeId,
      "chargepoint",
      setRemoteStopSuccess,
      setRemoteStopError,
      setRemoteStopSuccessButRefreshActive,
      setActiveTransactions,
      areaId,
      cpId
    );
  };

  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data (e.g. the user
   * does not have access to the area), an appropriate error message is displayed. Otherwise, the component
   * returns either the Chargepoint or EditChargepoint component depending on the state defined at the top
   * of this component.
   */
  return (
    <StyledChargepoint className="top-level-component">
      {showView === -1 ? ( //ShowView is -1 when the component is initialized, display "Loading...".
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? ( //If showView is 0, an error occured when retrieving the data from the backend.
        <h2 className="align-self-center">{t("global.view.noAccess")}</h2>
      ) : (
        <Container id="component-margin">
          {area && chargepoint ? (
            !editMode ? (
              <Chargepoint
                chargepoint={chargepoint}
                area={area}
                user={user}
                toggleEdit={toggleEdit}
                activeTransactions={activeTransactions}
                refresh={refresh}
                showCheckmark={showCheckmark}
                showLoading={showLoading}
                showCheckmarkStatusTable={showCheckmarkStatusTable}
                showLoadingStatusTable={showLoadingStatusTable}
                callForceStop={callForceStop}
                callRemoteStop={callRemoteStop}
                forceStopSuccess={forceStopSuccess}
                forceStopError={forceStopError}
                forceStopSuccessButRefreshActive={forceStopSuccessButRefreshActive}
                remoteStopSuccess={remoteStopSuccess}
                remoteStopError={remoteStopError}
                remoteStopSuccessButRefreshActive={remoteStopSuccessButRefreshActive}
                executionTimeExceeded={executionTimeExceeded}
                refreshStatusTable={refreshStatusTable}
                history={history}
              />
            ) : (
              <EditChargepoint
                toggleEdit={toggleEdit}
                chargepoint={chargepoint}
                area={area}
                allAreas={allAreas}
                history={history}
                runUseEffect={runUseEffect}
                user={user}
              />
            )
          ) : null}
        </Container>
      )}
    </StyledChargepoint>
  );
};

export default ChargepointView;
