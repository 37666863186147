import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AreaSingle, { AreaFull } from "../../../../model/Classes/Area";
import AreaMeter, { EmptyMeter } from "../../../../model/Classes/AreaMeter";
import { LogLevel, StateHandler } from "../../../../model/Utilities/Types";
import { timer } from "../../../../utils/timer";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import AreaMeterSettings from "./AreaMeterSettings";
import { AreaMLBSettings } from "./AreaMLBSettings";
import { AreaSettingsGeneral } from "./AreaSettingsGeneral";
import { tryMatchMeter } from "./SettingsUtils";
import { AddEditModal } from "./AddEditModal";
import { AreaDtoSend } from "../../../../model/DataTransferObjects/AreaDto";
import { logger } from "../../../../utils/logger";
import { Contract } from "../../../../model/Classes/Contract";
import User from "../../../../model/Classes/User";

/**
 * Component for the edit view where you can change the name, limit, and whether the area
 * has a meter or is a master load balancing area. If the area has a meter, allow the user to change
 * the backup limit as well. The component validates and checks for changes made when
 * the user clicks the save button, and shows a popup with all the changes and asks the user
 * to confirm the changes (if validation passes). If no changes were made, show an alert notifying
 * the user to make a change before submitting the form.
 * The component also disables the master load balance toggle buttons if the area has
 * 1) any direct parents that are master load balance areas
 * 2) any children that are master load balance areas
 * If the buttons are disabled, the component displays an alert telling the user what actions are needed
 * to be able to toggle master load balancing for this area.
 * @param {*} area the area that will have its changes made
 * @param {*} meter state for meter value
 * @param {*} setMeter state handler for meter value
 * @param {*} limit state for limit value
 * @param {*} handleLimit state handler for limit value
 * @param {*} backupLimit state for the backup limit
 * @param {*} setBackupLimit state handler for the backup limit
 * @param {*} masterLoadBalance state for master load balance
 * @param {*} setMasterLoadBalance state handler for master load balance
 * @param {*} newName state for name
 * @param {*} handleNameChange state handler for name
 * @param {*} handleSubmitSettings helper function for sending the data to the backend.
 * @param {*} canBeMasterLoadBalance used to check if the area can toggle master load balancing on
 * @param {*} childrenWithMasterLoadBalance array with all the children that are master load balancing areas
 * @param {*} parentsWithMasterLoadBalance array with all the parents that are master load balancing areas
 * @returns the edit view
 */
declare interface EditSettingsProps {
  area: AreaFull;
  allMeters: AreaMeter[];
  meter: string;
  setMeter: StateHandler<any>;
  limit: string | number;
  handleLimit: (event: any) => void;
  backupLimit: number;
  setBackupLimit: StateHandler<number>;
  masterLoadBalance: string;
  setMasterLoadBalance: StateHandler<string>;
  newName: string;
  handleNameChange: (event: any) => void;
  handleSubmitSettings: (
    area_changes: AreaSingle,
    meter_changes: AreaMeter,
    previousMeter: AreaMeter
  ) => Promise<boolean>;
  canBeMasterLoadBalance: boolean;
  childrenWithMasterLoadBalance: AreaSingle[];
  parentsWithMasterLoadBalance: AreaSingle[];
  toggleEdit: () => void;
  parent: AreaFull;
  history: any;
  contract?: Contract;
  isReporting: number;
  setIsReporting: StateHandler<number>;
  user: User;
  allAreas: AreaSingle[];
  pricingType: number;
  setPricingType: StateHandler<number>;
  spotPriceMargin: string | number;
  setSpotPriceMargin: StateHandler<string | number>;
  userBiddingValue: string;
  setUserBiddingValue: StateHandler<string>;
}
const EditSettings = ({
  area,
  allMeters,
  meter,
  setMeter,
  limit,
  handleLimit,
  backupLimit,
  setBackupLimit,
  masterLoadBalance,
  setMasterLoadBalance,
  newName,
  handleNameChange,
  handleSubmitSettings,
  canBeMasterLoadBalance,
  childrenWithMasterLoadBalance,
  parentsWithMasterLoadBalance,
  parent,
  toggleEdit,
  history,
  contract,
  isReporting,
  setIsReporting,
  user,
  allAreas,
  pricingType,
  setPricingType,
  userBiddingValue,
  setUserBiddingValue,
}: EditSettingsProps) => {
  const [showNameAlert, setShowNameAlert] = useState(false); //state for showing an alert notifying the user that the input name is invalid
  const [showLimitAlert, setShowLimitAlert] = useState(false); //state for showing an alert notifying the user that the input limit is invalid
  const [showMeterAlert, setShowMeterAlert] = useState(false); //state for showing an alert notifying the user that the input meter is invalid
  const [showBackupLimitAlert, setShowBackupLimitAlert] = useState(false); //state for showing an alert notifying the user that the input backup limit is invalid
  const [showMasterLoadBalancingAlert, setShowMasterLoadBalancingAlert] = useState(false); //state for showing an alert notifying the user that the input master load balancing is invalid
  const [showLoadAndLimitAlert, setShowLoadAndLimitAlert] = useState(false); //state for showing an alert notifying the user to specify a limit if the area is a master load balancing area
  const [showNoChangeAlert, setShowNoChangeAlert] = useState(false); //state for showing an alert notifying the user to make a change before submiting
  const [changes, setChanges] = useState<string[]>([]); //state containing the changes that will be made
  const [showChange, setShowChange] = useState(false); //state for showing the popup
  const [showSuccess, setShowSuccess] = useState(false); //state for showing a success alert when changes were successfully made
  const [showError, setShowError] = useState(false); //state for showing an error alert when changes failed
  const [disabledFields, setDisabledFields] = useState(false); //state for disabling the input fields and buttons
  const [WDLMID, setWDLMID] = useState<number>(0);
  const [slaveID, setSlaveID] = useState<number>(0);
  const [WDLMIDAlert, setShowWDLMIDAlert] = useState(false);
  const [slaveIDAlert, setSlaveIDAlert] = useState(false);
  const [meterAlert, setMeterAlert] = useState(false);
  const [previousMeter, setPreviousMeter] = useState<AreaMeter>(() => {
    return {
      ...EmptyMeter,
      area_id: area.id!,
    };
  });
  const [areaChargingType, setAreaChargingType] = useState<{ name: string; value: number }[]>([]);

  const [accessType, setAccessType] = useState(area.access_type!);
  const [publicPrice, setPublicPrice] = useState(
    `${area.default_public_price ? area.default_public_price.toFixed(2) : "0.00"}`
  );
  const [publicStartPrice, setPublicStartPrice] = useState(
    `${area.default_public_start_price ? area.default_public_start_price.toFixed(2) : "0.00"}`
  );
  const [privatePrice, setPrivatePrice] = useState(`${area.default_price ? area.default_price.toFixed(2) : "0.00"}`);
  const [spotPriceMargin, setSpotPriceMargin] = useState(area.margin!);

  const [publicPriceAlert, setPublicPriceAlert] = useState(false);
  const [changeAllSubAreas, setChangeAllSubareas] = useState(true);
  const [invoicing, setInvoicing] = useState(contract!.invoicing_method!);
  //function to format the margin value when ',' is used in the input
  const formatMarginValue = (margin: number | string): number => {
    return typeof margin === "string" ? parseFloat(margin.replace(",", ".")) : margin;
  };
  const fromattedSpotPriceMargin = formatMarginValue(spotPriceMargin); //formats the spot price margin into  decimal when ',' is used

  const { t } = useTranslation();
  /**
   * Helper function for validating the form fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the pass variable to false and toggles the respective alert on. This is done instead of instantly returning
   * false, because then all the criteria are checked instead of the function stopping once the first violation occured.
   * @returns true if validation passes, false otherwise
   */
  const validateArea = () => {
    let success = true; //Return value
    resetAlerts();
    //Check the name
    if (newName.length < 1 || newName.length > 256) {
      setShowNameAlert(true);
      success = false;
    }
    //Check the limit
    if (isNaN(Number(limit)) || Number(limit) < 0) {
      setShowLimitAlert(true);
      success = false;
    }
    //Check if the area has a meter or not
    if (Number(meter) !== 1 && Number(meter) !== 0) {
      setShowMeterAlert(true);
      success = false;
    }

    //Check the master load balancing
    if (Number(masterLoadBalance) !== 1 && Number(masterLoadBalance) !== 0) {
      setShowMasterLoadBalancingAlert(true);
      success = false;
    }
    //Check if the limit was not specified when master load balancing was toggled on.
    if (Number(limit) === 0 && Number(masterLoadBalance) === 1) {
      setShowLoadAndLimitAlert(true);
      success = false;
    }

    //Check accessType, if public or private check pricing is greater than zero
    if (!accessType) success = false;
    else if (![0, 3].includes(accessType)) {
      const prpNumber = Number(privatePrice);
      if (prpNumber < 0 || prpNumber > 10) {
        setPublicPriceAlert(true);
        success = false;
      }
    } else if (accessType >= 2) {
      const ppNumber = Number(publicPrice);
      const pspNumber = Number(publicStartPrice);
      if (ppNumber < 0 || ppNumber > 10 || pspNumber < 0 || pspNumber > 10) {
        setPublicPriceAlert(true);
        success = false;
      }
    }
    return success;
  };

  const validateMeter = () => {
    let success = true;
    if (Number(meter) === 1) {
      //If the area has a meter, check the backup limit
      if (backupLimit < 10) {
        setShowBackupLimitAlert(true);
        success = false;
      }

      const theMeters = tryMatchMeter(allMeters, WDLMID, false);
      //Check if the meter with public_id exists and whether or not it has an appropriate status
      if (theMeters.length > 0) {
        //just find any meter-slave representation and check its status
        if (theMeters[0].status > 4) {
          success = false;
          setShowWDLMIDAlert(true);
        }

        //Check is the meter-slave combination exists and is mapped to another area
        for (const meter of theMeters) {
          if (meter.public_id === WDLMID && meter.slave_id === slaveID && meter.area_id !== area.id) {
            success = false;
            setMeterAlert(true);
            break;
          }
        }

        if (slaveID < 1 || slaveID > 255 || slaveID === undefined) {
          success = false;
          setSlaveIDAlert(true);
        }
      } else {
        success = false;
        setShowWDLMIDAlert(true);
      }
    }
    return success;
  };

  //The two following functions aren't particularily elegant but they do what is required
  const area_changes = (): AreaDtoSend => {
    const newLimit = limit === null || limit === "" ? null : Number(limit) === 0 ? 0 : Number(limit); //checks the conditon of the limit to be changed accordingly.

    const data: AreaDtoSend = {
      name: newName,
      a_limit: newLimit,
      has_meter: Number(meter),
      meter_fail_limit: Number(meter) === 0 ? null : backupLimit, //Set the meter_fail_limit to null if the area doesn't have an area meter
      master_load_balancing: Number(masterLoadBalance),
      default_price: Number(privatePrice),
      default_public_price: Number(publicPrice),
      default_public_start_price: Number(publicStartPrice),
      access_type: accessType,
      change_all_default_prices: changeAllSubAreas,
      change_all_cp_prices: changeAllSubAreas,
      reporting_site: isReporting,
      bidding_area: userBiddingValue,
      margin: fromattedSpotPriceMargin,
      pricing_type: Number(pricingType),
    };

    return data;
  };
  const meter_changes = (): AreaMeter => {
    if (Number(meter) === 0) {
      const data: AreaMeter = {
        public_id: previousMeter.public_id,
        id: previousMeter.id,
        area_id: null,
        status: 2,
        slave_id: previousMeter.slave_id,
      };
      return data;
    }
    const themeter = tryMatchMeter(allMeters, WDLMID, false)[0];
    const data: AreaMeter = {
      public_id: WDLMID,
      area_id: area.id!,
      id: themeter.id,
      status: 3,
      slave_id: slaveID,
    };

    return data;
  };

  const updateCorrespondingFields = () => {
    setWDLMID(0);
    setSlaveID(0);
    setBackupLimit(0);
    setChanges([]);
  };

  const changesGeneral = (ac: AreaDtoSend, areaAccessType: { name: string; value: number }[]): string[] => {
    const changesArray: string[] = [];
    if (ac.name !== area.name) {
      // If name has changed
      changesArray.push(
        t("components.area.edit.changes.name", {
          currentName: area.name,
          newName: ac.name,
        })
      );
    }

    if (ac.a_limit !== area.a_limit) {
      //If limit has changed
      const limitShow = area.a_limit === null ? "None" : area.a_limit + " A";
      const dataLimitShow = ac.a_limit === null ? t("components.area.edit.changes.noLimit") : ac.a_limit + " A";
      changesArray.push(
        t("components.area.edit.changes.limit", {
          prevLimit: limitShow,
          newLimit: dataLimitShow,
        })
      );
    }

    if (ac.reporting_site !== area.reporting_site) {
      ac.reporting_site
        ? changesArray.push(t("components.area.edit.changes.isReporting.reporting"))
        : changesArray.push(t("components.area.edit.changes.isReporting.notReporting"));
    }

    if (ac.master_load_balancing !== area.master_load_balancing) {
      ac.master_load_balancing
        ? changesArray.push(t("components.area.edit.changes.mlb.has"))
        : changesArray.push(t("components.area.edit.changes.mlb.notHas"));
    }
    if (ac.meter_fail_limit !== area.meter_fail_limit) {
      !ac.meter_fail_limit
        ? changesArray.push(t("components.area.edit.changes.backupLimit.notHas"))
        : changesArray.push(
            t("components.area.edit.changes.backupLimit.has", {
              newLimit: ac.meter_fail_limit + " A",
              oldLimit: area.meter_fail_limit
                ? area.meter_fail_limit + " A"
                : t("components.area.edit.changes.backupLimit.no"),
            })
          );
    }
    if (ac.access_type !== area.access_type)
      changesArray.push(
        `${t("components.area.static.singleArea.header.areaType.type")}: ${areaAccessType[accessType!].name}`
      );
    if (ac.margin !== area.margin) {
      //spotPrice margin has changed
      changesArray.push(
        t("components.area.edit.changes.marginChange", {
          newMargin: ac.margin,
          oldMargin: area.margin,
        })
      );
    }
    if (ac.bidding_area !== area!.bidding_area) {
      //bidding area  has changed
      changesArray.push(
        t("components.area.edit.changes.biddingChange", {
          newBidArea: ac.bidding_area,
          oldBidArea: area.bidding_area,
        })
      );
    }
    if (ac.pricing_type !== area.pricing_type) {
      ac.pricing_type === 1
        ? changesArray.push(t("components.area.edit.changes.isSpotPrice"))
        : changesArray.push(t("components.area.edit.changes.isNotSpotPrice"));
    }

    return changesArray;
  };

  const changesMeter = (ac: AreaDtoSend, mc: AreaMeter): string[] => {
    const changesArray: string[] = [];
    if (ac.has_meter !== area.has_meter) {
      if (ac.has_meter === 1) {
        changesArray.push(t("components.area.edit.changes.meterPrev"));
        changesArray.push(t("components.area.edit.changes.meterNew", { DLM: WDLMID }));
        if (previousMeter.public_id > 0 && mc.slave_id !== previousMeter.slave_id) {
          //If it had a meter previously, but the slave ID has changed
          changesArray.push(
            t("components.area.edit.changes.meterChangeSlave", {
              newSlave: mc.slave_id,
              oldSlave: previousMeter.slave_id,
            })
          );
        } else {
          //If it didn't have a meter previously
          changesArray.push(
            t("components.area.edit.changes.meterNewSlave", {
              newSlave: mc.slave_id,
            })
          );
        }
      } else {
        changesArray.push(t("components.area.edit.changes.noMeter"));
      }
    } else {
      if (ac.has_meter === 1) {
        //If it had a meter previously
        //If a WDLM-ID has been set
        if (previousMeter.public_id !== mc.public_id && previousMeter.public_id !== 0) {
          changesArray.push(
            t("components.area.edit.changes.meterChange", {
              newDLM: WDLMID,
              oldDLM: previousMeter.public_id,
            })
          );
          changesArray.push(
            t("components.area.edit.changes.meterNewSlave", {
              newSlave: mc.slave_id,
            })
          );
        } else if (previousMeter.public_id === 0 && mc.public_id !== 0) {
          changesArray.push(
            t("components.area.edit.changes.meterNew", {
              DLM: WDLMID,
            })
          );
        }
      }

      if (previousMeter.public_id > 0 && mc.slave_id !== previousMeter.slave_id) {
        changesArray.push(
          t("components.area.edit.changes.meterChangeSlave", {
            newSlave: mc.slave_id,
            oldSlave: previousMeter.slave_id,
          })
        );
      } else if (
        previousMeter.public_id === 0 &&
        (mc.area_id !== previousMeter.area_id || mc.public_id !== previousMeter.public_id) &&
        ac.has_meter === 1
      ) {
        changesArray.push(
          t("components.area.edit.changes.meterNewSlave", {
            newSlave: mc.slave_id,
          })
        );
      }
    }

    return changesArray;
  };

  const changesPricing = (ac: AreaDtoSend): string[] => {
    const changesArray: string[] = [];

    /**  if (changeAllSubAreas) {
      changesArray.push(t('components.area.edit.changes.prices.subareas'));
      changesArray.push(t('components.area.edit.changes.prices.chargepoints'));
    }*/
    if (invoicing !== undefined) {
      if (ac.default_price !== Number(area.default_price)) {
        changesArray.push(
          `${t("components.chargepoint.static.tabs.general.info.table.price")}: ${privatePrice} ${area.currency}`
        );
      }
      if (ac.default_public_price !== area.default_public_price)
        changesArray.push(
          `${t("components.area.static.singleArea.header.publicPrice")}: ${publicPrice} ${area.currency}`
        );
      if (ac.default_public_start_price !== area.default_public_start_price)
        changesArray.push(
          `${t("components.area.static.singleArea.header.publicStart")}: ${publicStartPrice} ${area.currency}`
        );
      /**  if (ac.bidding_area !== area.bidding_area)
        changesArray.push(
          `${t(
            'components.area.static.singleArea.header.spotPrice.biddingValue'
          )}: ${userBiddingValue}`
        );
         if (ac.margin !== area.margin)
        changesArray.push(
          `${t(
            'components.area.static.singleArea.header.spotPrice.margin'
          )}: ${spotPriceMargin} `
        );
      if (ac.pricing_type !== area.pricing_type)
        changesArray.push(
          `${t(
            'components.area.static.singleArea.header.spotPrice.spotPriceVal'
          )}: ${pricingType} `
        ); */
    } else {
      logger(`invoicing is undefined`, LogLevel.WARNING);
    }

    return changesArray;
  };

  /**
   * Helper function for viewing the confirmation modal when making a change.
   * Either toggles an alert below the save button alerting the user to make a change before
   * saving/setting a limit when master load balancing is toggled on (does not render the confirmation modal),
   * or shows the confirmation modal.
   */
  const showChangeModal = () => {
    toggleAlertsOff([
      setShowNameAlert,
      setShowLimitAlert,
      setShowMeterAlert,
      setShowBackupLimitAlert,
      setShowMasterLoadBalancingAlert,
      setShowLoadAndLimitAlert,
      setShowNoChangeAlert,
      setMeterAlert,
      setPublicPriceAlert,
    ]);

    if (!(validateArea() && validateMeter())) return;
    const changesArray: string[] = [];

    //Check for all the changes made to the area. If a change is noticed, push the corresponding
    //change to the changesArray

    //this is a mess

    const areaAccessType = [
      {
        name: t("components.area.static.singleArea.header.areaType.free"),
        value: 0,
      },
      {
        name: t("components.area.static.singleArea.header.areaType.private"),
        value: 1,
      },
      {
        name: t("components.area.static.singleArea.header.areaType.privPub"),
        value: 2,
      },
      {
        name: t("components.area.static.singleArea.header.areaType.public"),
        value: 3,
      },
    ];

    /**Changes made directly to the area in the addArea form */
    const ac = area_changes();
    /**Changes made directly to the areaMeter in the addArea form */
    const mc = meter_changes();

    changesArray.push(...changesGeneral(ac, areaAccessType), ...changesMeter(ac, mc), ...changesPricing(ac));

    //If there were no changes made, show an alert.
    if (changesArray.length === 0) {
      setShowNoChangeAlert(true);
    } else {
      //Changes were made, show the confirmation modal with the changes that will be made.
      setChanges(changesArray);
      setShowChange(true);
    }
  };

  /**
   * Helper function for calling the function handleSubmitChanges(). The function sends the data
   * to the backend, and shows a success alert if it was successful, or an error alert if unsuccessfull.
   */
  //THIS IS ALSO A MESS
  const makeChanges = async () => {
    setDisabledFields(true);
    setShowChange(false);

    //No need to evaluate more than once
    //Technically they are already evaluated in the showChanges function
    const [ac, mc] = [area_changes(), meter_changes()];
    if (await handleSubmitSettings(ac, mc, previousMeter)) {
      setPreviousMeter(mc);
      timer(setShowSuccess);
    } else timer(setShowError);
    if (Number(meter) === 0) updateCorrespondingFields();
    setDisabledFields(false);
  };

  const resetAlerts = () => {
    toggleAlertsOff([
      setShowNameAlert,
      setShowLimitAlert,
      setShowMeterAlert,
      setShowBackupLimitAlert,
      setShowMasterLoadBalancingAlert,
      setShowLoadAndLimitAlert,
      setShowNoChangeAlert,
      setMeterAlert,
      setPublicPriceAlert,
    ]);
  };

  useEffect(() => {
    const getData = () => {
      //if the area has a meter, find it using the area.id

      const meter = tryMatchMeter(allMeters, area.id, true)[0];
      if (meter) {
        setPreviousMeter(meter);
        //set the public_id and slave_id to the meters public_id and slave_id respectively
        setWDLMID(meter.public_id);
        //If the user hasn't changed the slave_id, keep it as is
        //Otherwise, keep the value of the user input
        if (Number(slaveID) === 0 && meter.slave_id !== null) setSlaveID(meter.slave_id);
      }

      //eslint-disable-next-line
    };
    getData();
    //Only do this once
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    const validBackup = () => {
      return backupLimit > 10;
    };
    if (!validBackup()) setBackupLimit(backupLimit);
  }, [setBackupLimit, backupLimit]);

  //clean-up
  useEffect(() => {
    return () => {
      toggleAlertsOff([
        setShowNameAlert,
        setShowLimitAlert,
        setShowMeterAlert,
        setShowBackupLimitAlert,
        setShowMasterLoadBalancingAlert,
        setShowLoadAndLimitAlert,
        setShowNoChangeAlert,
        setMeterAlert,
        setPublicPriceAlert,
        setShowError,
        setShowSuccess,
      ]);
    };
  }, []);

  /**
   * Return a form containing fields and buttons for the various settings, and possible alerts
   * notifying the user of illegal (or successful) actions. Additionally, render the popup whenever
   * the save button is pressed.
   */
  return (
    <>
      {showSuccess && (
        <Alert key="success" variant="success">
          {t("global.alert.success.areaChanges")}
        </Alert>
      )}
      {showError && (
        <Alert key="error" variant="danger">
          {t("global.alert.failure.areaChanges")}
        </Alert>
      )}
      {showNoChangeAlert ? (
        <Alert className="mt-3" key="noChange" variant="warning">
          {t("global.alert.failure.requireChange")}
        </Alert>
      ) : null}
      {showLoadAndLimitAlert ? (
        <Alert key="showLoadAndLimitAlert" variant="danger">
          {t("global.alert.failure.mlbLimit")}
        </Alert>
      ) : null}
      {/*Show an alert if the user tries to toggle their root area to be a master load balancing area*/}
      {area.user_root ? (
        <Alert key="user_rootInfo" variant="info">
          {t("global.alert.failure.mlbRoot")}
        </Alert>
      ) : null}
      <AreaSettingsGeneral
        newName={newName}
        disabledFields={disabledFields}
        handleNameChange={handleNameChange}
        //limit={limit}
        handleLimit={handleLimit}
        showLimitAlert={showLimitAlert}
        parent={parent}
        setAreaChargingType={setAreaChargingType}
        areaChargingType={areaChargingType}
        accessType={accessType}
        setAccessType={setAccessType}
        privatePrice={privatePrice}
        setPrivatePrice={setPrivatePrice}
        publicPrice={publicPrice}
        setPublicPrice={setPublicPrice}
        publicStartPrice={publicStartPrice}
        setPublicStartPrice={setPublicStartPrice}
        publicPriceAlert={publicPriceAlert}
        changeAllSubareas={changeAllSubAreas}
        setChangeAllSubareas={setChangeAllSubareas}
        contract={contract}
        invoicing={invoicing}
        setInvoicing={setInvoicing}
        isReporting={isReporting}
        setIsReporting={setIsReporting}
        user={user}
        //masterLoadBalance={masterLoadBalance}
        area={area}
        allAreas={allAreas}
        setPricingType={setPricingType}
        pricingType={pricingType}
        spotPriceMargin={spotPriceMargin}
        setSpotPriceMargin={setSpotPriceMargin}
        userBiddingValue={userBiddingValue}
        setUserBiddingValue={setUserBiddingValue}
        //editArea={true}
      />

      {/*Display the master load balancing toggle buttons*/}
      <AreaMLBSettings
        canBeMasterLoadBalance={canBeMasterLoadBalance}
        disabledFields={disabledFields}
        masterLoadBalance={masterLoadBalance}
        setMasterLoadBalance={setMasterLoadBalance}
        showMasterLoadBalancingAlert={showMasterLoadBalancingAlert}
        childrenWithMasterLoadBalance={childrenWithMasterLoadBalance}
        parentsWithMasterLoadBalance={parentsWithMasterLoadBalance}
        editArea={true}
        limit={limit}
        handleLimit={handleLimit}
        area={area}
        allAreas={allAreas}
        addArea={true}
        parent={parent}
      />
      <AreaMeterSettings
        has_meter={meter}
        backupLimit={backupLimit}
        setBackupLimit={setBackupLimit}
        disabledFields={disabledFields}
        showBackupLimitAlert={showBackupLimitAlert}
        WDLMID={WDLMID}
        setWDLMID={setWDLMID}
        slaveID={slaveID}
        setSlaveID={setSlaveID}
        WDLMIDAlert={WDLMIDAlert}
        slaveIDAlert={slaveIDAlert}
        setMeter={setMeter}
        showMeterAlert={showMeterAlert}
        editArea={true}
        meterAlert={meterAlert}
      />
      {showNameAlert ? (
        <Alert key="invalidName" variant="danger">
          {t("global.alert.failure.invalidName")}
        </Alert>
      ) : null}
      <br />
      <AddEditModal
        showChangeModal={showChangeModal}
        disabledFields={disabledFields}
        showChange={showChange}
        setShowChange={setShowChange}
        changes={changes}
        makeChanges={makeChanges}
      />
    </>
  );
};

export default EditSettings;
