import { useCallback, useEffect, useState } from "react";
import { Button, Container, Row, Alert } from "react-bootstrap";
import { StyledArea } from "./Area.styled";
import AddArea from "./Settings/AddArea";
import { getAreaBranches } from "../../../services/areaService";
import { timer } from "../../../utils/timer";
import { logger } from "../../../utils/logger";
import User from "../../../model/Classes/User";
import AreaSingle, { AreaBase, AreaInTree } from "../../../model/Classes/Area";
import Tree from "./Tree";
import { useTranslation } from "react-i18next";
import { getUserRootAreas } from "../../../services/areaService";
import { configureObservableAreas } from "../../../model/Utilities/Extensions";

declare interface AreaViewProps {
  user: User;
  history: any;
}
/**
 * Component for viewing the area page. Gets the areas from the backend and forwards
 * the data to the area tree (accordion)
 * @param {*} user the user state
 * @param {*} history history object
 * @returns area view
 */
const AreaView = ({ user, history }: AreaViewProps) => {
  const [areas, setAreas] = useState<Map<number, AreaSingle[]>>(() => new Map<number, AreaSingle[]>()); //state for all the areas
  const [addMode, setAddMode] = useState(false); //state for displaying the "add area" mode
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or area tree screen
  const [areaDeleted, setAreaDeleted] = useState(false); //state for the area deleted alert
  const [areaAdded, setAreaAdded] = useState(false); //state for the area added alert
  //const [errorMessage, setErrorMessage] = useState(''); //state containing possible error message
  const [runUseEffectAgain, setRunUseEffectAgain] = useState(false); //state for running the useEffect again
  const [userRoot, setUserRoot] = useState<{ area_id: number; primary: number }[]>([]);
  const { t } = useTranslation();
  const [waitUntil, setWaitUntil] = useState(false);
  const [isReporting, setIsReporting] = useState(0);
  const [pricingType, setPricingType] = useState(0);

  /**
   * Helper function for toggling the view for adding an area. If the parameter added is present,
   * set an alert that notifies the user that adding a chargepoint was successful
   * @param {*} added boolean flag indicating if adding an area was successful
   */
  const toggleAdd = (added: boolean) => {
    if (added) {
      timer(setAreaAdded, 6000);
      window.history.replaceState({}, document.title);
    }
    setAddMode(!addMode);
  };

  //The api address from where the code fetched area data
  const apiAddress = user.user_level === 2 ? "/api/admin/area" : "/api/area";

  /**
   * Helper function for running the useEffect again. NOTE! The parameter mode is unused,
   * but it needs to be there since it's use in addArea always passes a parameter to
   * the function. DO NOT REMOVE!
   * @param {*} mode unused parameter
   */
  const runUseEffect = (mode: number) => {
    setRunUseEffectAgain((s) => !s);
  };

  /**
   * Asynchronous helper function for fetching all the areas data from the backend,
   * and updates the corresponding state with the data. The function also sorts all the areas in
   * alphabetical order.
   */
  const getData = useCallback(async () => {
    //uhhhhhhhhhhhhhh
    setWaitUntil(false);
    //Get the areas data
    const res = await getAreaBranches<AreaBase>(apiAddress);
    const userRes = await getUserRootAreas();
    //Check if the request was successful
    if (res.success && userRes.success) {
      //As an admin I dont necessarily want to bother with multiple branches and a synthetic root
      const userRoots = user.user_level === 2 ? [{ area_id: 0, primary: 1 }] : userRes.data;

      //Sort all the areas
      //When using multiple manager mapping the sorting became quite
      //verbose so i extracted it into utility functions in model/utilities/extensions.ts;
      //the response is either a 1D or a 2D-array
      const sortedAreas = configureObservableAreas(res.data, userRoots);

      const treeBranches = userRoots.filter((root) => !!root.primary || sortedAreas.has(root.area_id));

      setUserRoot(treeBranches);

      //Update the areas state
      setAreas(sortedAreas);

      //Show the area view
      setShowView(1);
    } else {
      logger(res.data);
      logger(userRes.data);
      //Display an error message notifying that the user does not have access to this area
      setShowView(0);
      return false;
    }
    return true;
  }, [apiAddress, user.user_level]);

  /**
   * On each rerender, fetch all the areas the user has access to. All the areas are sorted in alphabetical order.
   * If fetching the data was successful, the areas and showView states are updated.
   */
  useEffect(() => {
    const resolve = async () => {
      setWaitUntil(await getData());
    };
    resolve();
  }, [getData, runUseEffectAgain]);

  /**
   * On each rerender, check if the user was redirected to this page with a state. If a redirect with a state
   * occured, check which state is active, and set the corresponding success alert on and remove the state.
   * The state is removed instantly after the alert is shown since the page should not render the success alert
   * when e.g. refreshing or accessing the page again.
   */
  useEffect(() => {
    //Check if a state exists
    if (history.location.state) {
      //Check which state exists.
      //window.history.replaceState was used instead of pushing the history stack to this page due to
      //the fact that pushing the history stack to this page causes a rerender to occur. This would
      //cause the success alert to be visible again when e.g. refreshing the page.
      //window.history.replaceState does not cause a rerender.

      if (history.location.state.areaDeleted) {
        //Sets the successful alert for deleting an area.
        timer(setAreaDeleted);
        window.history.replaceState({}, document.title);
        setRunUseEffectAgain((r) => !r);
      }
    }
  }, [history]);
  const handleLimit = () => {};
  /**
   * Initially, the component shows a loading screen. If an error occured when fetching data (e.g. the user does
   * not have access to the page), an appropriate error message is displayed. Otherwise, the component returns
   * either a header + possible alerts + add area button + area tree OR the AddArea component.
   */
  return (
    <StyledArea className="top-level-component">
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : showView === 0 ? (
        <h2 className="align-self-center">{t("components.area.response.noManage")}</h2>
      ) : !addMode ? (
        <Container id="component-margin">
          <Row xs={1} sm={1}>
            <h2>{t("components.area.static.area.header")}</h2>
          </Row>
          <Row xs={1} sm={1}>
            {areaDeleted ? (
              <Alert key="deletedArea" variant="success">
                {t("global.alert.success.areaDelete")}
              </Alert>
            ) : (
              <></>
            )}
            {areaAdded && (
              <Alert key="addedArea" variant="success">
                {t("global.alert.success.areaAdd")}
              </Alert>
            )}
          </Row>
          {/** Button for the add area in the tree when you first go to the area managemnet componenet  */}
          <Row xs={1} sm={1} className="button-div">
            <Button
              onClick={() => {
                toggleAdd(addMode);
              }}
              variant="primary"
              id="addAreaButton"
              style={{ border: "1px solid red" }}
              hidden={true} // hidden as of now but the functionality are still  there, just not displayed
            >
              {t("global.buttons.add.area")}
            </Button>
          </Row>
          <Row xs={1} sm={1}>
            {/*Disgusting band-aid for updating after an area has been added */}
            {waitUntil && (
              <>
                {userRoot.map((root) => {
                  return (
                    <Tree
                      key={root.area_id}
                      nodes={areas.get(root.area_id) as AreaInTree[]}
                      userRoot={root.area_id}
                      history={history}
                    />
                  );
                })}
              </>
            )}
          </Row>
        </Container>
      ) : (
        <AddArea
          toggleAdd={toggleAdd}
          areas={[...areas.values()].flatMap((a) => a)}
          runUseEffect={runUseEffect}
          userRoot={userRoot.map((a) => a.area_id)}
          history={history}
          isReporting={isReporting}
          setIsReporting={setIsReporting}
          user={user}
          handleLimit={handleLimit}
          setPricingType={setPricingType}
          pricingType={pricingType}
        />
      )}
    </StyledArea>
  );
};

export default AreaView;
