import { Contract } from "../model/Classes/Contract";
// import { Contract } from "@shared/types/Contract";
import { ChargePoint } from "@shared/types/ChargePoint";
import { AreaBase } from "../model/Classes/Area";
import { User } from "@shared/types/User";
import { UserInvoice } from "@shared/types/UserInvoice";
import { UserInvoiceItem } from "@shared/types/UserInvoiceItem";
import { SearchUserInfo } from "@shared/types/Search";
import { CompletedTransaction } from "@shared/types/CompletedTransaction";
import { SearchResponse } from "@shared/types/Search";
import { tokenRenewingGet, tokenRenewingPost } from "./lib/tokenRenewingRequest";

// Contract Management
export const getContract = async (contract_id: number, url?: string) =>
  await tokenRenewingGet<[Contract, AreaBase[]]>(`${url ? url : "/api/reporting/contract"}/single/${contract_id}`);

export const addContract = async (contract: Contract) =>
  await tokenRenewingPost<any>("/api/admin/contract/add", { contract });

export const getAreaContract = async (area_id: number) =>
  await tokenRenewingGet<Contract>(`/api/reporting/contract/area/${area_id}`);

export const getContracts = async () => await tokenRenewingGet<Contract[]>("/api/admin/contract/all");

export const getAvailableContracts = async () =>
  await tokenRenewingGet<Contract[]>("/api/reporting/contract/available");

export const updateContract = async (apiAddress: string, contract: Contract) =>
  await tokenRenewingPost<any>(`${apiAddress}/update/${contract.contract_number}`, contract);

export const getContractPricingOptions = async () =>
  await tokenRenewingGet<[]>("/api/admin/contract/pricingOptions");

// Manager Management
export const getPrimaryManager = async (contract_number: number) =>
  await tokenRenewingGet<{ primary_manager: string }>(`/api/admin/contract/${contract_number}/primary`);

export const updatePrimaryManager = async (contract_number: number, email: string) =>
  await tokenRenewingPost<{ status: number; message: string }>(`/api/admin/contract/primary/${contract_number}`, {
    email,
  });

export const setAreaManager = async (data: { email: string; area_id: number }) =>
  await tokenRenewingPost<{ status: number; message: string }>("/api/admin/makeAreaManager", data);

// Search Management
export const search = async (searchInput: string) =>
  await tokenRenewingGet<SearchResponse>(`/api/v2/admin/search/${searchInput}`);

// Accordion Management
export const getAreaByContractId = async (contractId: number) =>
  await tokenRenewingGet<Contract>(`/api/v2/admin/areas/${contractId}`);

export const getChargePointsByAreaId = async (id: number) =>
  await tokenRenewingGet<ChargePoint>(`/api/v2/admin/chargepoints/${id}`);

// User Management
export const searchUser = async (searchInput: string) =>
  await tokenRenewingGet<User>(`/api/v2/admin/search/user/${searchInput}`);

export const getUserInfoById = async (userId: number) =>
  await tokenRenewingGet<SearchUserInfo>(`/api/v2/admin/search/userInfo/${userId}`);

export const getCompletedTransactionsByUserId = async (userId: number, offset?: number) =>
  await tokenRenewingGet<CompletedTransaction[]>(`/api/v2/admin/completedTransactions/userId/${userId}/${offset ? offset : ""}`);

export const getCompletedTransactionsByUserInvoiceId = async (userInvoiceId: number) =>
  await tokenRenewingGet<CompletedTransaction[]>(`/api/v2/admin/completedTransactions/userInvoiceId/${userInvoiceId}`);

export const getInvoicesByUserId = async (userId: number) =>
  await tokenRenewingGet<UserInvoice[]>(`/api/v2/admin/userInvoices/userId/${userId}`);

export const getInvoiceItemsByUserInvoiceId = async (userInvoiceId: number) =>
  await tokenRenewingGet<UserInvoiceItem[]>(`/api/v2/admin/userInvoiceItems/userInvoiceId/${userInvoiceId}`);