import { useState } from "react";
import { Button, Tab, Row, Col, Tabs } from "react-bootstrap";
import { sendChargepointChanges } from "../../../../services/chargepointService";
import { sendChargepointMove } from "../../../../services/chargepointService";
import { sendChargepointDelete } from "../../../../services/chargepointService";
import EditSettingsCp from "./EditSettingsCp";
import EditDeleteCp from "./EditDeleteCp";
import EditMoveCp from "./EditMoveCp";
import { logger } from "../../../../utils/logger";
import CpFull, { ChargepointClass } from "../../../../model/Classes/Chargepoint";
import AreaSingle, { AreaFull } from "../../../../model/Classes/Area";
import User from "../../../../model/Classes/User";
import { useTranslation } from "react-i18next";

/**
 * Component for chargepoint settings. The component returns a tab menu from where the corresponding tasks can be comepleted.
 * The component also contains most states for the form fields (reasoning in the comments above the state) + helper functions.
 * @param {*} toggleEdit helper function for changing the view between settings and chargepoint view
 * @param {*} chargepoint the chargepoint that is supposed to be edited
 * @param {*} area the area the chargepoint belongs to
 * @param {*} allAreas all the areas the user has access to
 * @param {*} updateSessionStorageCp helper function for updating session storage
 * @param {*} history history object
 * @param {*} runUseEffect helper function for running the useEffect in Chargepoint.js again
 * @returns the edit view
 */
declare interface EditChargepointProps {
  toggleEdit: () => void;
  chargepoint: CpFull;
  area: AreaFull;
  allAreas: AreaSingle[];
  history: any;
  user: User;
  runUseEffect: (s: number) => void;
}
const EditChargepoint = ({
  toggleEdit,
  chargepoint,
  area,
  allAreas,
  history,
  user,
  runUseEffect,
}: EditChargepointProps) => {
  //The reason why most states are up here instead of at their respective components is due to the fact
  //that the states are needed for the functions handling the respective tasks (editing, moving, deleting).
  //It's easier to manage them if they are in this component
  const [newAddress, setNewAddress] = useState(chargepoint.address === null ? "" : chargepoint.address); //state for the new address
  const [newLatitude, setNewLatitude] = useState(`${chargepoint.latitude ? chargepoint.latitude : "0"}`); //state for the new latitude
  const [newLongitude, setNewLongitude] = useState(`${chargepoint.longitude ? chargepoint.longitude : "0"}`); //state for the new longitude
  const [newPrice, setNewPrice] = useState(`${chargepoint.price ? chargepoint.price.toFixed(2) : "0.00"}`); //state for the new price
  const [newDisabled, setNewDisabled] = useState(`${chargepoint.disabled}`); //state for the new active or disabled field
  const [newPhase, setNewPhase] = useState(`${chargepoint.phase}`); //state for the new phase
  const [newNickname, setNewNickname] = useState(chargepoint.nickname === null ? "" : chargepoint.nickname!); //state for the new nickname
  const [publicPrice, setPublicPrice] = useState(
    `${chargepoint.public_price ? chargepoint.public_price.toFixed(2) : "0.00"}`
  );
  const [publicStartPrice, setPublicStartPrice] = useState(
    `${chargepoint.public_start_price ? chargepoint.public_start_price.toFixed(2) : "0.00"}`
  );
  const [newConnLimit, setNewConnLimit] = useState(
    `${chargepoint.connector_limit ? chargepoint.connector_limit : "32"}`
  ); //state for connector limit
  const { t } = useTranslation();

  /**
   * Function responsible for handling settings submission. It tries to send the data to the backend,
   * and if successful, run the useEffect in Chargepoint.js again.
   * @returns true if successful, false if an error was caught
   */
  const handleSubmitSettingsCp = async () => {
    //Data containing the new details of the chargepoint
    const data = {
      nickname: newNickname === "" ? null : newNickname,
      address: newAddress === "" ? null : newAddress,
      latitude: newLatitude === "" ? 0 : Number(newLatitude),
      longitude: newLongitude === "" ? 0 : Number(newLongitude),
      price: Number(newPrice),
      disabled: Number(newDisabled),
      phase: Number(newPhase),
      public_price: Number(publicPrice),
      public_start_price: Number(publicStartPrice),
      connector_limit: Number(newConnLimit),
    };

    try {
      if (!area.id || area.id !== chargepoint.area_id || !chargepoint.charge_point_id) {
        throw new Error(`Area id did not match chargepoint's area id`);
      }

      await sendChargepointChanges(area.id, chargepoint.charge_point_id, data);
      runUseEffect(1);
      return true;
    } catch (e) {
      logger(e);

      return false;
    }
  };

  /**
   * Function responsible for handling move submission. It tries to send the data to the backend,
   * and if successful, run the useEffect in Chargepoint.js again.
   * @param {*} data the data that will be sent
   * @returns true if successful, false if an error was caught
   */
  const handleSubmitMoveCp = async (data) => {
    try {
      if (!area.id || area.id !== chargepoint.area_id || !chargepoint.charge_point_id) {
        throw new Error(`Area id did not match chargepoint's area id`);
      }

      await sendChargepointMove(chargepoint.charge_point_id, data);

      return true;
    } catch (e) {
      logger(e);

      return false;
    }
  };

  /**
   * Function responsible for handling delete submission. It tries to send a post request to the backend,
   * and if successful, run the useEffect in Chargepoint.js again.
   * @returns true if successful, false if an error was caught
   */
  const handleSubmitDeleteCp = async () => {
    try {
      if (!area.id || area.id !== chargepoint.area_id || !chargepoint.charge_point_id) {
        throw new Error(`Area id did not match chargepoint's area id`);
      }

      await sendChargepointDelete(area.id, chargepoint.charge_point_id);
      runUseEffect(-1);

      return true;
    } catch (e) {
      logger(e);

      return false;
    }
  };

  /**
   * The component returns a header + button + tab menu for the different settings. The Tabs component is responsible
   * for making sure the correct component is displayed when the user switches tabs.
   */
  return (
    <>
      <Row style={{ textAlign: "center" }}>
        <h2>
          {t("components.chargepoint.edit.header", {
            chargepoint: ChargepointClass.formatNickname(chargepoint),
          })}
        </h2>
      </Row>

      <Row className="mb-3">
        <Col>
          <Button variant="secondary" onClick={toggleEdit}>
            {t("global.buttons.back")}
          </Button>
        </Col>
      </Row>
      {/*Row containing the different tabs*/}
      <Row>
        <Tabs defaultActiveKey="first" id="" className="mb-3">
          <Tab eventKey="first" title={t("components.chargepoint.edit.tabs.basic.header")}>
            <EditSettingsCp
              area={area}
              chargepoint={chargepoint}
              //toggleEdit={toggleEdit}
              newAddress={newAddress}
              setNewAddress={setNewAddress}
              newLatitude={newLatitude}
              setNewLatitude={setNewLatitude}
              newLongitude={newLongitude}
              setNewLongitude={setNewLongitude}
              newPrice={newPrice}
              setNewPrice={setNewPrice}
              newDisabled={newDisabled}
              setNewDisabled={setNewDisabled}
              newPhase={newPhase}
              setNewPhase={setNewPhase}
              newNickname={newNickname}
              setNewNickname={setNewNickname}
              handleSubmitSettingsCp={handleSubmitSettingsCp}
              publicPrice={publicPrice}
              publicStartPrice={publicStartPrice}
              setPublicPrice={setPublicPrice}
              setPublicStartPrice={setPublicStartPrice}
              newConnLimit={newConnLimit}
              setNewConnLimit={setNewConnLimit}
            />
          </Tab>
          <Tab eventKey="second" title={t("components.chargepoint.edit.tabs.move.header")}>
            <EditMoveCp
              chargepoint={chargepoint}
              allAreas={allAreas}
              //toggleEdit={toggleEdit}
              area={area}
              history={history}
              handleSubmitMoveCp={handleSubmitMoveCp}
              user={user}
            />
          </Tab>
          <Tab eventKey="third" title={t("components.chargepoint.edit.tabs.delete.header")}>
            <EditDeleteCp
              chargepoint={chargepoint}
              handleSubmitDeleteCp={handleSubmitDeleteCp}
              history={history}
              areaId={area.id!}
            />
          </Tab>
        </Tabs>
      </Row>
    </>
  );
};

export default EditChargepoint;
