import { StyledAdminSearch } from './AdminSearch.styled';
import { Accordion, Card, ListGroup } from 'react-bootstrap';
import { useState } from 'react';
import { getAreaByContractId, getChargePointsByAreaId } from '../../services/adminService';
import { Link } from 'react-router-dom';
import ChargePointStatusImg from './ChargePointStatusIndicators';

const AdminSearchResult = ({ searchResult, gotResults, onContractSelect }) => {

  const [contractAccordionBody, setContractAccordionBody] = useState<any | null>(null);
  const [areaAccordionBody, setAreaAccordionBody] = useState<any | null>(null);

  async function getAccordionBody(e) {
    e.preventDefault();

    function sortResults(resData, key) {

      return resData.sort((a, b) => {
          if (!a[key]) return 1; // Move items with missing names to the end
          if (!b[key]) return -1;
          return a[key].localeCompare(b[key]);
      });
    }

    // parent is Card element and has id and className
    if (e.target.offsetParent.className === "Contract card") {
      try {
        const contract_id = e.target.offsetParent.id;

        const res = await getAreaByContractId(contract_id);
        const sortedResults = sortResults(res.data, "name")
        setContractAccordionBody(sortedResults);
      } catch (error) {
        console.log("Error", error);
      }
    } else if (e.target.offsetParent.className === "Area card") {
      try {
        const area_id = e.target.offsetParent.id;

        const res = await getChargePointsByAreaId(area_id);
        const sortedResults = sortResults(res.data, "nickname")
        setAreaAccordionBody(sortedResults);
      } catch (error) {
        console.log("Error", error);
      }
    }
  }

  return (
    <StyledAdminSearch className="searchResult">
      {!searchResult ? null : ( // avoid null rendering
        searchResult.response ? ( // mahdolliset error viestit
          <>
            <h2>{searchResult.response.statusText}</h2>
            <details>
              <summary className='summary'>
                Show error message
              </summary>
              <pre>{JSON.stringify(searchResult.response, null, 2)}</pre>
            </details>
          </>
        ) :
        !gotResults ? "No results found with FI-number, name, address, Business ID, city or area." : (  // show message if nothing found
          <div>
            {searchResult?.contracts?.length === 0 ? null : ( // check if contract null and check if there are any
              <div className='my-4 block'>
                {/* ----------------------Contracts--------------------- */}
                <h3>Contracts</h3>
                <Accordion>
                  {searchResult.contracts.map((contract: any, index: number) => (
                    <Card key={index} id={contract.id} className="Contract">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={index.toString()}
                        onClick={(e) => getAccordionBody(e)}
                        className="contract-header"
                      >
                        <div>{contract.id} <Link to={"/admin"} onClick={() => onContractSelect(contract.name)}>{contract.name}</Link></div>
                        <div>{contract.business_id}</div>
                        <div>{contract.street_address}</div>
                        <div>{contract.city}</div>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          {contractAccordionBody && contractAccordionBody.length !== 0 ? (
                            <>
                              {/* ----------------------Contract Areas--------------------- */}
                              <h5>Areas</h5>
                              {contractAccordionBody.map((area: any, index: number) => (
                                <div key={index}>
                                  {area.id + " "}
                                  <Link to={`/area/${area.id}`}>{area.name}</Link>
                                </div>
                              ))}
                            </>
                          ) : "no areas found"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            )}
            {searchResult?.areas?.length === 0 ? null : (
              <div className='mb-4'>
              {/* ----------------------Areas--------------------- */}
                <h3>Areas</h3>
                <Accordion>
                  {searchResult.areas.map((area: any, index: number) => (
                    <Card key={index} id={area.id} className="Area">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={index.toString()}
                        onClick={(e) => getAccordionBody(e)}
                        className="area-header"
                      >
                        {area.id + " "}
                        <Link to={`/area/${area.id}`}>{area.name}</Link>
                        {" - " + area.contract_name}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          {areaAccordionBody && areaAccordionBody.length !== 0 ? (
                            <>
                            {/* ----------------------Area Chargepoints--------------------- */}
                              <h5>Chargepoints</h5>
                              <ListGroup>
                                {areaAccordionBody.map((chargepoint: any, index: number) => (
                                  <ListGroup.Item key={index} className="chargepoint">
                                    <div className='mb-2'>
                                      <Link to={`/area/${chargepoint.area_id}/chargepoint/${chargepoint.charge_point_id}`} className='m-1'>
                                        {chargepoint.number_prefix + "-" + chargepoint.number}
                                      </Link>
                                      <ChargePointStatusImg chargepoint={chargepoint} />
                                    </div>
                                    <div>{chargepoint.nickname}</div>
                                    <div>{chargepoint.charge_point_id}</div>
                                    <div>{chargepoint.address}</div>
                                    <a href={`https://google.com/maps/place/${chargepoint.latitude},${chargepoint.longitude}`}>
                                      {chargepoint.latitude + ", " + chargepoint.longitude}
                                    </a>
                                    <div>{chargepoint.contract_name}</div>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : "no chargepoints found"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            )}
            {searchResult?.chargepoints?.length === 0 ? null : (
              <div className='mb-4'>
              {/* ----------------------Chargepoints--------------------- */}
                <h3>Chargepoints</h3>
                <ListGroup>
                  {searchResult.chargepoints.map((chargepoint: any, index: number) => (
                    <ListGroup.Item key={index} className='chargepoint'>
                      <div className='mb-2'>
                        <Link to={`/area/${chargepoint.area_id}/chargepoint/${chargepoint.charge_point_id}`}>
                          {chargepoint.number_prefix + "-" + chargepoint.number + " "}
                        </Link>
                      <ChargePointStatusImg chargepoint={chargepoint} />
                      </div>
                      <div>{chargepoint.nickname}</div>
                      <div>{chargepoint.charge_point_id}</div>
                      <div>{chargepoint.address}</div>
                      <a href={`https://google.com/maps/place/${chargepoint.latitude},${chargepoint.longitude}`}>
                        {chargepoint.latitude + ", " + chargepoint.longitude}
                      </a>
                      <div>{chargepoint.contract_name}</div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            )}
          </div>
        )
      )}
    </StyledAdminSearch>
  );
};

export default AdminSearchResult;