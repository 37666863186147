import { useEffect, useState } from "react";
import { Button, Form, InputGroup, ButtonGroup, ToggleButton, Modal, Alert } from "react-bootstrap";
import { StateHandler } from "../../../../model/Utilities/Types";
import { addChargepoint } from "../../../../services/chargepointService";
import { logger } from "../../../../utils/logger";
import { timer } from "../../../../utils/timer";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import { useTranslation } from "react-i18next";
import { AreaFull } from "../../../../model/Classes/Area";
import CpFull from "../../../../model/Classes/Chargepoint";
import { ChargepointPricing } from "../../Chargepoint/Settings/ChargepointPricing";
import User from "../../../../model/Classes/User";

/**
 * Form component responsible for displaying the form for adding a chargepoint. Also responsible
 * for validating the fields the user inputs and showing a popup with the given input. If the user
 * enters valid data and clicks the Add chargepoint button in the popup, the component will call the
 * function makeChanges(). If successful, redirect to the single area page and display a success alert.
 * Otherwise, display an error alert.
 * @param {*} cpId state for the chargepoint id field
 * @param {*} nickname state for the nickname field
 * @param {*} address state for the address field
 * @param {*} latitude state for the latitude field
 * @param {*} longitude state for the longitude field
 * @param {*} price state for the price field
 * @param {*} disabled state for the active/disabled field
 * @param {*} phase state for the phase field
 * @param {*} setCpId state handler for the cpId state
 * @param {*} setNickname state handler for the nickname state
 * @param {*} setAddress state handler for the address state
 * @param {*} setLatitude state handler for the latitude state
 * @param {*} setLongitude state handler for the longitude state
 * @param {*} setPrice state handler for the price state
 * @param {*} setDisabled state handler for the disabled state
 * @param {*} setPhase state handler for the phase state
 * @param {*} toggleAddCp toggler for switching between the single area view and add chargepoint view
 * @param {*} handleSubmitAddCp helper function for submitting the the form to the backend
 * @returns add chargepoint form
 */
declare interface AddChargepointFormProps {
  accessType: number;
  cpId: string;
  nickname: string;
  address: string;
  latitude: string;
  longitude: string;
  price: string;
  disabled: string;
  phase: string;
  setCpId: StateHandler<string>;
  setNickname: StateHandler<string>;
  setAddress: StateHandler<string>;
  setLatitude: StateHandler<string>;
  setLongitude: StateHandler<string>;
  setPrice: StateHandler<string>;
  setDisabled: StateHandler<string>;
  setPhase: StateHandler<string>;
  toggleAddCp: (success: boolean) => void;
  handleSubmitAddCp: () => Promise<boolean>;
  publicPrice: string;
  publicStartPrice: string;
  setPublicPrice: StateHandler<string>;
  setPublicStartPrice: StateHandler<string>;
  areaContract?: number;
  userlevel: number;
  area: AreaFull;
  pricingType?: number;
  connLimit: string;
  setConnLimit: StateHandler<string>;
}
const AddChargepointForm = ({
  accessType,
  cpId,
  nickname,
  address,
  latitude,
  longitude,
  price,
  disabled,
  phase,
  setCpId,
  setNickname,
  setAddress,
  setLatitude,
  setLongitude,
  setPrice,
  setDisabled,
  setPhase,
  toggleAddCp,
  handleSubmitAddCp,
  publicPrice,
  publicStartPrice,
  setPublicPrice,
  setPublicStartPrice,
  areaContract,
  userlevel,
  area,
  pricingType,
  connLimit,
  setConnLimit,
}: AddChargepointFormProps) => {
  const [showChange, setShowChange] = useState(false); //state for showing the add popup (modal)
  const [showCancel, setShowCancel] = useState(false); //state for showing the cancel popup (modal)
  const [showError, setShowError] = useState(false); //state for showing an error alert
  const [details, setDetails] = useState<string[]>([]); //state containing the details of the chargepoint when the user submits the form
  const [disabledFields, setDisabledFields] = useState(false); //state for disabling the input fields and buttons

  const [showCpIdAlert, setShowCpIdAlert] = useState(false); //state for showing an alert notifying the user to specify an id
  const [showNicknameAlert, setShowNicknameAlert] = useState(false); //state for showing an alert notifying the user that the nickname is too long
  const [showAddressAlert, setShowAddressAlert] = useState(false); //state for showing an alert notifying the user that the address is too long
  const [showLatitudeAlert, setShowLatitudeAlert] = useState(false); //state for showing an alert notifying the user to specify a latitude
  const [showLongitudeAlert, setShowLongitudeAlert] = useState(false); //state for showing an alert notifying the user to specify a longitude
  const [showPriceAlert, setShowPriceAlert] = useState(false); //state for showing an alert notifying the user specify a price between 0 and 10 (inclusive)
  const [showDisabledAlert, setShowDisabledAlert] = useState(false); //state for showing an alert notifying the user to specify if the chargepoint is active or disabled
  const [showPhaseAlert, setShowPhaseAlert] = useState(false); //state for showing an alert notifying the user to specify a phase
  const [showAreaContractAlert, setshowAreaContractAlert] = useState(false); //state for showing an alert notifying the user that chargepoint cannot be created or moved to area with contract_id=0
  const [showConnLimitAlert, setShowConnLimitAlert] = useState(false); // alert state for connector limit
  const { t } = useTranslation();

  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   * @returns true if the validation passes, false otherwise
   */
  const regex = /^[A-Za-z0-9_\-\\.ÆØÅæøåÄÖäö]+$/; //regulat expression for CP ID
  const validate = () => {
    //return value
    let success = true;
    cpId = cpId.trim();
    //checkes is the area has contract or not
    if (userlevel < 2 && areaContract === 0) {
      success = false;
      setshowAreaContractAlert(true);
    }

    if (cpId === "") {
      success = false;
      setShowCpIdAlert(true);
    } //Check if chargepoint id was specified

    if (!regex.test(cpId)) {
      success = false;
      setShowCpIdAlert(true);
    }
    if (nickname.length > 128) {
      success = false;
      setShowNicknameAlert(true);
    } //Check if nickname is too long
    if (address.length > 128) {
      success = false;
      setShowAddressAlert(true);
    } //Check if address is too long

    const latitudeNumber = Number(latitude);
    //Check if the latitude input is a number, not empty, nor more than 90 or lower than -90 (boundaries for latitude)
    if (isNaN(latitudeNumber) || latitudeNumber > 90 || latitudeNumber < -90) {
      success = false;
      setShowLatitudeAlert(true);
    }

    const longitudeNumber = Number(longitude);
    //Check if the longitude input is a number, not empty, nor more than 180 or lower than -180 (boundaries for longitude)
    if (isNaN(longitudeNumber) || longitudeNumber > 180 || longitudeNumber < -180) {
      success = false;
      setShowLongitudeAlert(true);
    }

    const priceNumber = Number(price);
    //Check if the price input is a number, not empty, nor more than 10 or negative
    if (isNaN(priceNumber) || price === "" || priceNumber > 10 || priceNumber < 0) {
      success = false;
      setShowPriceAlert(true);
    }

    //Check if the active/disabled buttons were pressed
    if (disabled === "") {
      success = false;
      setShowDisabledAlert(true);
    }

    //Check if any of the phase buttons were pressed
    if (phase === "") {
      success = false;
      setShowPhaseAlert(true);
    }

    if (accessType >= 2) {
      const ppNumber = Number(publicPrice); //ppNumber :DD
      const pspNumber = Number(publicStartPrice);
      if (ppNumber > 10 || ppNumber < 0) {
        setShowPriceAlert(true);
        success = false;
      }
      if (pspNumber > 10 || pspNumber < 0) {
        setShowPriceAlert(true);
        success = false;
      }
    }
    const connLimitNumber = Number(connLimit);
    //Check if the connector limit  is a number, not empty, nor less than 8 or decimala value
    if (isNaN(connLimitNumber) || connLimit === "" || connLimitNumber < 8 || connLimitNumber % 1 !== 0) {
      success = false;
      setShowConnLimitAlert(true);
    }

    return success;
  };
  /**
   * Helper function for checking the details of the chargepoint to be added. This function
   * is called AFTER the validation checker validate(). The function initializes an empty array,
   * and checks what changes were made and pushes a respective string to the array.
   * @param {*} data data object containing the form inputs
   * @returns array containing the details of the chargepoint
   */
  const checkDetails = (data: any) => {
    let detailsArray: string[] = [];
    detailsArray.push(`${t("components.area.addCp.details.cpId")}: ${cpId}`); //Push the chargepoint id detail

    //Push the correct nickname detail
    !data.nickname
      ? detailsArray.push(`${t("components.area.addCp.details.nickname.notHas")}`)
      : detailsArray.push(`${t("components.area.addCp.details.nickname.has")}: ${nickname}`);

    //Push the correct address detail
    !data.address
      ? detailsArray.push(t("components.area.addCp.details.address.notHas"))
      : detailsArray.push(`${t("components.area.addCp.details.address.has")}: ${address}`);

    detailsArray.push(`${t("components.area.addCp.details.position.lat")}: ${latitude}`); //Push the latitude detail
    detailsArray.push(`${t("components.area.addCp.details.position.long")}: ${longitude}`); //Push the longitude detail
    detailsArray.push(`${t("components.area.addCp.details.price")}: ${price} ${area.currency}`); //Push the price detail

    if (accessType && accessType >= 2) {
      detailsArray.push(
        `
        ${t("components.area.addCp.details.publicPrice")}: ${publicPrice} ${area.currency}
        `
      );
      detailsArray.push(
        `
        ${t("components.area.addCp.details.publicStart")}: ${publicStartPrice} ${area.currency}
        `
      );
    }
    //Push the correct phase detail
    detailsArray.push(`${t("components.area.addCp.details.phase")}: ${phases[data.phase].name}`);

    // push connector limit detail
    detailsArray.push(`${t("components.chargepoint.static.tabs.general.info.table.cpConnLimit")}:${connLimit}`);

    //Push the correct active/disabled detail
    !data.disabled
      ? detailsArray.push(t("components.area.addCp.details.active"))
      : detailsArray.push(t("components.area.addCp.details.disabled"));

    //Return the detail array
    return detailsArray;
  };

  /**
   * Helper function for showing the add chargepoint popup (modal). The function first toggles all alert off,
   * and then validates the input fields by calling the validate() function. If the validation didn't pass,
   * exit the function. If it passed, check for the details using the checkDetails() function and update the
   * details state with the correct information. Lastly, set the showChange state to true (triggers the popup
   * to appear).
   */
  const showChangeModal = () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([
      setShowCpIdAlert,
      setShowNicknameAlert,
      setShowAddressAlert,
      setShowLatitudeAlert,
      setShowLongitudeAlert,
      setShowPriceAlert,
      setShowDisabledAlert,
      setShowPhaseAlert,
      setShowConnLimitAlert,
    ]);

    //if the validation fails, exit the function
    if (!validate()) return;

    //Data object with the field values
    const data = {
      charge_point_id: cpId,
      nickname: nickname === "" ? null : nickname,
      address: address === "" ? null : address,
      latitude: Number(latitude),
      longitude: Number(longitude),
      price: Number(price),
      disabled: Number(disabled),
      phase: Number(phase),
      public_price: accessType && accessType >= 2 ? Number(publicPrice) : 0,
      public_start_price: accessType && accessType >= 2 ? Number(publicStartPrice) : 0,
      connector_limit: Number(connLimit),
    };

    //Check the details and update the details state accordingly
    setDetails(checkDetails(data));
    //Trigger the popup to appear
    setShowChange(true);
  };
  /**
   * Asynchronous helper function that is called when the user presses the submit button in the popup.
   * The function closes the popup, and calls the handleSubmitAddCp() function. If the submission was
   * successful, go back to the single area page and display an alert thelling the user that the
   * submission was successful. Otherwise, display an alert telling the user that the submission failed.
   */
  const makeChanges = async () => {
    setDisabledFields(true);
    setShowChange(false);

    //Call the helper function handleSubmitAddCp()
    (await handleSubmitAddCp())
      ? toggleAddCp(true) //Submission was successful, go back to the single area page and display a success alert
      : timer(setShowError); //Submission failed, show an error alert

    setDisabledFields(false);
  };

  //Arrays used for the active/disabled and phases buttons
  const activeOrDisabled = [
    { name: t("components.area.addCp.spec.active"), value: "0" },
    { name: t("components.area.addCp.spec.disabled"), value: "1" },
  ];

  const phases = [
    { name: "0° RST (A=L1, B=L2, C=L3)", value: "0" },
    { name: "120° STR (A=L2, B=L3, C=L1) ", value: "1" },
    { name: "240° TRS (A=L3, B=L1, C=L2)", value: "2" },
  ];
  const initialPhase = phases[0].value; // initial state for teh phase to be preselected and data passes as predefined
  const initialActive = activeOrDisabled[0].value; //Initial value for the disabled so thet it is predefined to be false on app load

  //useeffect to handle the changes made to phase by user
  useEffect(() => {
    setPhase(initialPhase);
    setDisabled(initialActive);
    //eslint-disable-next-line
  }, [initialPhase, initialActive]);

  const [showCoordinates, setShowCoordinates] = useState(false);
  /**
   * Return a form containing fields and buttons for the various inputs. Additionally, render
   * a popup whenever the save/cancel button is pressed, and alerts whenever they need to be shown.
   */
  return (
    <>
      {showError && ( //Show error alert if needed
        <Alert key="error" variant="danger">
          {t("global.view.errorTry")}
        </Alert>
      )}

      {showAreaContractAlert && (
        <Alert key="error" variant="danger">
          {t("global.alert.failure.cpCreate")}
        </Alert>
      )}

      {/*Simple text input field for the chargepoint id field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="name">{t("components.area.addCp.details.cpId")} (*)</InputGroup.Text>
        <Form.Control
          type="text"
          value={cpId}
          aria-label={cpId}
          aria-describedby="chargepointId"
          data-cy="cpId-field"
          disabled={disabledFields}
          onChange={(event) => {
            const trimmedValue = event.target.value.trim();
            setCpId(trimmedValue);
          }}
          pattern="^[A-Za-z0-9_\-\\.ÆØÅæøåÄÖäö]+(?:\s+[A-Za-z0-9_\-\\.ÆØÅæøåÄÖäö]+)*$"
        />
      </InputGroup>
      {showCpIdAlert && ( //Show alert notifying the user if the chargepoint id is invalid
        <Alert key="cpIdAlert" variant="danger">
          {t("global.alert.chargepointForm.invalid")}
        </Alert>
      )}

      {/*Simple text input field for the nickname field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="name">{t("components.area.addCp.details.nickname.has")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={nickname}
          placeholder={t("components.area.addCp.details.cpIdPlaceholder")}
          aria-label={nickname}
          aria-describedby="Nickname"
          data-cy="nickname-field"
          disabled={disabledFields}
          onChange={(event) => setNickname(event.target.value)}
        />
      </InputGroup>
      {showNicknameAlert && ( //Show alert notifying the user if the nickname is too long
        <Alert key="nicknameAlert" variant="danger">
          {t("global.alert.chargepointForm.nickTooLong")}
        </Alert>
      )}

      {/*Simple text input field for the address field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="name">{t("components.area.addCp.details.address.has")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={address}
          placeholder={t("components.area.addCp.details.address.placeholder")}
          aria-label={address}
          aria-describedby="Address"
          data-cy="address-field"
          disabled={disabledFields}
          onChange={(event) => setAddress(event.target.value)}
        />
      </InputGroup>
      {showAddressAlert && ( //Show alert notifying the user if the address is too long
        <Alert key="addressAlert" variant="danger">
          {t("global.alert.chargepointForm.addressTooLong")}
        </Alert>
      )}

      {/* Checkbox with its label to show/hide latitude and longitude fields */}
      <Form.Check
        type="checkbox"
        id="showCoordinates"
        label={t("components.chargepoint.static.tabs.general.info.addCoordinates")}
        checked={showCoordinates}
        onChange={() => setShowCoordinates(!showCoordinates)}
        style={{ width: "250px" }}
      />
      {/*Simple text input field for the latitude field*/}
      <InputGroup className="mb-3">
        {/* condition to show/hide latitude and longitude fields */}

        {showCoordinates && (
          <>
            <InputGroup.Text id="name">{t("components.area.addCp.details.position.lat")}</InputGroup.Text>

            <Form.Control
              type="text"
              value={latitude}
              placeholder={t("components.area.addCp.details.position.latPlaceholder")}
              aria-label={latitude}
              aria-describedby="Latitude"
              data-cy="latitude-field"
              disabled={disabledFields}
              onChange={(event) => {
                setLatitude(event.target.value);
              }}
            />
          </>
        )}
      </InputGroup>
      {showLatitudeAlert && ( //Show alert notifying the user if the latitude is invalid
        <Alert key="latitudeAlert" variant="danger">
          {t("global.alert.chargepointForm.invalidLat")}
        </Alert>
      )}

      {/*Simple text input field for the longitude field*/}

      <InputGroup className="mb-3">
        {showCoordinates && (
          <>
            <InputGroup.Text id="name">{t("components.area.addCp.details.position.long")}</InputGroup.Text>

            <Form.Control
              type="text"
              value={longitude}
              placeholder={t("components.area.addCp.details.position.longPlaceholder")}
              aria-label={longitude}
              aria-describedby="Longitude"
              data-cy="longitude-field"
              disabled={disabledFields}
              onChange={(event) => {
                setLongitude(event.target.value);
              }}
            />
          </>
        )}
      </InputGroup>
      {showLongitudeAlert && ( //Show alert notifying the user if the longitude is invalid
        <Alert key="longitudeAlert" variant="danger">
          {t("global.alert.chargepointForm.invalidLong")}
        </Alert>
      )}
      {pricingType === 0 && (
        <ChargepointPricing
          price={price}
          setPrice={setPrice}
          publicPrice={publicPrice}
          publicStartPrice={publicStartPrice}
          setPublicPrice={setPublicPrice}
          setPublicStartPrice={setPublicStartPrice}
          disabledFields={disabledFields}
          accessType={accessType}
          showPriceAlert={showPriceAlert}
          pricingType={pricingType}
          area={area}
        />
      )}
      <>
        {/*ButtonGroup containing the button input fields for the phase buttons.*/}
        <InputGroup className="mb-3">
          <InputGroup.Text>{t("components.area.addCp.details.phase")}</InputGroup.Text>
          <ButtonGroup>
            {/*Map the three elements in the array (done this way to reduce DRY code)*/}
            {phases.map((radio, index) => (
              //Return a togglable button
              <ToggleButton
                key={radio.value}
                id={`phase-radio-${radio.value}`}
                type="checkbox"
                variant="outline-success"
                name="radio"
                value={radio.value}
                checked={phase === radio.value || (index === 0 && phase === "")}
                data-cy={`phase-${radio.value}-button`}
                disabled={disabledFields}
                onChange={(event) => setPhase(event.target.value)}
              >
                {/**Dont think these (phases) need to be translated */}
                {` ${radio.name}`}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </InputGroup>

        {/**input field for connector limit */}
        {showConnLimitAlert && ( //Show alert notifying the user to specify correct value for limit
          <Alert key="disabledAlert" variant="danger">
            {t("global.alert.chargepointForm.invalidConnLimit")}
          </Alert>
        )}

        <InputGroup className="mb-3">
          <InputGroup.Text id="name">
            {t("components.chargepoint.static.tabs.general.info.table.cpConnLimit")}
          </InputGroup.Text>
          <Form.Control
            type="text"
            min="8"
            placeholder="Only whole values Eg: 10"
            value={connLimit}
            aria-label={connLimit}
            aria-describedby="connector limit"
            data-cy="connector limit"
            disabled={disabledFields}
            onChange={(event) => setConnLimit(event.target.value)}
          />
        </InputGroup>

        {/*ButtonGroup containing the button input fields for the active/disabled buttons.*/}
        <ButtonGroup className="mb-3">
          {/*Map the two elements in the array (done this way to reduce DRY code)*/}
          {activeOrDisabled.map((radio, idx) => (
            //Return a togglable button
            <ToggleButton
              key={radio.value}
              id={`activeOrDisabled-radio-${radio.value}`}
              type="checkbox"
              variant={idx % 2 ? "outline-danger" : "outline-success"} //first one outline-success, second one outline-danger
              name="radio"
              value={radio.value}
              checked={disabled === radio.value || (idx === 0 && disabled === "")}
              data-cy={idx % 2 ? "disabled-button" : "active-button"}
              disabled={disabledFields}
              onChange={(event) => setDisabled(event.target.value)}
            >
              {` ${radio.name}`}
            </ToggleButton>
          ))}
        </ButtonGroup>
        {showDisabledAlert && ( //Show alert notifying the user to specify if the chargepoint is active or not
          <Alert key="disabledAlert" variant="danger">
            {t("global.alert.chargepointForm.activeDisabled")}
          </Alert>
        )}

        <br />

        {showPhaseAlert && ( //Show an alert notifying the user to specify a phase
          <Alert key="phaseAlert" variant="danger">
            {t("global.alert.chargepointForm.invalidPhase")}
          </Alert>
        )}

        <br />
        {/*ButtonGroup containing the save and cancel button with their corresponding popups*/}
        <ButtonGroup className="mb-3">
          <>
            {/*Save button*/}
            <Button variant="primary" onClick={showChangeModal} disabled={disabledFields} data-cy="save-button">
              {t("global.buttons.save")}
            </Button>
            {/*Popup that is shown when the save button is pressed and the input fields are successfully validated*/}
            <Modal
              show={showChange}
              onHide={() => setShowChange(false)}
              backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
              keyboard={false} //doesn't allow the user to close the modal by using the keyboard
            >
              <Modal.Header>
                <Modal.Title>{t("components.area.addCp.modal.titleDetails")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/*Show a list with all the details*/}
                {t("components.area.addCp.modal.confirmDetails")}
                <ul>
                  {details.map((change, idx) => {
                    return (
                      <li key={idx} data-cy={`change-${idx}`}>
                        {change}
                      </li>
                    );
                  })}
                </ul>
              </Modal.Body>
              {/*Submit and cancel buttons*/}
              <Modal.Footer>
                <Button variant="success" onClick={makeChanges}>
                  {t("global.buttons.add.cp")}
                </Button>
                <Button variant="secondary" onClick={() => setShowChange(false)}>
                  {t("global.buttons.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
          <>
            {/*Cancel button*/}
            <Button variant="secondary" onClick={() => setShowCancel(true)}>
              {t("global.buttons.cancel")}
            </Button>
            {/*Popup that is shown when the cancel button is pressed*/}
            <Modal
              show={showCancel}
              onHide={() => setShowCancel(false)}
              backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
              keyboard={false} //doesn't allow the user to close the modal by using the keyboard
            >
              <Modal.Header>
                <Modal.Title>{t("components.area.addCp.modal.titleCancel")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("components.area.addCp.modal.confirmCancel")}</Modal.Body>
              <Modal.Footer>
                {/*Cancel and go back buttons*/}
                <Button variant="danger" onClick={() => toggleAddCp(false)}>
                  {t("global.buttons.cancel")}
                </Button>
                <Button variant="secondary" onClick={() => setShowCancel(false)}>
                  {t("global.buttons.back")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </ButtonGroup>
      </>
    </>
  );
};

declare interface AddChargepointProps {
  toggleAddCp: (success: boolean) => void;
  area: AreaFull;
  runUseEffect: (s: number) => void;
  user: User;
}

/**
 * Component for adding a chargepoint. The component keeps the states for the data that will be sent
 * to the backend. Additionally, it contains a helper function handleSudmitAddCp() responsible for
 * calling the service function addChargepoint() as well as helper function updateSessionStorage()
 * whenever a chargepoint is added.
 * @param {*} toggleAddCp state handler for switching between the single area view and add chargepoint view
 * @param {*} area the area the chargepoint will be added to
 * @param {*} runUseEffect helper function for running the useEffect in SingleArea.js
 * @returns add chargepoint page
 */
const AddChargepoint = ({ toggleAddCp, area, runUseEffect, user }: AddChargepointProps) => {
  const [cpId, setCpId] = useState(""); //state for the chargepoint id field
  const [nickname, setNickname] = useState(""); //state for the nickname field
  const [address, setAddress] = useState(""); //state for the address field
  const [latitude, setLatitude] = useState(""); //state for the latitude field
  const [longitude, setLongitude] = useState(""); //state for the longitude field
  const [price, setPrice] = useState(`${area.default_price}`); //state for the price field
  const [publicPrice, setPublicPrice] = useState(
    `${area.default_public_price ? area.default_public_price : area.default_price}`
  );
  const [publicStartPrice, setPublicStartPrice] = useState(
    `${area.default_public_start_price ? area.default_public_start_price : 0}`
  );
  const [disabled, setDisabled] = useState(""); //state for the active/disabled field
  const [phase, setPhase] = useState(""); //state for the phase field
  const [connLimit, setConnLimit] = useState("32"); //state for the phase field
  const { t } = useTranslation();

  /**
   * Helper function for submitting the new chargepoint data to the backend. The function calls
   * the service function addChargepoint, If the operation was successful, run the useEffect in
   * SingleArea.js again.
   * @returns true if successful, false otherwise
   */
  const handleSubmitAddCp = async () => {
    const data: CpFull = {
      charge_point_id: cpId,
      nickname: nickname === "" ? null : nickname, //if no nickname was given, send it as null
      address: address === "" ? null : address, //if no address was given, send it as null
      latitude: Number(latitude),
      longitude: Number(longitude),
      price: Number(price),
      disabled: Number(disabled),
      phase: Number(phase),
      public_price:
        area.access_type && area.access_type >= 2
          ? Number(publicPrice)
          : Number(price) > Number(area.default_public_price)
          ? Number(price)
          : Number(area.default_public_price),
      public_start_price: area.access_type && area.access_type >= 2 ? Number(publicStartPrice) : 0,
      connector_limit: Number(connLimit),
    };

    if (!area || !area.id) {
      logger("Area not found");
      return false;
    }

    try {
      await addChargepoint(area.id, data); //Call a service function for sending the new chargepoint data to the backend
      runUseEffect(1);

      return true;
    } catch (e) {
      //Some errors occured
      logger(e);

      return false;
    }
  };

  //Simply returns a header and the AddChargepointForm component
  return (
    <div className="anchor-chargepoint-add">
      <h2>
        {t("components.area.addCp.static.header")} {area.name}
      </h2>
      <AddChargepointForm
        accessType={area.access_type ? area.access_type! : 1}
        toggleAddCp={toggleAddCp}
        cpId={cpId}
        address={address}
        latitude={latitude}
        longitude={longitude}
        price={price}
        disabled={disabled}
        phase={phase}
        setCpId={setCpId}
        setAddress={setAddress}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        setPrice={setPrice}
        setDisabled={setDisabled}
        setPhase={setPhase}
        nickname={nickname}
        setNickname={setNickname}
        handleSubmitAddCp={handleSubmitAddCp}
        publicPrice={publicPrice}
        publicStartPrice={publicStartPrice}
        setPublicPrice={setPublicPrice}
        setPublicStartPrice={setPublicStartPrice}
        areaContract={area.contract_id}
        userlevel={user.user_level}
        area={area}
        pricingType={area.pricing_type}
        connLimit={connLimit}
        setConnLimit={setConnLimit}
      />
    </div>
  );
};

export default AddChargepoint;
