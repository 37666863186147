import { useState } from "react";
import { Alert, InputGroup, Form, Button, ButtonGroup, ToggleButton, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AreaFull } from "../../../../model/Classes/Area";
import CpFull from "../../../../model/Classes/Chargepoint";
import StructuredResponse from "../../../../model/Classes/StructuredResponse";
import { StateHandler } from "../../../../model/Utilities/Types";
import { timer } from "../../../../utils/timer";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import { ChargepointPricing } from "./ChargepointPricing";

declare interface EditSettingsCpProps {
  area: AreaFull;
  chargepoint: CpFull;
  newNickname: string;
  newAddress?: string;
  newLatitude: string;
  newLongitude: string;
  newPrice: string;
  newDisabled: string;
  newPhase: string;
  setNewNickname: StateHandler<string>;
  setNewAddress: StateHandler<string>;
  setNewLatitude: StateHandler<string>;
  setNewLongitude: StateHandler<string>;
  setNewPrice: StateHandler<string>;
  setNewDisabled: StateHandler<string>;
  setNewPhase: StateHandler<string>;
  handleSubmitSettingsCp: () => Promise<boolean>;
  publicPrice: string;
  publicStartPrice: string;
  setPublicPrice: StateHandler<string>;
  setPublicStartPrice: StateHandler<string>;
  newConnLimit: string;
  setNewConnLimit: StateHandler<string>;
}
/**
 * Component for the edit chargepoint view where you can change the details about the chargepoint
 * @param {*} chargepoint the chargepoint to be edited
 * @param {*} newNickname state for the new nickname
 * @param {*} newAddress state for the new address
 * @param {*} newLatitude state for the new latitude
 * @param {*} newLongitude state for the new longitude
 * @param {*} newPrice state for the new price
 * @param {*} newDisabled state for the active/disable state of the chargepoint
 * @param {*} newPhase state for the new phase
 * @param {*} setNewNickname state handler for the nickname state
 * @param {*} setNewAddress state handler for the address state
 * @param {*} setNewLatitude state handler for the latitude state
 * @param {*} setNewLongitude state handler for the longitude state
 * @param {*} setNewPrice state handler for the price state
 * @param {*} setNewDisabled state handler for the active/disable state
 * @param {*} setNewPhase state handler for the phase state
 * @param {*} handleSubmitSettingsCp function responsible for handling settings submission.
 * @returns the edit chargepoint view
 */
const EditSettingsCp = ({
  area,
  chargepoint,
  newNickname,
  newAddress,
  newLatitude,
  newLongitude,
  newPrice,
  newDisabled,
  newPhase,
  setNewNickname,
  setNewAddress,
  setNewLatitude,
  setNewLongitude,
  setNewPrice,
  setNewDisabled,
  setNewPhase,
  handleSubmitSettingsCp,
  publicPrice,
  publicStartPrice,
  setPublicPrice,
  setPublicStartPrice,
  newConnLimit,
  setNewConnLimit,
}: EditSettingsCpProps) => {
  const [showChange, setShowChange] = useState(false); //state for showing the changes that will be made
  const [showSuccess, setShowSuccess] = useState(false); //state for showing a success alert
  const [showError, setShowError] = useState(false); //state for showing an error alert
  const [changes, setChanges] = useState<string[]>([]); //state containing the changes that will be made
  const [disabledFields, setDisabledFields] = useState(false); //state for disabling the input fields and buttons

  const [showNoChangesAlert, setShowNoChangesAlert] = useState(false); //state for showing an alert notifying the user to make a change before submitting
  const [showNicknameAlert, setShowNicknameAlert] = useState(false); //state for showing an alert if the nickname is too long
  const [showAddressAlert, setShowAddressAlert] = useState(false); //state for showing an alert if the address is too long
  const [showLatitudeAlert, setShowLatitudeAlert] = useState(false); //state for showing an alert if the latitude is invalid
  const [showLongitudeAlert, setShowLongitudeAlert] = useState(false); //state for showing an alert if the longitude is invalid
  const [showPriceAlert, setShowPriceAlert] = useState(false); //state for showing an alert if the price is invalid
  const [showConnLimitAlert, setShowConnLimitAlert] = useState(false); //state for showing an alert if the price is invalid

  const { t } = useTranslation();
  /**
   * Helper function to validate the input fields. The function checks for multiple criteria, and if a criteria fails
   * it sets the success variable to false and toggles the respective alert on.
   * This is done instead of instantly returning false, because then all the criteria are checked instead of the
   * function stopping once the first violation occured.
   * @returns true if the validation passes, false otherwise
   */
  const validate = () => {
    //Return value
    let success = true;

    if (newNickname.length > 128) {
      success = false;
      setShowNicknameAlert(true);
    } //Check if the nickname is too long
    if (newAddress && newAddress.length > 128) {
      success = false;
      setShowAddressAlert(true);
    } //Check if the address is too long

    const latitudeNumber = Number(newLatitude);
    //Check if the latitude input is a number, not empty, nor more than 90 or lower than -90 (boundaries for latitude)
    if (isNaN(latitudeNumber) || latitudeNumber > 90 || latitudeNumber < -90) {
      success = false;
      setShowLatitudeAlert(true);
    }

    const longitudeNumber = Number(newLongitude);
    //Check if the longitude input is a number, not empty, nor more than 180 or lower than -180 (boundaries for longitude)
    if (isNaN(longitudeNumber) || longitudeNumber > 180 || longitudeNumber < -180) {
      success = false;
      setShowLongitudeAlert(true);
    }

    const priceNumber = Number(newPrice);
    //Check if the price input is a number, not empty, nor more than 10 or negative
    if (isNaN(priceNumber) || newPrice === "" || priceNumber < 0 || priceNumber > 10) {
      success = false;
      setShowPriceAlert(true);
    }

    if (area.access_type && area.access_type >= 2) {
      const ppNumber = Number(publicPrice); //ppNumber :DD
      const pspNumber = Number(publicStartPrice);
      if (ppNumber > 10 || ppNumber < 0) {
        setShowPriceAlert(true);
        success = false;
      }
      if (pspNumber > 10 || pspNumber < 0) {
        setShowPriceAlert(true);
        success = false;
      }
    }
    const connLimitNumber = Number(newConnLimit);
    if (isNaN(connLimitNumber) || newConnLimit === "" || connLimitNumber < 8) {
      success = false;
      setShowConnLimitAlert(true);
    }
    return success;
  };

  /**
   * Helper function for checking all the changes made by the user. The changes are checked by comparing
   * the data that the user will send against the existing data for the chargepoint.
   * If there are changes, the corresponding change string will be pushed to an array
   * that will be returned at the end of the function.
   * @param {*} data data to be checked
   * @returns an array with with the first element being true if there were changes made, and the second
   * element being the array containing the changes
   */
  const checkChanges = (data) => {
    let changesArray: string[] = [];

    //Push the correct nickname change
    if (data.nickname !== chargepoint.nickname) {
      !data.nickname
        ? changesArray.push(t("components.chargepoint.edit.tabs.basic.changes.nickname.notHas"))
        : changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.nickname.has", {
              oldNickname: chargepoint.nickname,
              newNickname: data.nickname,
            })
          );
    }
    //Push the correct address change
    if (data.address !== chargepoint.address)
      !chargepoint.address
        ? changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.address.noPrev", {
              address: data.address,
            })
          )
        : !data.address
        ? changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.address.hadPrev", { address: chargepoint.address })
          )
        : changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.address.new", {
              oldAddress: chargepoint.address,
              newAddress: data.address,
            })
          );
    //Push the correct latitude change
    if (data.latitude !== chargepoint.latitude)
      changesArray.push(
        t("components.chargepoint.edit.tabs.basic.changes.latitude", {
          oldLatitude: chargepoint.latitude,
          newLatitude: newLatitude,
        })
      );
    //Push the correct longitude change
    if (data.longitude !== chargepoint.longitude)
      changesArray.push(
        t("components.chargepoint.edit.tabs.basic.changes.longitude", {
          oldLongitude: chargepoint.longitude,
          newLongitude: newLongitude,
        })
      );
    //Push the correct price change
    if (data.price !== chargepoint.price)
      changesArray.push(
        t("components.chargepoint.edit.tabs.basic.changes.price", {
          oldPrice: chargepoint.price,
          newPrice: data.price,
        })
      );

    if (area.access_type && area.access_type >= 2) {
      if (data.public_price !== chargepoint.public_price)
        changesArray.push(
          `
        ${t("components.chargepoint.edit.tabs.basic.changes.publicPrice", {
          newPublicPrice: data.public_price,
          oldPublicPrice: chargepoint.public_price ? chargepoint.public_price : 0,
        })} ${area.currency}
        `
        );
      if (data.public_start_price !== chargepoint.public_start_price)
        changesArray.push(
          `
        ${t("components.chargepoint.edit.tabs.basic.changes.publicStartPrice", {
          newPublicStartPrice: data.public_start_price,
          oldPublicStartPrice: chargepoint.public_start_price ? chargepoint.public_start_price : 0,
        })} ${area.currency}
        `
        );
    }

    //Push the correct phase change
    if (data.phase !== chargepoint.phase) {
      changesArray.push(
        `${t("components.chargepoint.edit.tabs.basic.changes.phase", {
          oldPhase: phases[chargepoint.phase!].name,
          newPhase: phases[data.phase].name,
        })} ${area.currency}`
      );
    }
    //Push the correct active/disabled change
    if (data.disabled !== chargepoint.disabled) {
      data.disabled
        ? changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.activeDisabled", {
              isActiveOrDisabled: t("components.area.addCp.details.disabled"),
              isDisabledOrActive: t("components.area.addCp.details.active"),
            })
          )
        : changesArray.push(
            t("components.chargepoint.edit.tabs.basic.changes.activeDisabled", {
              isActiveOrDisabled: t("components.area.addCp.details.active"),
              isDisabledOrActive: t("components.area.addCp.details.disabled"),
            })
          );
    }
    //push the correct connector limit value
    if (data.connector_limit !== chargepoint.connector_limit) {
      changesArray.push(
        `${t("components.chargepoint.edit.tabs.basic.changes.connLimit", {
          oldConnLimit: chargepoint.connector_limit,
          newLimit: data.connector_limit,
        })}`
      );
    }

    //Return an array [true if changes were made/false otherwise, changes as an array]
    return new StructuredResponse(changesArray.length > 0, changesArray);
  };

  /**
   * Helper function for displaying the changes popup (modal). The function first toggles all alert off,
   * and then validates the input fields by calling the validate() function. If the validation didn't pass,
   * exit the function. If it passed, check for the changes using the checkChanges() function and update the
   * changes state with the correct information (if changes were made). Lastly, set the showChange state to
   * true (triggers the popup to appear) if changes were made, otherwise set the showNoChangesAlert to true.
   */
  const showChangeModal = () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([
      setShowNicknameAlert,
      setShowAddressAlert,
      setShowLatitudeAlert,
      setShowLongitudeAlert,
      setShowPriceAlert,
      setShowConnLimitAlert,
      setShowNoChangesAlert,
    ]);

    //If the validation fails, exit the function
    if (!validate()) return;

    //Data object with the field values
    const data = {
      nickname: newNickname === "" ? null : newNickname,
      address: newAddress === "" ? null : newAddress,
      latitude: Number(newLatitude),
      longitude: Number(newLongitude),
      price: Number(newPrice),
      disabled: Number(newDisabled),
      phase: Number(newPhase),
      public_price: area.access_type && area.access_type >= 2 ? Number(publicPrice) : 0,
      public_start_price: area.access_type && area.access_type >= 2 ? Number(publicStartPrice) : 0,
      connector_limit: Number(newConnLimit),
    };

    //Check the changes
    const changesReturn = checkChanges(data);

    //If there were no changes, display an alert notifying the user about this
    if (!changesReturn.success) {
      setShowChange(false);
      setShowNoChangesAlert(true);
    } else {
      //Changes were made, update the changes state, toggle the alerts off and trigger the popup to appear.
      setChanges(changesReturn.data);
      //Call the utility function toggleAlertsOff to toggle all the alerts off
      toggleAlertsOff([
        setShowNicknameAlert,
        setShowAddressAlert,
        setShowLatitudeAlert,
        setShowLongitudeAlert,
        setShowPriceAlert,
        setShowConnLimitAlert,
        setShowNoChangesAlert,
      ]);
      setShowChange(true);
    }
  };

  /**
   * Asynchronous helper function that is called when the user presses the submit button in the popup.
   * The function closes the popup, and calls the handleSubmitSettingsCp() function. If the submission was
   * successful, display a success alert. Otherwise, display an alert telling the user that the submission failed.
   */
  const makeChanges = async () => {
    setDisabledFields(true);
    setShowChange(false);

    //Call the helper function handleSubmitSettingsCp()
    (await handleSubmitSettingsCp())
      ? timer(setShowSuccess) //Submission was successful, display a success alert
      : timer(setShowError); //Submission failed, show an error alert

    setDisabledFields(false);
  };

  //Arrays used for the active/disabled and phases buttons
  const activeOrDisabled = [
    { name: t("components.area.addCp.details.active"), value: "0" },
    { name: t("components.area.addCp.details.disabled"), value: "1" },
  ];

  const phases = [
    { name: "0° RST (A=L1, B=L2, C=L3)", value: "0" },
    { name: "120° STR (A=L2, B=L3, C=L1) ", value: "1" },
    { name: "240° TRS (A=L3, B=L1, C=L2)", value: "2" },
  ];

  /**
   * Return a form containing the fields and buttons for the various inputs. Additionally, render
   * a popup whenever the save/cancel button is pressed, and alerts whenever they need to be shown
   */
  return (
    <>
      {showSuccess && ( //Show success alert if needed
        <Alert key="success" variant="success">
          {t("global.alert.success.areaChanges")}
        </Alert>
      )}
      {showError && ( //Show error alert if needed
        <Alert key="error" variant="danger">
          {t("global.view.error")}
        </Alert>
      )}

      {/*Simple text input field for the nickname field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="nickname">{t("components.area.addCp.details.nickname.has")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={newNickname}
          aria-label={chargepoint.nickname ? chargepoint.nickname : ""}
          aria-describedby="Nickname"
          disabled={disabledFields}
          onChange={(event) => setNewNickname(event.target.value)}
        />
      </InputGroup>
      {showNicknameAlert && ( //Show alert notifying the user if the nickname is too long
        <Alert key="nicknameAlert" variant="danger">
          {t("global.alert.chargepointForm.nickTooLong")}
        </Alert>
      )}

      {/*Simple text input field for the address field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="address">{t("components.area.addCp.details.address.has")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={newAddress}
          aria-label={chargepoint.address ? chargepoint.address : ""}
          aria-describedby="Address"
          disabled={disabledFields}
          onChange={(event) => setNewAddress(event.target.value)}
        />
      </InputGroup>
      {showAddressAlert && ( //Show alert notifying the user if the address is too long
        <Alert key="addressAlert" variant="danger">
          {t("global.alert.chargepointForm.addressTooLong")}
        </Alert>
      )}

      {/*Simple text input field for the latitude field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="latitude">{t("components.area.addCp.details.position.lat")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={newLatitude}
          aria-label={chargepoint.latitude as unknown as string}
          aria-describedby="Latitude"
          disabled={disabledFields}
          onChange={(event) => setNewLatitude(event.target.value)}
        />
      </InputGroup>
      {showLatitudeAlert && ( //Show alert notifying the user that the latitude is invalid
        <Alert key="latitudeAlert" variant="danger">
          {t("global.alert.chargepointForm.invalidLat")}
        </Alert>
      )}

      {/*Simple text input field for the longitude field*/}
      <InputGroup className="mb-3">
        <InputGroup.Text id="longitude">{t("components.area.addCp.details.position.long")}</InputGroup.Text>
        <Form.Control
          type="text"
          value={newLongitude}
          aria-label={chargepoint.longitude as unknown as string}
          aria-describedby="Longitude"
          disabled={disabledFields}
          onChange={(event) => setNewLongitude(event.target.value)}
        />
      </InputGroup>
      {showLongitudeAlert && ( //Show alert notifying the user that the longitude is invalid
        <Alert key="longitudeAlert" variant="danger">
          {t("global.alert.chargepointForm.invalidLong")}
        </Alert>
      )}
      {area.pricing_type === 0 && (
        <ChargepointPricing
          price={newPrice}
          setPrice={setNewPrice}
          publicPrice={publicPrice}
          publicStartPrice={publicStartPrice}
          setPublicPrice={setPublicPrice}
          setPublicStartPrice={setPublicStartPrice}
          disabledFields={disabledFields}
          accessType={area.access_type}
          showPriceAlert={showPriceAlert}
          area={area}
        />
      )}
      <>
        {/*ButtonGroup containing the button input fields for the phase buttons.*/}
        <InputGroup className="mb-3">
          <InputGroup.Text>{t("components.area.addCp.details.phase")}</InputGroup.Text>
          <ButtonGroup>
            {/*Map the three elements in the array (done this way to reduce DRY code)*/}
            {phases.map((radio) => (
              //Return a togglable button
              <ToggleButton
                key={radio.value}
                id={`phase-radio-${radio.value}`}
                type="checkbox"
                variant="outline-success"
                name="radio"
                value={radio.value}
                checked={newPhase === radio.value}
                disabled={disabledFields}
                onChange={(event) => setNewPhase(event.target.value)}
              >
                {` ${radio.name}`}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </InputGroup>

        {/** connector limit input  field */}

        {showConnLimitAlert && ( //Show alert notifying the user that the connector limit is invalid
          <Alert key="connector limit alert" variant="danger">
            {t("global.alert.chargepointForm.invalidConnLimit")}
          </Alert>
        )}

        <InputGroup className="mb-3">
          <InputGroup.Text id="connLimit">
            {t("components.chargepoint.static.tabs.general.info.table.cpConnLimit")}
          </InputGroup.Text>
          <Form.Control
            type="text"
            value={newConnLimit}
            aria-label={chargepoint.connector_limit as unknown as string}
            aria-describedby="connector limit"
            disabled={disabledFields}
            onChange={(event) => setNewConnLimit(event.target.value)}
          />
        </InputGroup>

        {/*ButtonGroup containing the button input fields for the active/disabled buttons.*/}
        <ButtonGroup className="mb-3">
          {/*Map the two elements in the array (done this way to reduce DRY code)*/}
          {activeOrDisabled.map((radio, idx) => (
            //Return a togglable button
            <ToggleButton
              key={radio.value}
              id={`activeOrDisabled-radio-${radio.value}`}
              type="checkbox"
              variant={idx % 2 ? "outline-danger" : "outline-success"} //first one outline-success, second one outline-danger
              name="radio"
              value={radio.value}
              checked={newDisabled === radio.value}
              disabled={disabledFields}
              onChange={(event) => setNewDisabled(event.target.value)}
            >
              {` ${radio.name}`}
            </ToggleButton>
          ))}
        </ButtonGroup>
        <br />
        {/*ButtonGroup containing the save and cancel button with their corresponding popups*/}
        <ButtonGroup className="mb-3">
          <>
            {/*Save button*/}
            <Button variant="primary" onClick={showChangeModal} disabled={disabledFields}>
              {t("global.buttons.save")}
            </Button>
            {/*Popup that is shown when the save button is pressed and the input fields are successfully validated*/}
            <Modal
              show={showChange}
              onHide={() => setShowChange(false)}
              backdrop="static" //doesn't allow the user to close the modal by clicking outside of it
              keyboard={false} //doesn't allow the user to close the modal by using the keyboard
            >
              <Modal.Header>
                <Modal.Title>{t("global.generalWords.confirm")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/*Show a list with all the changes*/}
                {t("components.area.edit.changes.changes")}:
                <ul>
                  {changes.map((change, idx) => {
                    return <li key={idx}>{change}</li>;
                  })}
                </ul>
              </Modal.Body>
              {/*Submit and cancel buttons*/}
              <Modal.Footer>
                <Button variant="success" onClick={makeChanges}>
                  {t("global.buttons.submit")}
                </Button>
                <Button variant="secondary" onClick={() => setShowChange(false)}>
                  {t("global.buttons.cancel")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </ButtonGroup>
        {showNoChangesAlert && ( //Show alert notifying the user to make changes before submitting
          <Alert key="noChangesAlert" variant="warning">
            {t("global.alert.failure.requireChange")}
          </Alert>
        )}
      </>
    </>
  );
};

export default EditSettingsCp;
