import { useState, useEffect } from "react";
import { Row, Alert, Button, Col } from "react-bootstrap";
import { getUserTags } from "../../../../services/userService";
import { decodeAndMakeDate } from "../../../../utils/dates";
import { toggleAlertsOff } from "../../../../utils/toggleAlertsOff";
import { TagsTable, AddTag } from "./UserSettingsTagsComponents";
import { handlePostTag, validate, sortTags } from "./UserSettingsTagsUtils";
import { logger } from "../../../../utils/logger";
import { Tag } from "@shared/types/Tag";
import User from "../../../../model/Classes/User";
import { TransactionCp } from "../../../../model/Classes/Chargepoint";
import { Nullable, StateHandler } from "../../../../model/Utilities/Types";
import { useTranslation } from "react-i18next";
import { getChargingAccess } from "../../../../services/chargepointService";
import { getAllChargepointsForTagGeneration } from "../../../../services/userService";
import { getPublicChargepoints } from "../../../../services/chargepointService";

declare interface UserSettingsTagsProps {
  user: User;
  redirectToAddTag: boolean;
  cpId: string;
  setCpId: StateHandler<string>;
  cpLabel: string;
}
/**
 * Component responsible for rendering the entire tags tab view. Each individual component is defined
 * in UserSettingsTagsComponents.js
 * @param {User} user user of the application
 * @returns tags tab view
 */
const UserSettingsTags = ({ user, redirectToAddTag, cpId, setCpId, cpLabel }: UserSettingsTagsProps) => {
  const [allTags, setAllTags] = useState<Tag[]>([]); //state for all the tags the user owns
  const [allChargepoints, setAllChargepoints] = useState<TransactionCp[]>([]); //state for all the chargepoints the user owns
  const [allChargepointsReal, setAllChargepointsReal] = useState<TransactionCp[]>([]);
  const [publicChargepoints, setPublicChargepoints] = useState<TransactionCp[]>([]);
  const [tagScanEventId, setTagScanEventId] = useState<Nullable<number>>(null); //state for the new tag id field
  const [newlyAddedTagId, setNewlyAddedTagId] = useState<Nullable<number>>(null); //state for the tag id when a new tag id is successfully created. Needed since the tag id might be randomly generated from the backend
  const [newTagNickname, setNewTagNickname] = useState(""); //state for the new tag nickname
  const [validUntilDate, setValidUntilDate] = useState(""); //state for the valid until date for the new tag
  const [validUntilTime, setValidUntilTime] = useState(""); //state for the valud until time for the new tag
  const [newDisabled, setNewDisabled] = useState("0"); //state for knowing if the new tag will be disabled or not, initially active
  const [backendScanning, setBackendScanning] = useState(false); //state for knowing if the backend is scanning for a tag using a chargepoint
  const [backendScanningComplete, setBackendScanningComplete] = useState(false); //state for knowing if the backend successfully scanned for a tag using a chargepoint
  const [showView, setShowView] = useState(-1); //state for showing the loading screen, error screen, or UserSettingsTags screen
  const [disabledFields, setDisabledFields] = useState(false); //state for disabling the input fields and buttons
  const [addNewTag, setAddNewTag] = useState(redirectToAddTag);
  //eslint-disable-next-line
  const [emptyTagIdAlert, setEmptyTagIdAlert] = useState(false); //state for showing an alert notifying the user that the tag id field for a new tag cannot be empty
  const [invalidLengthTagIdAlert, setInvalidLengthTagIdAlert] = useState(false); //state for showing an alert notifying the user that the tag id field is of invalid length
  const [invalidActiveOrDisabledAlert, setInvalidActiveOrDisabledAlert] = useState(false); //state for showing an alert notifying the user to specify if the new tag will be active or disabled
  const [noEmptyNicknameAlert, setNoEmptyNicknameAlert] = useState(false); //state for showing an alert notifying the user to provide a nickname
  const [invalidNicknameAlert, setInvalidNicknameAlert] = useState(false); //state for showing an alert notifying the user to provide a valid nickname
  const [nicknameAlreadyExistsAlert, setNicknameAlreadyExistsAlert] = useState(false); //state for showing an alert notifying the user to provide a nickname that doesn't exist
  const [invalidEditNicknameAlert, setInvalidEditNicknameAlert] = useState(false); //state for showing an alert notifying the user to provide a valid nickname (when editting)
  const [noEmptyEditNicknameAlert, setNoEmptyEditNicknameAlert] = useState(false); //state for showing an alert notifying the user to provide a nickname (when editting)
  const [editNicknameAlreadyExistsAlert, setEditNicknameAlreadyExistsAlert] = useState(false); //state for showing an alert notifying the user to provide a nickname that doesn't exist

  const [successfullAddAlert, setSuccessfullAddAlert] = useState(false); //state for showing an alert notifying the user that adding a new tag was successful
  const [failedAddAlert, setFailedAddAlert] = useState(false); //state for showing an alert notifying the user that adding a new tag failed
  const [successfullDeleteAlert, setSuccessfullDeleteAlert] = useState(false); //state for showing an alert notifying the user that deleting a tag was successful
  const [failedDeleteAlert, setFailedDeleteAlert] = useState(false); //state for showing an alert notifying the user that deleting a tag failed

  const { t } = useTranslation();

  /**
   * When the component initializes, the useEffect hook fetches all the tags that belong to the user (the user is checked
   * at the backend using the tokens), as well as all the chargepoints the user has charging access to. If it's successful,
   * update the allTags and allChargepoints states with the data respective and set the showView state to 1 (shows the
   * UserSettingsTags tab). Otherwise, log the error and set the showView state to 0 (shows an error message to the user).
   */

  useEffect(() => {
    /**
     * Asynchronous helper function for calling service functions that fetches the needed data from the backend.
     * If it succeeds, update the correct states with all the data and set the showView state to 1. Otherwise,
     * set the showView state to 0.
     */

    const getData = async () => {
      //Fetch the users tags and chargepoints where the user has charging access from the backend
      const res = await getUserTags();
      const allChargepointsRes = await getAllChargepointsForTagGeneration();
      const chargingAccessRes = await getChargingAccess();
      const publicChargepointsRes = await getPublicChargepoints();

      //Check if the requests came back as successful
      if (res.success && chargingAccessRes.success && allChargepointsRes.success && publicChargepointsRes.success) {
        //Yes, update the allTags and allChargepoints state with the tags data and set the showView state to 1 to show the page to the user
        setAllChargepoints(
          chargingAccessRes.data.sort((a: TransactionCp, b: TransactionCp) =>
            b.charge_point_id! < a.charge_point_id! ? 1 : -1
          )
        );
        setAllChargepointsReal(
          allChargepointsRes.data.sort((a, b) => (b.charge_point_id! < a.charge_point_id! ? 1 : -1))
        );
        setPublicChargepoints(publicChargepointsRes.data);

        sortTags(res.data, setAllTags);
        setShowView(1); //Set the showView state to 1 to show the UserSettingsTags tab
      } else {
        //Errors when fetching the tags?
        if (!res.success) {
          logger(res.data);
        }

        //Errors when fetching the chargepoints?
        if (!chargingAccessRes.success) {
          logger(chargingAccessRes.data);
        }

        setShowView(0); //Set the showView state to 0 notifying the user that an error occured
      }
    };
    getData(); //Call the asynchronous helper function
  }, [user.user_level]);

  //If coming from startTransaction
  useEffect(() => {
    setAddNewTag(redirectToAddTag);
  }, [redirectToAddTag]);

  //Resets the add form and closes it using states.
  const resetAddTagFormAndClose = (): void => {
    resetAddTagForm();
    setAddNewTag(false);
  };

  const resetAddTagForm = (): void => {
    setTagScanEventId(null);
    setNewTagNickname("");
    setValidUntilDate("");
    setValidUntilTime("");
    setBackendScanning(false);
    setBackendScanningComplete(false);
    setDisabledFields(false);
    toggleAlertsOff([
      setEmptyTagIdAlert,
      setInvalidLengthTagIdAlert,
      setInvalidActiveOrDisabledAlert,
      setInvalidNicknameAlert,
      setNoEmptyNicknameAlert,
      setNicknameAlreadyExistsAlert,
    ]);
  };

  /**
   * Asynchronous helper function for adding a new tag. The function initially toggles all the alerts off,
   * and then validates the form fields. If the validation fails, exit the function. Otherwise, it checks
   * if the user specified a validity date. Finally it calls one of two helper functions, depending on if
   * the user wanted to add the tag id using a chargepoint or not.
   */
  const handleTagAdd = async (): Promise<boolean> => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([
      setEmptyTagIdAlert,
      setInvalidLengthTagIdAlert,
      setInvalidActiveOrDisabledAlert,
      setInvalidNicknameAlert,
      setNoEmptyNicknameAlert,
      setNicknameAlreadyExistsAlert,
      setSuccessfullAddAlert,
      setFailedAddAlert,
    ]);

    if (
      !validate(
        allTags,
        newTagNickname,
        setInvalidNicknameAlert,
        setNoEmptyNicknameAlert,
        setNicknameAlreadyExistsAlert
      )
    )
      return false; //If the validation fails, exit the function

    //The value for the valid until data, initially null (null = no valid until date)
    let validUntil = "";

    //If the user inserted a valid until date AND time, change the validUntil variable to contain the
    //data in ISO-String format (needed since it's stored in this format in the database). This is
    //done by first decoding the fields from html date input format to a Javascript Date object. After
    //that, we simply call the toISOString() function for the Date object to convert it into an ISO-String.
    if (validUntilDate !== "") {
      validUntil = decodeAndMakeDate(validUntilDate, "23:59:59").toISOString();
    }

    // const convertedTagDate = convertToTimeZone(validUntil,userSystemTime)
    const attemptPostTag = await handlePostTag(
      tagScanEventId,
      newTagNickname,
      validUntil,
      newDisabled,
      setNewlyAddedTagId,
      setSuccessfullAddAlert,
      setFailedAddAlert,
      setAllTags,
      setShowView,
      setDisabledFields
    );
    if (attemptPostTag) {
      resetAddTagFormAndClose();
      return true;
    } else {
      resetAddTagForm();
      return false;
    }
  };

  /**
   * Return a loading screen, an error screen, or the view for seeing your own tags etc.
   */
  return showView === -1 ? (
    <>
      <h2 className="align-self-center">{t("global.view.loading")}</h2>
    </>
  ) : showView === 0 ? (
    <h2 className="align-self-center">{t("global.view.error")}</h2>
  ) : addNewTag ? (
    <AddTag
      newTagNickname={newTagNickname}
      validUntilDate={validUntilDate}
      validUntilTime={validUntilTime}
      newDisabled={newDisabled}
      allChargepoints={allChargepoints}
      allChargepointsReal={allChargepointsReal}
      publicChargepoints={publicChargepoints}
      disabledFields={disabledFields}
      backendScanning={backendScanning}
      backendScanningComplete={backendScanningComplete}
      setFailedAddAlert={setFailedAddAlert}
      setTagScanEventId={setTagScanEventId}
      setNewTagNickname={setNewTagNickname}
      setValidUntilDate={setValidUntilDate}
      setValidUntilTime={setValidUntilTime}
      setNewDisabled={setNewDisabled}
      invalidLengthTagIdAlert={invalidLengthTagIdAlert}
      invalidActiveOrDisabledAlert={invalidActiveOrDisabledAlert}
      handleTagAdd={handleTagAdd}
      noEmptyNicknameAlert={noEmptyNicknameAlert}
      invalidNicknameAlert={invalidNicknameAlert}
      nicknameAlreadyExistsAlert={nicknameAlreadyExistsAlert}
      setBackendScanning={setBackendScanning}
      setBackendScanningComplete={setBackendScanningComplete}
      failedAddAlert={failedAddAlert}
      resetAndClose={resetAddTagFormAndClose}
      cpId={cpId}
      setCpId={setCpId}
      cpLabel={cpLabel}
    />
  ) : (
    <>
      <TagsTable
        allTags={allTags}
        setAllTags={setAllTags}
        setShowView={setShowView}
        noEmptyEditNicknameAlert={noEmptyEditNicknameAlert}
        setNoEmptyEditNicknameAlert={setNoEmptyEditNicknameAlert}
        invalidEditNicknameAlert={invalidEditNicknameAlert}
        setInvalidEditNicknameAlert={setInvalidEditNicknameAlert}
        editNicknameAlreadyExistsAlert={editNicknameAlreadyExistsAlert}
        setEditNicknameAlreadyExistsAlert={setEditNicknameAlreadyExistsAlert}
        successfullDeleteAlert={successfullDeleteAlert}
        setSuccessfullDeleteAlert={setSuccessfullDeleteAlert}
        failedDeleteAlert={failedDeleteAlert}
        setFailedDeleteAlert={setFailedDeleteAlert}
      />
      {/*Row for some alerts*/}
      <Row>
        {successfullAddAlert && ( //Show an alert notifying the user that adding a tag was successful
          <Alert variant="success" key="successfullAdd">
            {t("global.alert.success.tagAdd", {
              tagId: newlyAddedTagId,
            })}
          </Alert>
        )}
      </Row>
      <Row>
        {/*Show tag adding form if button is pressed*/}
        {!addNewTag && (
          <Col sm="auto">
            <Button
              onClick={() => {
                toggleAlertsOff([setSuccessfullAddAlert, setSuccessfullDeleteAlert, setFailedDeleteAlert]);
                setAddNewTag(true);
              }}
              variant="success"
            >
              {t("global.buttons.add.tag")}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default UserSettingsTags;
