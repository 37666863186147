import {
    Row,
    InputGroup,
    Col,
    Alert,
    Form,
    ButtonGroup,
    ToggleButton,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { StateHandler } from '../../../../model/Utilities/Types';
import { PrefixForm } from './PrefixForm';

declare interface AreaMeterSettingsProps {
  has_meter: string;
  backupLimit: number | string;
  setBackupLimit?: StateHandler<number>;
  setBackupLimitString?: StateHandler<string>;
  disabledFields: boolean;
  showBackupLimitAlert: boolean;
  WDLMID?: number;
  setWDLMID?: StateHandler<number>;
  slaveID?: number;
  setSlaveID?: StateHandler<number>;
  WDLMIDAlert?: boolean;
  slaveIDAlert?: boolean;
  setMeter: StateHandler<any>;
  showMeterAlert: boolean;
  editArea: boolean;
  meterAlert: boolean;
}

const AreaMeterSettings = ({
  has_meter,
  backupLimit,
  setBackupLimit,
  setBackupLimitString,
  disabledFields,
  showBackupLimitAlert,
  WDLMID,
  setWDLMID,
  slaveID,
  setSlaveID,
  WDLMIDAlert,
  slaveIDAlert,
  setMeter,
  showMeterAlert,
  editArea,
  meterAlert,
}: AreaMeterSettingsProps) => {
    const { t } = useTranslation();

  //Arrays used for the area meter and load balancing buttons
  const meters = [
    { name: t('components.area.addArea.static.form.meter.notHas'), value: '0' },
    { name: t('components.area.addArea.static.form.meter.has'), value: '1' },
  ];
  //validated further in editsettings by validBackup()
  const handleSetBackupLimit = (event: string) => {
    typeof backupLimit === 'string'
      ? setBackupLimitString!(event)
      : setBackupLimit!(Number(event));
  };

  return (
    <div>
      <h4>{t('components.area.addArea.static.form.headers.meter')}</h4>
      {/*Display the area meter toggle button*/}
      <ButtonGroup className="mb-3">
        {meters.map((radio, idx) => (
          <ToggleButton
            key={radio.name}
            id={`meter-radio-${idx}`}
            type="checkbox"
            variant={idx % 2 ? 'outline-success' : 'outline-danger'}
            name="radio"
            value={radio.value}
            checked={has_meter === radio.value}
            disabled={disabledFields}
            onChange={(e) => setMeter(e.currentTarget.value)}
            data-cy={`editAreaMeterButton-${idx}`}
          >
            {` ${radio.name}`}
          </ToggleButton>
        ))}
      </ButtonGroup>

      {showMeterAlert && (
        <Alert key="invalidMeter" variant="danger">
          {t('global.alert.failure.meter')}
        </Alert>
      )}
      <br />
      {editArea && Number(has_meter) === 1 && (
        <>
          {/* Row for area_meter input */}
          <Row className="mb-3">
            <InputGroup className="mb-3">
              <InputGroup.Text>W-DLM ID</InputGroup.Text>
              <PrefixForm
                aria-label="meter-public-id"
                prefix="WTRY-"
                initialValue={WDLMID!}
                setBtsValue={setWDLMID!}
              />
            </InputGroup>

            {WDLMIDAlert! && (
              <Col sm="auto" xs="auto">
                <Alert key="invalidPublicId" variant="danger">
                  {t('global.alert.failure.dlm')}
                </Alert>
              </Col>
            )}

            <InputGroup>
              <InputGroup.Text>Modbus slave ID</InputGroup.Text>
              <Form.Control
                id="meter-slave-id"
                type="number"
                value={slaveID!}
                onChange={(event) => setSlaveID!(Number(event.target.value))}
              />
            </InputGroup>
          </Row>
          {slaveIDAlert && (
            <Col sm="auto" xs="auto">
              <Alert key="invalidSlaveId" variant="danger">
                {t('global.alert.failure.slave')}
              </Alert>
            </Col>
          )}

          {/*Display the backup limit field only if the area has a meter*/}
        </>
      )}
      {Number(has_meter) === 1 && (
        <InputGroup className="mb-3">
          <InputGroup.Text id="backup_limit">
            {t('components.area.addArea.static.form.onFailure')}
          </InputGroup.Text>
          <Form.Control
            type="number"
            value={backupLimit ? backupLimit : ''}
            //min="10"
            aria-label={'area.meter_fail_limit'}
            aria-describedby="backup_limit"
            disabled={disabledFields}
            onChange={(event) => handleSetBackupLimit(event.target.value)}
            data-cy="editAreaBackupLimitField"
          />
        </InputGroup>
      )}
      {
        showBackupLimitAlert ? (
          <Alert key="invalidBackupLimit" variant="danger" className="mt-3">
            {t('global.alert.failure.backupLimit')}
          </Alert>
        ) : (
          <></>
        ) //Empty paragraph to add some space below the form text
      }
      {meterAlert && (
        <Alert variant="danger" key={'invalidIdCombination'}>
          {t('global.alert.failure.meterIdSlaveCombo')}
        </Alert>
      )}
    </div>
  );
};

export default AreaMeterSettings;
