import styled from "styled-components";

/**
 * Styled component for the transaction accordionbody
 */

export const StyledTransactionAccordionBody = styled.div`

    .kWh-based {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
    }

    .spot-price {
        margin-left: 38px;
    }
`;