import { StyledAdminUserInfo } from "./AdminUserInfo.styled";
import { ListGroup, Accordion, Card, Container, Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import {
  getUserInfoById,
  getCompletedTransactionsByUserId,
  getCompletedTransactionsByUserInvoiceId,
  getInvoiceItemsByUserInvoiceId,
} from "../../services/adminService";
import { Link } from "react-router-dom";
import getCurrencySymbol from "src/utils/getCurrencySymbol";
import { DateTime } from "luxon";
import TransactionAccordion from "./TransactionAccordion";

// Types
import { User } from "@shared/types/User";
import { Area } from "@shared/types/Area";
import { ActiveTransaction } from "@shared/types/ActiveTransaction";
import { CompletedTransaction } from "@shared/types/CompletedTransaction";
import { UserInvoice } from "@shared/types/UserInvoice";
import { UserInvoiceItem } from "@shared/types/UserInvoiceItem";
import { StripeCard } from "@shared/types/StripeCard";
import { PaytrailCard } from "@shared/types/PaytrailCard";

const AdminUserInfo = ({ userId }) => {
  const [userInfo, setUserInfo] = useState<User | null>(null);
  const [userAuthorizedAreas, setUserAuthorizedAreas] = useState<Area[] | null>(null);
  const [userActiveTransactions, setUserActiveTransactions] = useState<ActiveTransaction[] | null>(null);
  const [userCompletedTransactions, setUserCompletedTransactions] = useState<CompletedTransaction[] | null>(null);
  const [userAdminAreas, setUserAdminAreas] = useState<Area[] | null>(null);
  const [userInvoices, setUserInvoices] = useState<UserInvoice[] | null>(null);
  const [invoiceCompletedTransactions, setInvoiceCompletedTransactions] = useState<CompletedTransaction[] | null>(null);
  const [invoiceItems, setInvoiceItems] = useState<UserInvoiceItem[] | null>(null);
  const [offset, setOffset] = useState<number | null>(10);
  const [userStripeCards, setUserStripeCards] = useState<StripeCard[] | null>(null);
  const [userPaytrailCards, setUserPaytrailCards] = useState<PaytrailCard[] | null>(null);

  const formatDateTime = (dateTime, timezone) => {
    return DateTime.fromISO(dateTime, { zone: timezone }).setLocale("fi").toFormat("dd.MM.yyyy HH:mm");
  };

  // Get base info for user
  const getUserInfo = async (id) => {
    try {
      const res = await getUserInfoById(id);

      setUserInfo(res.data.userInfo);
      setUserStripeCards(res.data.userStripeCards);
      setUserPaytrailCards(res.data.userPaytrailCards);
      setUserAdminAreas(res.data.userAdminAreas);
      setUserAuthorizedAreas(res.data.userAuthorizedAreas);
      setUserActiveTransactions(res.data.userActiveTransactions);
      setUserCompletedTransactions(res.data.userCompletedTransactions);
      setUserInvoices(res.data.userInvoices);

    } catch (error) {
      console.error("getUserInfo error: ", error);
    }
  };

  // More completed transactions is clicked
  const getUserCompletedTransactions = async (id, offset) => {
    try {
      setOffset(offset + offset);
      const res = await getCompletedTransactionsByUserId(id, offset);
      setUserCompletedTransactions([...(userCompletedTransactions ?? []), ...res.data]);
    } catch (error) {
      console.error("getUserCompletedTransactions error: ", error);
    }
  }

  // Invoice Accordion is clicked
  const getInvoiceAccordionBody = async (e) => {
    e.preventDefault();

    const invoice_id = e.target.offsetParent.id;

    try {
      const transactionRes = await getCompletedTransactionsByUserInvoiceId(invoice_id);
      const itemRes = await getInvoiceItemsByUserInvoiceId(invoice_id);
      setInvoiceCompletedTransactions(transactionRes.data);
      setInvoiceItems(itemRes.data);
    }
    catch (error) {
      console.error("getInvoiceAccordionBody error: ", error
      );
    }
  }

  // for invoice status indicator
  const invoiceStatusMap = {
    0: { text: "new", color: "#F0F0F0" },
    1: { text: "sent", color: "#E5E8B7" },
    2: { text: "paid", color: "#CBE7D6" },
    3: { text: "not paid", color: "#FA988F" },
    4: { text: "canceled", color: "#E0E0E0" },
  };
  
  // for payment method status indicator
  const renderPaymentMethodStatus = (status) => {
    
    const paymentStatusMap = {
      0: { text: "Not set", color: "#E0E0E0" },
      1: { text: "OK", color: "#CBE7D6" },
      2: { text: "grace period", color: "#E5E8B7" },
      3: { text: "blocked", color: "#FA988F" },
    };

    const paymentStatusStyle = {
      paddingBlock: "5px",
      paddingInline: "10px",
      borderRadius: "4px",
    };

    const { text, color } = paymentStatusMap[status] || { text: "Not set", color: "#E0E0E0" };

    return <div style={{ ...paymentStatusStyle, backgroundColor: color }}>{text}</div>;
  };

  // on page load or userId change
  useEffect(() => {
    getUserInfo(userId);
  }, [userId]);

  return (
    <StyledAdminUserInfo>
      {!userInfo ? null : ( //            -------------User Info----------------
        <>
            <div className="mb-4">
              <h3>
                {userInfo.first_name} {userInfo.last_name}
              </h3>
              <div className="mb-4">{userInfo.email}</div>
              <div>id : {userInfo.id}</div>
              <div className="d-inline-flex justify-content-between align-items-center gap-2">
                Payment method status : {renderPaymentMethodStatus(userInfo.payment_method_status)}
              </div>
            </div>  {/*            -------------Payment Cards----------------        */}
            <div className="mb-4">
              <h4>Cards</h4>
              <ListGroup>
                {!userStripeCards || userStripeCards.length === 0 ? null : (
                  <>
                    {userStripeCards?.map((card, index) => (
                      <div key={index}>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <div>
                          number: **** **** **** {card.last4}
                          </div>
                          <div>
                          expire date: {card.expire_month + "/" + card.expire_year?.slice(2, 4)}
                          </div>
                          <div>
                          Stripe
                          </div>
                          </ListGroup.Item>
                      </div>
                    ))}
                  </>
                )}
                {!userPaytrailCards || userPaytrailCards.length === 0 ? null : (
                  <>
                    {userPaytrailCards?.map((card, index) => (
                      <div key={index}>
                        <ListGroup.Item className="d-flex justify-content-between">
                          <div>
                          number: **** **** **** {card.partial_pan}
                          </div>
                          <div>
                            expire date: {card.expire_month + "/" + card.expire_year.slice(2, 4)}
                          </div>
                          <div>
                            Paytrail
                          </div>
                        </ListGroup.Item>
                      </div>
                    ))}
                  </>
                )}      {/*            -------------Admin areas----------------        */}
              </ListGroup>
            </div>
          {userAdminAreas && userAdminAreas.length > 0 ? (
            <div className="mb-4">
              <h4>Admin areas</h4>
              <ListGroup>
                {userAdminAreas.map((area, index) => (
                  <ListGroup.Item key={index}>
                    {area.id}
                    <Link to={`/area/${area.id}`}> {area.name}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          ) : null}
          {!userAuthorizedAreas || userAuthorizedAreas.length === 0 ? null : (
            <div className="mb-4"> {/*            -------------Authorized areas----------------        */}
              <h4>Authorized areas</h4>
              <ListGroup>
                {userAuthorizedAreas.map((area, index) => (
                  <ListGroup.Item key={index}>
                    {area.id}
                    <Link to={`/area/${area.id}`}> {area.name}</Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </div>
          )}
          {!userActiveTransactions || userActiveTransactions.length === 0 ? null : (
            <div className="mb-4"> {/*            -------------Active transactions----------------        */}
              <h4>Active transactions</h4>
              <TransactionAccordion transactions={userActiveTransactions} isActiveTransaction={true} />
            </div>
          )}
          
          {!userCompletedTransactions || userCompletedTransactions.length === 0 ? (
              <div className="mb-4">
                No completed transactions
              </div>
            ) : (
              <div className="mb-4">  {/*            -------------Completed transactions----------------        */}
                <h4>Completed transactions</h4>
                <TransactionAccordion transactions={userCompletedTransactions} isActiveTransaction={false} />
                <a className="mt-4 d-block"
                  href="/"
                  onClick={(e) => {
                    e.preventDefault(); // Prevents navigation while keeping href
                    getUserCompletedTransactions(userId, offset);
                  }}
                >
                  More completed transactions
                </a>
              </div>
            )}
          {!userInvoices || userInvoices.length === 0 ? (
              <div className="mb-4">
                No invoices
              </div>
            ) : (
              <div className="mb-4">  {/*            -------------User Invoices--------------        */}
                <h4>User Invoices</h4>
                <Accordion>
                  {userInvoices.map((invoice, index) => (
                    <Card key={index} id={String(invoice.id)}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={index.toString()}
                        onClick={(e) => getInvoiceAccordionBody(e)}
                        style={{ backgroundColor: invoiceStatusMap[invoice.invoice_status]?.color}}
                      >
                        <Container>
                          <Row>
                            <Col>{invoice.reference_number}</Col>
                            <Col sm="auto">{formatDateTime(invoice.period_start, "Europe/Helsinki") + " -- " + formatDateTime(invoice.period_end, "Europe/Helsinki")}</Col>
                            <Col>{invoice.date_paid?.toString()}</Col>
                            <Col>{invoiceStatusMap[invoice.invoice_status]?.text}</Col>
                            <Col className="ms-auto text-end">{invoice.amount + getCurrencySymbol(invoice.currency!)}</Col>
                          </Row>
                        </Container>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          {!invoiceCompletedTransactions ||
                            invoiceCompletedTransactions.length === 0 ? (
                            <div className="mb-4">no completed transactions</div>
                          ) : (
                            <div className="mb-4">
                              <h4>Completed transactions</h4> {/*            -------------Invoice Completed Transactions----------------        */}
                              <TransactionAccordion transactions={invoiceCompletedTransactions} isActiveTransaction={false} />
                            </div>
                          )}
                          {invoiceItems && invoiceItems.length !== 0 ? (
                            <>
                              <h4>Items</h4>
                              <ListGroup>
                                {invoiceItems.map((item, index) => (
                                  <ListGroup.Item key={index} className="d-flex justify-content-between">
                                    <div>{item.description}</div>
                                    <div>{item.unit_price ? item.unit_price : 0 * item.quantity + getCurrencySymbol(invoice.currency!)}</div>
                                  </ListGroup.Item>
                                ))}
                              </ListGroup>
                            </>
                          ) : "no items found"}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))}
                </Accordion>
              </div>
            )
          }
        </>
      )}
    </StyledAdminUserInfo>
  );
};

export default AdminUserInfo;
