import { bool } from "prop-types";
import { StyledMenu } from "./Menu.styled";
import { Link } from "react-router-dom";
import { logout } from "../../../services/authService";
import { timer } from "../../../utils/timer";
import { logger } from "../../../utils/logger";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import User from "../../../model/Classes/User";
import { useTranslation } from "react-i18next";

declare interface MenuProps {
  open: boolean;
  setOpen: StateHandler<boolean>;
  user: Nullable<User>;
  setUser: StateHandler<Nullable<User>>;
  setLogoutSuccessAlert: StateHandler<boolean>;
  history: any;
  id: any;
}
/**
 * Component responsible displaying the menu containing all the necessary links for the application, and
 * handling functionality related to logging out.
 * There are three different looks to the menu, depending on the user level of the user:
 *
 * Area managers (and admins):
 * - Home
 * - Start Transaction
 * - My Transactions
 * - Area Management
 * - Reporting
 * - Admin (ONLY SHOWN TO ADMINS!)
 * - Settings
 * - Logout
 *
 * Basic user:
 * - Home
 * - Start Transaction
 * - My transactions
 * - Settings
 * - Logout
 *
 * When the user logs out, the reset helper function makes a logout request to the backend. If it's
 * successful, the response will automatically remove the cookies present when the user is logged
 * in. Then it updates some states in addition to the user state, and the application pushes the
 * user to the login page.
 * @param {*} open state for showing the menu
 * @param {*} setOpen state handler for showing the menu
 * @param {*} user state for user accessing the menu
 * @param {*} setUser state handler for user
 * @param {*} setNotification state handler for displaying notifications
 * @param {*} history history object used for redirecting
 * @returns menu containing all the necessary links for the application
 */
const Menu = ({ open, setOpen, user, setUser, setLogoutSuccessAlert, history, id }: MenuProps, ...props: any[]) => {
  //Needed for the aria-hidden prop for the StyledMenu component
  const isHidden = open ? true : false;
  const { t } = useTranslation();

  //Tab index used to disable access to the links by using the tab key on the keyboard.
  //Tabbing is not possible when the menu is closed
  const tabIndex = isHidden ? 0 : -1;

  /**
   * Asynchronous helper function for logging out. The function calls the logout() service function,
   * which makes a request to the backend for logging out. If it's successful, the response will
   * automatically remove the cookies present when user is logged in (hence why we don't need to store
   * the result in a variable).
   * If this is successful, set the user state to null, set the logoutSuccessAlert state to true for
   * 6 seconds, and push the user to the login page. If it fails, log the error.
   */
  const reset = async () => {
    try {
      await logout(); //Call the logout() service function

      //Update the user and logoutSuccessAlert states
      setUser(null);
      localStorage.removeItem("user");
      timer(setLogoutSuccessAlert);

      // _Reload_ to the login page
      window.location.replace("/login");
    } catch (e) {
      logger(e);
    }
  };

  /**
   * Helper function for setting the open state to false
   */
  const hide = () => {
    setOpen(false);
  };

  //If the user is not logged in, do not show the menu.
  //Otherwise, show the menu
  if (!user) return null;
  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      <Link to="/" tabIndex={tabIndex} onClick={hide}>
        {t("components.menu.home")}
      </Link>

      {
        //Links to content specific for area managers/admins. If a user is not an area manager/admin, only show a link to /myTransactions and /startNewTransaction
        user.user_level > 0 ? (
          <>
            <a href="/startNewTransaction" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.startTransaction")}
            </a>
            <a href="/myTransactions" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.myTransactions")}
            </a>
            <a href="/area" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.areaManagement")}
            </a>
            <a href="/reporting" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.reporting")}
            </a>
          </>
        ) : (
          <>
            <a href="/startNewTransaction" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.startTransaction")}
            </a>
            <a href={`/myTransactions`} tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.myTransactions")}
            </a>
          </>
        )
      }

      {
        //Links to content specific for an admin
        user.user_level === 2 ? (
          <>
            <a href="/admin" tabIndex={tabIndex} onClick={hide}>
              {t("components.menu.admin")}
            </a>
          </>
        ) : null
      }

      {/*Links available to everyone*/}
      <Link to="/settings" onClick={hide}>
        {t("components.menu.settings")}
      </Link>
      <Link to="/help" onClick={hide}>
        {t("components.menu.help")}
      </Link>
      <Link to="/login" onClick={reset} tabIndex={tabIndex}>
        {t("components.menu.logout")}
      </Link>
    </StyledMenu>
  );
};

//Require the open prop for Menu
Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
