import { useState, useEffect } from "react";
import { Alert, Button, Row } from "react-bootstrap";
import { useOnTabClicked } from "../../../hooks";
import { Contract, DefaultContract } from "../../../model/Classes/Contract";
import { getContracts } from "../../../services/adminService";
import { logger } from "../../../utils/logger";
import { ContractTable } from "./ContractsTable";
import { EditContract } from "./EditContract";
import { useTranslation } from "react-i18next";
import { StateHandler } from "../../../model/Utilities/Types";

interface ContractViewProps {
  activeEventKey: string;
  contracts: Contract[];
  setContracts: StateHandler<Contract[]>;
  selectedContractName: string | null;
}

export const ContractView = ({ activeEventKey, contracts, setContracts, selectedContractName }: ContractViewProps) => {
  const { t } = useTranslation();
  const [view, setView] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract>(DefaultContract);
  const [addSuccessAlert, setAddSuccessAlert] = useState(false);
  const [showView, setShowView] = useState(-1);
  const [runUseEffect, setRunUseEffect] = useState(false);

  useOnTabClicked(
    activeEventKey,
    ["contracts"],
    () => {
      const getData = async () => {
        const contractRes = await getContracts();

        if (contractRes.success) {
          setContracts(contractRes.data);
          setShowView(1);
        } else {
          logger(contractRes.data);
          setShowView(0);
        }
      };

      if (contracts.length === 0 || runUseEffect) getData();
      setRunUseEffect(false);
    },
    [runUseEffect]
  );

  const toggleView = () => {
    if (view) setSelectedContract(DefaultContract);
    setView(!view);
  };

  const isAddMode = () => {
    return selectedContract.id === DefaultContract.id;
  };

  // Sets view to contracts when contract link is pressed on searchResults
  useEffect(() => {
    setView(false);
  }, [selectedContractName]);

  return (
    <>
      {showView === -1 ? (
        <>
          <h2 className="align-self-center">{t("global.view.loading")}</h2>
        </>
      ) : //if showView is 0, an error occured when retrieving the data from the backend.
      showView === 0 ? (
        <h2 className="align-self-center">{t("global.view.error")}</h2>
      ) : (
        <>
          {view ? (
            <EditContract
              toggleView={toggleView}
              contract={selectedContract}
              addMode={isAddMode()}
              setRunUseEffect={setRunUseEffect}
              setSelectedContract={setSelectedContract}
              admin={true}
              setAddSuccessAlert={setAddSuccessAlert}
            />
          ) : (
            <>
              {addSuccessAlert && (
                <Row>
                  <Alert variant="success">Successfully added contract and area</Alert>
                </Row>
              )}
              <Button data-cy="add-contract" className="mb-3" onClick={toggleView} disabled={true}>
                {t("global.buttons.add.contract")}
              </Button>

              <ContractTable
                contracts={contracts}
                setSelectedContract={setSelectedContract}
                contractName={selectedContractName}
                toggleView={toggleView}
                setRunUseEffect={setRunUseEffect}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
